import api from './axios';

export const availabilityApi = payload => {
  return api.get('https://storage.googleapis.com/public_balloon/hotelbeds-response.json', payload);
  // return api.post('availability/', payload);
};
export const checkratesApi = payload => {
  return api.post('checkrates/', payload);
};

export default api;
