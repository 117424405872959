import api from './axios';

export const ticketFareApi = payload => {
  return api({
    url: '/ancillaries/offers/check',
    method: 'POST',
    baseURL: process.env.VUE_APP_SKYPICKER_API_BASE,
    data: payload,
  });
};
