<template>
  <div class="outer tw-flex tw-flex-grow">
    <div class="img-container tw-flex tw-m-auto tw-flex-row tw-ml-0">
    
      <img
        :src="departureAirlines[0]"
        class="airline-img mr-2 tw-rounded-full"
      />
    </div>
    <div class="tw-flex left tw-flex-col">
      <div class="time tw-mr-2 ">
        {{ departureTime }}
      </div>
      <div class="airport tw-text-grey_medium">{{ airportFrom }}</div>
    </div>
    <div class="tw-flex tw-flex-col tw-flex-grow duration tw-items-center ">
      <div class="fly-duration">
        {{ flyDuration }}
      </div>
      <div
        class="arrow-container tw-flex tw-flex-row  tw-relative tw-items-center"
      >
        <div
          class="flight-arrow tw-transform  tw--rotate-90 tw-flex tw-items-center"
        >
          <PlaneIcon />
        </div>
        <div class="path-line"></div>
      </div>
    </div>
    <div class="tw-flex right tw-flex-col tw-flex-wrap font-14-px">
      <div class="time tw-mr-2 ">
        {{ arrivalTime }}
      </div>
      <div class="airport tw-text-grey_medium">{{ airportTo }}</div>
    </div>
  </div>
</template>

<script>
import PlaneIcon from '@/assets/bookingPage/flights/plane.svg';

export default {
  components: {
    PlaneIcon
  },
  props: {
    title: String,
    departureTime: String,
    departureDate: String,
    arrivalTime: String,
    arrivalDate: String,
    cityFrom: String,
    airportFrom: String,
    airportFromCode: String,
    cityTo: String,
    airportTo: String,
    airportToCode: String,
    cityCodeTo: String,
    flyDuration: String,
    departureAirlines: Array
  }
};
</script>

<style lang="scss" scoped>
.path-line {
  content: '';
  position: absolute;
  height: 1px;
  border-top: 2px solid #ffe4ec;
  width: 100%;
}

.flight-arrow {
  width: 12px;
  margin: auto;
  z-index: 1;
  &::before {
    background: white;
  }
  svg {
    background: #fff;
    padding: 7px 0;
  }
}

.airline-img {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
}

.section-title {
  font-family: PoppinsMedium;
  font-style: normal;
  display: inline-block !important;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Primary */

  color: #d62a53;
}

.airport {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 62px;

  font-family: PoppinsRegular;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* Paragraph */

  color: #747378;
}

.departure-date {
  display: inline-block !important;
  font-family: PoppinsMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Paragraph */

  color: #747378;
}

.circle-container {
  width: 20px;
  height: 18px;
  svg {
    margin: auto;
  }
}

.font-14-px {
  font-size: 14px;
}

.duration {
  white-space: nowrap;
  max-width: 94px;
  margin: 0 16px;

  font-family: PoppinsRegular;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* Title */

  color: #28262e;
}

.arrow-container {
  width: 100%;
}

.time {
  font-family: PoppinsMedium;
  font-size: 16px;
  line-height: 16px;
  width: 66px;

  letter-spacing: -0.02em;

  /* Title */
  margin-bottom: 6px;
  color: #28262e;
}

.img-container {
  width: 32px;
  margin-right: 12px;
}

.outer {
  height: 38px;
  margin: 11px 0;
}
</style>
