<template>
  <div>
    <h3 class="flights-step-title">{{title}}</h3>
    <h4 class="tw-mb-10">{{description}}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>
