<template>
  <div>
    <selected-fare
      v-if="showSelectedFare"
      :title="selectedFare.title"
      @change="changeTab(tabName.fare)"
    />
    <ticket-fare-header
      :title="faresHeader.title"
      :description="faresHeader.description"
    />
    <div>
      <div v-if="currentTab === tabName.fare">
        <fare-type v-if="!!ticketFares.length" @fare-selected="fareSelected" />
      </div>
      <div v-else-if="currentTab === tabName.service">
        <service-type @service-selected="serviceSelected" />
      </div>
    </div>
    <div class="d-flex tw-justify-between tw-mt-10">
      <v-btn color="primary" @click="previousHandler"
        >Go Back</v-btn
      >
      <v-btn color="primary" @click="continueHandler"
        >Continue</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FareType from './components/FareType.vue';
import ServiceType from './components/ServiceType.vue';
import { faresTypes } from '@/store/flights/flightsConstants';
import TicketFareHeader from './components/TicketFareHeader.vue';
import SelectedFare from './components/SelectedFare.vue';

export default {
  components: {
    FareType,
    ServiceType,
    TicketFareHeader,
    SelectedFare
  },
  props: {},
  data: () => {
    const tabName = {
      fare: 'fare',
      service: 'service'
    };
    return {
      currentTab: tabName.fare,
      tabName
    };
  },
  computed: {
    ...mapGetters('flights', [
      'checkedData',
      'ticketFares',
      'selectedFare',
      'selectedService'
    ]),
    faresHeader() {
      return this.currentTab === this.tabName.fare
        ? {
          title: 'Get the option to change or cancel your trip',
          description:
              'Upgrade your ticket so you can rebook or get a refund if you decide to change your plans.'
        }
        : {
          title: 'Upgrade your level of services',
          description:
              'Receive faster support, shorter wait times, and reduced fees on additional requests after booking.'
        };
    },
    showSelectedFare() {
      return this.selectedFare && this.currentTab === this.tabName.service;
    }
  },
  watch: {
    checkedData: {
      handler: function(val) {
        val && this.getTicketFare();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('flights', [ 'getTicketFare' ]),
    ...mapMutations('flights', [ 'setSelectedFare', 'setSelectedService' ]),
    changeTab(tab) {
      this.currentTab = tab;
    },
    fareSelected(fare) {
      this.setSelectedFare(fare);
      fare.loyaltyOption === faresTypes.FLEXI
        ? this.$emit('next-step')
        : this.changeTab(this.tabName.service);
    },
    serviceSelected(service) {
      service !== this.selectedService
        ? this.setSelectedService(service)
        : this.$emit('next-step');
    },
    previousHandler() {
      if (this.currentTab === this.tabName.service) {
        this.currentTab = this.tabName.fare;
      } else {
        this.$emit('prev-step');
      }
    },
    continueHandler() {
      if (this.currentTab === this.tabName.fare) {
        this.currentTab = this.tabName.service;
      } else {
        this.$emit('next-step');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}
</style>
