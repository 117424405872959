<template>
  <div class="displayTabs">
    <div v-for="(item, index) in items" :key="index">
      <v-card color="transparent" flat>
        <div class="experienceInCartContainer">
          <flights-item
            :canBeEdited="canBeEdited"
            v-bind="item"
            @remove-flight="removeFlightHandler(index)"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import FlightsItem from './FlightsItem.vue';

export default {
  components: { FlightsItem },
  name: 'DisplayTabs',
  props: {
    items: Array,
    canBeEdited: Boolean
  },
  methods: {
    ...mapMutations([ 'removeFlightFromCart' ]),
    removeFlightHandler(index) {
      this.removeFlightFromCart(index);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';

.displayTabs {
  background-color: transparent !important;
  border: 0px !important;
}

.experienceInCartContainer {
  height: auto;
  background-color: transparent;

  padding-right: 31px;
  padding-left: 31px;

  margin-bottom: 25px;
  margin-bottom: 1rem;
}
</style>
