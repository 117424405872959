<template>
  <div>
    <template v-if="activeTabName === '' || activeTabName == 'Flights'">
      <div>
        <flights :canBeEdited="canBeEdited" :items="flightsInCart" />
      </div>
    </template>

    <template
      v-if="activeTabName === '' || activeTabName == 'Accommodation'"
    >
      <div
        v-for="item in accommodationsInCart"
        :key="item.id"
        class="display-tabs"
      >
        <ExperienceCartContainer
          :item="item"
          :priceWithCurrency="priceWithCurrency"
          :decrement="decrement"
          :increment="increment"
          hideIncrement
          :canBeEdited="canBeEdited"
          removable
          @remove-experience="removeAccommodation(item)"
        />
      </div>
    </template>

    <template
      v-if="activeTabName === '' || activeTabName == 'Activities'"
    >
      <div
        v-for="item in activitiesInCart"
        :key="item.id"
        class="display-tabs"
      >
        <ExperienceCartContainer
          :item="item"
          :priceWithCurrency="priceWithCurrency"
          :decrement="decrement"
          :canBeEdited="canBeEdited"
          :increment="increment"
        /></div
    ></template>
    <template
      v-if="activeTabName === '' || activeTabName == 'Restaurants'"
    >
      <div
        v-for="item in restaurantsInCart"
        :key="item.id"
        class="display-tabs"
      >
        <ExperienceCartContainer
          :item="item"
          :priceWithCurrency="priceWithCurrency"
          :decrement="decrement"
          @remove-restaurant="removeRestaurant(item)"
          :increment="increment"
          hidePrice
          :canBeEdited="canBeEdited"
        /></div
    ></template>
  </div>
</template>

<script>
import ExperienceCartContainer from './ExperienceCartContainer.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';
import Flights from './flights/Flights.vue';

export default {
  name: 'DisplayTabs',
  components: {
    Flights,
    ExperienceCartContainer,
  },
  props: {
    name: String,
    image: String,
    price: Number,
    type: String,
    quantity: Number,
    canBeEdited: Boolean,
    activeTabName: String,
  },

  data() {
    return {
      tab: null,
      flights: [],
    };
  },
  computed: {
    ...mapGetters([
      'accommodationsInCart',
      'flightsInCart',
      'activitiesInCart',
      'restaurantsInCart',
      'experienceshoppingcart',
    ]),
  },
  methods: {
    ...mapActions([ 'addExperienceToCart', 'increment', 'decrement' ]),
    ...mapMutations([ 'removeExperienceFromCart', 'removeActivityOrRestaurant' ]),
    priceWithCurrency(price) {
      return `€${price}`;
    },
    removeRestaurant(item) {
      this.removeActivityOrRestaurant(item);
    },
    removeAccommodation(accommodation) {
      this.removeExperienceFromCart(accommodation);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/grid.scss";
@import "~@/assets/styles/variables.scss";

.unsetTransform {
  text-transform: unset !important;
  font-family: "RedHatDisplayMedium";
  font-size: 16px;
}

.display-tabs {
  background-color: transparent !important;
  border: 0px !important;

  @apply tw-flex tw-flex-col tw-items-center;

  @apply tw-px-10;

  padding-right: calc(2.5rem - 8px);
  padding-left: calc(2.5rem - 8px);
}

.priceInfo {
  font-family: "RedHatDisplayBold";
}

.tabItems {
  display: flex;
  align-items: center;
  text-align: center;
}

.tabColor {
  @apply tw-text-black tw-mt-2;
}

.icon {
  @apply tw-text-black tw-h-10 tw-w-10 tw-mt-2;
}
</style>
