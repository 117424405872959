export const detailsTabsNames = [
  {
    tab: 'flights',
    title: 'Flights',
    icon: 'FlightIcon',
    iconWhite: 'FlightIconWhite',
    componentName: 'Flights',
  },
  {
    tab: 'accommodation',
    title: 'Accommodation',
    icon: 'AccommodationIcon',
    iconWhite: 'AccommodationIconWhite',
    componentName: 'Accommodation',
  },
  {
    tab: 'experiences',
    title: 'Activities',
    icon: 'ActivitiesIcon',
    iconWhite: 'ActivitiesIconWhite',
    componentName: 'Activities',
  },
  {
    tab: 'restaurants',
    title: 'Restaurants',
    icon: 'RestaurantIcon',
    iconWhite: 'RestaurantIconWhite',
    componentName: 'Restaurants',
  },
];
