<template>
  <div class="main-content-container">
    <div class="tw-flex tw-flex-row">
      <div>
        <div class="confirm-container">
          <h1 class="confirm-header tw-font-bold tw-text-lef tw-tracking-wide">
            {{ greeting }}
          </h1>
        </div>
        <div class="para-container tw-mb-20">
          <h2 class="header-text confirm-para tw-tracking-wide tw-ml-16">
            You will receive a confirmation email soon with your itinerary and
            booking details
          </h2>
        </div>
        <div class="tw-flex tw-justify-center">
          <ConfirmationMarker />
        </div>
        <h1 class="tw-text-4xl tw-font-bold tw-text-center tw-mt-5 tw-mb-10">
          You’re going to {{ cityaccommodation }}!
        </h1>
        <div class="tw-flex tw-full tw-justify-center">
          <div class="tw-flex tw-flex-wrap tw-w-3/4">
            <template v-if="flights && flights.length">
              <order-detail
                v-for="(flight, index) in flights"
                :key="`flight_${index}`"
                :title="`${flight.city_from} - ${flight.city_to} and Back`"
                :items="[
                  `Departure: ${formatDate(flight.departure_at)}`,
                  `Return: ${calcReturn(flight.itinerary, flight.city_to)}`,
                  `Passengers: ${flight.passengers.length}`
                ]"
              >
                <template slot="icon"><PlaneIcon /></template>
              </order-detail>
            </template>

            <template v-if="!!accommodations.length">
              <order-detail
                v-for="(acc, index) in accommodations"
                :key="`accommodation_${index}`"
                :title="acc.name"
                :items="[
                  `${formatDate(acc.checkInDate, 'DD MMM')} - ${formatDate(
                    acc.checkOutDate
                  )}`,
                  `Guests: ${acc.paxes.length + 1} ${
                    acc.children && acc.children.length
                      ? 'Children: ' + acc.children.length
                      : ''
                  }`,
                  `Booking Nr. ${accommodationsBookingId(index)}`
                ]"
              >
                <template slot="icon"><AccommodationIcon /></template>
              </order-detail>
            </template>

            <template v-if="!!restaurants.length">
              <order-detail
                v-for="(res, index) in restaurants"
                :key="`restaurant_${index}`"
                :title="res.name"
                :items="[
                  `${formatDate(res.date, 'DD MMM')}`,
                  `Guests: ${res.quantity}`
                ]"
              >
                <template slot="icon"><RestaurantIcon /></template>
              </order-detail>
            </template>

            <template v-if="!!activities.length">
              <order-detail
                v-for="(res, index) in activities"
                :key="`activities_${index}`"
                :title="res.name"
                :items="[
                  `${formatDate(res.date, 'DD MMM')}`,
                  `Guests: ${res.quantity}`
                ]"
              >
                <template slot="icon"><ActivitiesIcon /></template>
              </order-detail>
            </template>
          </div>
        </div>
        <div class="tw-flex">
          <button
            v-if="paymentData.data.cart.accommodation.length > 0"
            @click="generatePDF"
            class="
              tw-border
              tw-bg-primary
              tw-content-center
              tw-mx-auto
              tw-px-12
              tw-py-4
              md:tw-py-2
              tw-flex tw-flex-row tw-right-0 tw-rounded-full tw-mt-16
            "
          >
            <div class="tw-text-white">Download details</div>
          </button>
          <button
            @click="homepage"
            class="
              tw-border
              tw-bg-primary
              tw-content-center
              tw-mx-auto
              tw-px-12
              tw-py-4
              md:tw-py-2
              tw-flex tw-flex-row tw-right-0 tw-rounded-full tw-mt-16
            "
          >
            <div class="tw-text-white">Go to homepage</div>
          </button>
        </div>
      </div>
      <div>
        <div class="tw-rounded-3xl confirm tw-ml-20">
          <video
            playsinline
            ref="videoRef"
            src="confirmationVideo"
            id="video-container"
            class="confirmation-video tw-rounded-3xl"
            muted
            loop
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import confetti from 'canvas-confetti';
import moment from 'moment';
import ConfirmationMarker from '@/assets/bookingPage/confirmation/confirmationMarker.svg';
import OrderDetail from './components/OrderDetail.vue';
import PlaneIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import { mapActions, mapGetters } from 'vuex';
import { generatePDF } from '@/utils/generateFinalPDF';


export default {
  components: {
    ConfirmationMarker,
    OrderDetail,
    PlaneIcon,
    AccommodationIcon,
    RestaurantIcon,
    ActivitiesIcon
  },
  computed: {
    ...mapGetters([ 'paymentData', 'cityaccommodation' ]),
    accommodations() {
      return this.paymentData?.data?.cart?.accommodation || [];
    },
    flights() {
      return this.paymentData?.data?.cart?.flights || [];
    },
    restaurants() {
      return this.paymentData?.data?.cart?.restaurants || [];
    },
    activities() {
      return this.paymentData?.data?.cart?.activities || [];
    },
    greeting() {
      let name = null;
      if (this.accommodations?.length) {
        name =
          this.accommodations[0]?.holder?.firstName +
          ' ' +
          this.accommodations[0]?.holder?.lastName;
      } else if (this.flights?.length) {
        name =
          this.flights[0]?.passengers[0]?.firstName +
          ' ' +
          this.flights[0]?.passengers[0]?.lastName;
      } else if (this.restaurants?.length || this.activities?.length) {
        name = this.restaurants[0]?.holder?.current.firstName
          ? this.restaurants[0]?.holder?.current.firstName +
            ' ' +
            this.restaurants[0]?.holder?.current.lastName
          : this.activities[0]?.holder?.current.firstName +
            ' ' +
            this.activities[0]?.holder?.current.lastName;
      }
      return name
        ? 'Great! ' + name + ', everything is ready.'
        : 'Great! Everything is ready.';
    }
  },
  watch: {
    paymentData: {
      handler: val => console.log(val),
      immediate: true
    }
  },
  mounted: function() {
    if (this.$refs.videoRef) {
      this.$refs.videoRef.src = require('@/assets/bookingPage/confirmation/confirmation_video.mp4');
      this.$refs.videoRef.play();
    }
    confetti({
      particleCount: 300,
      spread: 70,
      origin: { y: 0.6 }
    });
  },
  methods: {
    ...mapActions([ '' ]),
    formatDate(date, format = 'DD MMM YY') {
      return moment(date).format(format);
    },
    calcReturn(routes, cityFrom) {
      const returnRoute = routes?.find(r => r.city_from === cityFrom);
      return returnRoute ? this.formatDate(returnRoute.departure_at) : '';
    },
    accommodationsBookingId(index) {
      const acc = this.paymentData?.data?.accommodation;
      return acc && acc[index] ? acc[index].value?.booking?.reference : '';
    },
    homepage() {
      this.$router.push({ name: 'LandingPage' });
    },
    async generatePDF() {
      const doc = new jsPDF();
      // const myFont = await fetch('@/assets/fonts/RedHatDisplay/RedHatDisplay-Medium.ttf');
      // doc.addFileToVFS('RedHatDisplay-Medium.ttf', myFont);
      // doc.addFont('RedHatDisplay-Medium.ttf', 'Red Hat Display', 'normal');
      // doc.setFont('Red Hat Display');
      generatePDF(doc, this.paymentData);
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-container {
  max-width: 70rem;
}
.confirm-header {
  font-size: 3.5rem;
  font-family: 'RedHatDisplayBold';
  line-height: 76%;
  letter-spacing: -4%;
  color: #28262e;
  margin-left: 4rem;
  margin-top: 12rem;
}
.para-container {
  max-width: 55rem;
}
.confirm-para {
  margin-top: 18px;
  font-family: 'RedHatDisplayMedium';
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -4%;
  color: #595959;
}
.confirm {
  width: 52% !important;
  height: 100%;
  margin-top: 8rem;
}
</style>
