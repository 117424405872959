<template>
  <div class="scroll">
    <div class="modal booking-modal">
      <div class="tw-flex tw-justify-center">
        <img :src="item.image" />
      </div>
      <div class="inner">
        <template v-if="!!item.image"> </template>

        <!-- <div>
          <template v-if="item">
            <div class="tw-mb-3.5 tw-flex">
              <div class="experienceName">
                {{ item.name }}
              </div>
              <div class="tw-flex tw-flex-col">
                <div class="price">€{{ item.price }}</div>
                <div class="per-adult">Per Adult</div>
              </div>
            </div>
            <div class="tw-mb-3.5 experienceDescription">
              {{ item.details.description }}
            </div>
          </template>
        </div>
        <div class="divider"></div> -->
        <div class="mainTitle">
          Please select the date and time for your booking
        </div>
        <div class="tw-flex-grow">
          <div class="section">
            <div class="form-label">
              Select your date
            </div>

            <div
              class="tw-flex tw-flex-row form-container  tw-rounded-xl tw-pl-3"
            >
              <home-date-picker
                class="form-label"
                label="Change date"
                v-model="selectedDate"
              />
              <calendar-icon class="tw-w-6 tw-h-6 tw-m-auto tw-text-primary" />
            </div>
          </div>
        </div>
        <div class="section">
          <div class="time-label">Time</div>
          <div class="select-container tw-rounded-xl">
            <v-select flat solo v-model="selectedTime" :items="selectableTimes">
            </v-select>
          </div>
        </div>
        <div class="section">
          <div class="guests-label">number of people</div>

          <div class="counter">
            <div
              class="tw-flex-grow tw-text-title tw-select-none  tw-text-2xl tw-leading-6 tw-text-left tw-tracking-tighter"
            >
              {{ quantity }} Guest<template v-if="quantity > 1">s</template>
            </div>
            <button
              v-if="quantity != 1"
              type="button"
              v-on:click="
                () => {
                  quantity -= 1;
                }
              "
              class="counter-button"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.55469 9.99902H15.4436"
                  stroke="#D62A53"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div class="quantity">{{ quantity }}</div>
            <button
              type="button"
              v-on:click="
                () => {
                  quantity += 1;
                }
              "
              class="counter-button"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 4.55469V15.4436"
                  stroke="#D62A53"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.55469 9.99902H15.4436"
                  stroke="#D62A53"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <div class="action-bar">
          <v-btn @click="$emit('close')" class="cancel-btn "> Cancel </v-btn
          ><v-btn
            color="primary"
            @click="
              () => {
                updateDateTime();
              }
            "
            class="proceed-btn"
            :disabled="!selectedTime"
          >
            Update
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import HomeDatePicker from '@/views/landingPage/landingPageComponents/HomeDatePicker.vue';
import CalendarIcon from '@/assets/bookingPage/flights/calendar.svg';

export default {
  name: 'Modal',
  data() {
    return {
      selectedDate: moment(this.item.date).format('YYYY-MM-DD'),
      selectedTime: this.item.time.hour + ':' + this.item.time.minute,
      selectableTimes: [
        '9:00',
        '9:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30'
      ],
      quantity: this.item.quantity
    };
  },
  props: {
    item: {
      type: Object
    }
  },
  components: {
    HomeDatePicker,
    CalendarIcon
  },
  computed: {
    ...mapGetters([ 'activitiesInCart' ]),
    datesArr() {
      const { from, to } = this.$store.state.search;
      const startDate = moment(from);
      const endDate = moment(to);
      let date = startDate;
      const dates = [];
      if (startDate && endDate) {
        while (date.isSameOrBefore(endDate)) {
          dates.push(date.clone());
          date = date.add(1, 'day');
        }
      }
      return dates;
    }
  },
  methods: {
    updateDateTime() {
      const [ hour, minute ] = this.selectedTime.split(':');
      this.$emit(
        'update',
        this.item,
        this.selectedDate,
        { hour: hour, minute: minute },
        this.quantity
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';
.mainTitle {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #28262e;
}

.updateButton {
  font-size: 20px;
  height: 3.5rem !important;
}

.closeButton {
  color: $primary;
  background-color: transparent !important;
  border: unset;
  border-color: transparent !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  font-family: 'RedHatDisplayMedium';
  font-size: 20px;
  letter-spacing: 0%;
  text-decoration: underline;
}

.timeLabel {
  color: black;
  font-size: 14px;
  font-family: 'RedHatDisplayMedium';
}

select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 96%;
}

.dateCircles {
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  box-shadow: none !important;
  background: #ffe4ec;
  color: #d62a53;
  text-align: center;
  font-family: MontserratMedium;
  font-size: 18px;
}

.dateCircles:hover {
  background-color: $primary;
}

.experienceName {
  font-family: PoppinsSemiBold;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: -0.04em;
  color: #28262e;
}

.dayButton {
  width: 50px;
  height: 50px;
}

.dayButton:focus {
  background-image: $primary;
}

.form-container {
  background-color: #f1f1f1;
  max-height: 54px;
  padding: 0 20px !important;
}

.modal {
  margin: 0;
  padding: 0 !important;
  max-width: 624px !important;
  width: 624px !important;
  max-height: calc(100vh - 48px);
  border-radius: 20px !important;
}
.select-container {
  background: #f1f1f1;
  color: #28262e;
  height: 54px;
}
img {
  max-height: 300px;
  height: 27.77vh;
  object-fit: cover;
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.action-bar {
  align-items: flex-end;
  flex-grow: 0.5;
  width: 100%;
  display: flex;
  padding-top: 23.5px;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    button {
      width: 100%;
      margin: 16px 0 0 0;
    }
    .cancel-btn {
      background: transparent !important;
    }
  }
}

.counter-button,
.remove-btn {
  background: #ffe4ec;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  /* Primary */
  color: #d62a53;
}
.cancel-btn,
.proceed-btn {
  border-radius: 104px !important;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  height: 54px !important;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: none !important;

  /* Primary */
}

.cancel-btn {
  background: #ffe4ec !important;

  color: #d62a53 !important;
  border-radius: 104px !important;
  text-transform: none;
  margin: 0px;
  height: 54px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  margin-right: 16px !important;
  margin-left: auto;
}

.counter {
  @apply tw-self-center tw-font-bold tw-flex tw-flex-row tw-items-center tw-gap-12 tw-mb-2;

  @apply tw-gap-2 tw-mb-0;

  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 55px;

  .quantity {
    min-width: 18px;
    user-select: none;
  }

  text-align: center;

  /* Primary */

  color: #d62a53;
}

.counter {
  svg {
    margin: auto;
  }
}

.time-label {
  color: black;
  font-size: 14px;
  font-family: 'RedHatDisplayMedium';
}

.time-label,
.form-label,
.guests-label {
  font-family: PoppinsMedium;
  font-size: 18px;
  line-height: 18px;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Paragraph */

  color: #747378;

  margin-bottom: 16px;
}
.section {
  height: 88px;
  margin: 24px 0 0 0;
  display: inline-block;
  width: 100%;
}

.inner {
  padding: 32px;
}

.divider {
  background: #747378;
  opacity: 0.1;
  border-radius: 20px;
  max-width: 560px;
  width: 100%;
  height: 1px;
  margin: 32px auto;
}

.experienceDescription {
  width: 329px;
  left: 680px;
  top: 658px;

  font-family: MontserratRegular;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  margin-top: 25px;
  margin-bottom: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #747378;
}

.per-adult {
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #747378;
  width: 65px;
}
.price {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #28262e;
}

@media (max-height: 764px) {
  img {
    display: none;
  }
}
</style>
