<template>
  <div class="link d-flex flex-column align-center justify-center active-element tw-font-sans tw-text-sm tw-text-center"
  :class="{ 'active': active }">
    <button class="tw-flex tw-flex-col tw-items-center"
     @click="$emit('activate')"
     ><div class="icon tw-mx-5 tw-bg-primary tw-bg-opacity-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-primary">
        <component :is="icon" />
      </div>
      <span class="tw-my-1">{{ title }}</span>
    </button>
    <div class="checked-block">
      <CheckedGreen v-if="checked" />
    </div>
  </div>
</template>

<script>
import CheckedGreen from '@/assets/tools/checkedGreen.svg';
import FlightIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';

export default {
  components: {
    CheckedGreen,
    FlightIcon,
    AccommodationIcon,
    ActivitiesIcon,
    RestaurantIcon,
  },
  props: { 
    title: String,
    icon: String,
    active: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    imgSrc() {
      return `/images/booking/${this.icon}${this.active ? '_active' : ''}.svg`;
    }
  } 
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.checked-block {
  height: 22px;
}

.link {
  .icon {
    width: 48px;
    height: 48px;
  }

  &.active {
    color: #D62A53;

    .icon {
      background: #D62A53;
      color: #fff;
    }
  }

  button {
    color: currentColor;

    svg {
      display: block;
      width: 30px;
      height: 30px;
      margin: 5px auto;
    }
  }
}
</style>
