// import { convertDate } from '@/utils/appDate.js';

export const reduceHotelseResponse = hotels => {
  const data = hotels.reduce((acc, item) => {
    const image = item.images.find(v => v.roomCode === item.rooms[0].code || v.imageTypeCode === 'HAB' || v.imageTypeCode === 'GEN');
    if (image) {
      image.path = image.path ? image.path.replace('medium', 'bigger') : 'https://images.unsplash.com/photo-1517840901100-8179e982acb7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80';
    }
    const hotelImage = item.images.find(v => v.imageTypeCode === 'COM' && v.order === 1);
    if (hotelImage) {
      hotelImage.path = hotelImage.path ? hotelImage.path.replace('medium', 'bigger') : 'https://images.unsplash.com/photo-1517840901100-8179e982acb7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80';
    }
    return [ ...acc, { ...{ ...item.rooms[0].rates[0] }, ...{
    // return [ ...acc, { ...{ ...item.rooms[0].rates[0], cancellationPolicy: convertDate(item.rooms[0].rates[0].cancellationPolicies[0].from) }, ...{ 
      ...item.rooms[0], roomName: item.rooms[0].name, roomCode: item.rooms[0].code, image, hotelImage }, 
    ...{ ...item, categoryName: hotelStars[item.categoryName] } 
    } ];
  }, []);
  return data;
};


const hotelStars = {
  '4 STARS': 4,
  '3 STARS': 3,
  '2 STARS': 2,
  '1 STAR': 1,
  '5 STARS': 5,
  'HOSTEL' : 1
};

export function delay(delayInms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}
