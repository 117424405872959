import {
  checkFlightsApi,
  searchFlightsApi,
  saveBookingApi,
  heartbeatApi,
  confirmPaymentApi
} from '@/api/flights';
import { ticketFareApi } from '@/api/kiwi';
import { APP_CURRENCY } from '@/assets/constants/appConfig';
import { REFRESH_FLIGHTS_DURATION } from '@/assets/constants/appConfig';
import moment from 'moment';
import { emailRule, requiredRule } from '../../utils/rules';
import {
  ancillaries,
  faresList,
  guaranteesItems,
  servicesList,
  flightSteps,
  passengerCategory
} from './flightsConstants';
import {
  passengersCategories,
  passengerTemplate,
  fillFares,
  fillServices,
  countAgeCategories,
  ageCategory,
  getShortPassengerTitle
} from './flightsUtils';

const getInitialState = () => {
  return {
    currentStep: flightSteps[0],
    flyFrom: null,
    flyTo: null,
    flight: null,
    checkedData: null,
    heartbeatInterval: null,
    updateDuration: 30 * 1000,
    adults: 1,
    bnum: 0,
    email: '',
    phone: {
      code: '',
      value: ''
    },
    contactsComplete: false,
    passengers: [ passengerTemplate() ],
    ticketFares: [],
    ticketFaresLoaded: false,
    ticketServices: [],
    selectedFare: faresList[1],
    selectedService: servicesList[1],
    guarantee: guaranteesItems[0],
    searchedFlights: [],
    ticketsPrice: 0,
    totalPrice: 0,
    bookingId: null,
    transactionId: null
  };
};

const state = getInitialState();

const getters = {
  currentStep: state => state.currentStep,
  flyFrom: state => state.flyFrom,
  flyTo: state => state.flyTo,
  flight: state => state.flight,
  checkedData: state => state.checkedData,
  bnum: state =>
    state.passengers.reduce((acc, passenger) => {
      return acc + passenger.baggage;
    }, 0),
  cabinBaggageDefs: state =>
    state.checkedData?.baggage?.definitions?.hand_bag.map((item, index) => {
      item.index = index;
      return item;
    }),
  handBaggageDimentions: state => {
    const bag = state.flight?.baglimit;
    return (
      bag && {
        height: bag.hand_height,
        length: bag.hand_length,
        width: bag.hand_width,
        weight: bag.hand_weight
      }
    );
  },
  holdBaggageDimentions: state => {
    const bag = state.flight?.baglimit;
    return (
      bag && {
        height: bag.hold_height,
        length: bag.hold_length,
        width: bag.hold_width,
        weight: bag.hold_weight
      }
    );
  },
  bagsPrice: state => state.flight?.bags_price,
  checkedBaggageDefs: state =>
    state.checkedData?.baggage?.definitions?.hold_bag.map((item, index) => {
      item.index = index;
      return item;
    }),
  passengers: state => state.passengers,
  passengersUTC: state =>
    state.passengers.map(passenger => {
      const { birthdayDate, expirationDate } = passenger;
      passenger.birthday = Math.floor(
        moment(
          `${birthdayDate.month}-${birthdayDate.date}-${birthdayDate.year}`,
          'MMMM-DD-YYYY'
        ).valueOf() / 1000
      );
      passenger.expiration = Math.floor(
        moment(
          `${expirationDate.month}-${expirationDate.date}-${expirationDate.year}`,
          'MMMM-DD-YYYY'
        ).valueOf() / 1000
      );
      return passenger;
    }),
  contactEmail: state => state.email,
  contactPhone: state => state.phone,
  contactsComplete: state => {
    const emailComplete =
      (emailRule()(state.email) === true ? true : false) &&
      (requiredRule()(state.email) === true ? true : false);
    const phoneComplete =
      (requiredRule()(state.phone.value) === true ? true : false) &&
      (requiredRule()(state.phone.code) === true ? true : false);
    return emailComplete && phoneComplete;
  },
  contactPhoneStr: state => state.phone.code + state.phone.value,
  ticketFares: state => state.ticketFares,
  ticketFaresLoaded: state => state.ticketFaresLoaded,
  ticketServices: state => state.ticketServices,
  selectedFare: state => state.selectedFare,
  selectedService: state => state.selectedService,
  guarantee: state => state.guarantee,
  searchedFlights: state => state.searchedFlights.slice(0, 100),

  adults: state =>
    countAgeCategories(state.passengers, passengerCategory.adult.category),
  children: state =>
    countAgeCategories(state.passengers, passengerCategory.child.category),
  infants: state =>
    countAgeCategories(state.passengers, passengerCategory.infant.category),
  checkedPrice: state =>
    state.totalPrice * state.passengers.length +
    state.passengers.reduce((sum, curr) => {
      const bagsPrice = state.flight?.bags_price;
      if (bagsPrice && !!curr.baggage && bagsPrice[curr.baggage]) {
        sum += bagsPrice[curr.baggage];
      }
      return sum;
    }, 0),
  totalPrice: state => state.totalPrice,
  ticketsPrice: state => state.ticketsPrice,
  bookingId: state => state.bookingId,
  transactionId: state => state.transactionId,
  flightComplete: state => {
    const emailComplete =
      (emailRule()(state.email) === true ? true : false) &&
      (requiredRule()(state.email) === true ? true : false);
    const phoneComplete =
      (requiredRule()(state.phone.value) === true ? true : false) &&
      (requiredRule()(state.phone.code) === true ? true : false);
    return (
      !!state.flight &&
      emailComplete &&
      phoneComplete &&
      state.passengers.every(p => p.valid)
    );
  }
};

const actions = {
  async searchFlights({ commit }, payload) {
    const flights = await searchFlightsApi(payload);
    flights && commit('setSearchedFlights', flights);
  },
  async setFlightRequest({ dispatch, commit }, flight) {
    commit('clearFlight');
    commit('setFlight', flight);
    const res = await dispatch('checkFlights');
    if (res) {
      const heartbeatInterval = setInterval(() => {
        dispatch('heartbeat');
      }, REFRESH_FLIGHTS_DURATION);
      commit('setHeartbeatInterval', heartbeatInterval);
    } else {
      commit('clearFlight');
    }
    return !!res;
  },
  async checkFlights({ commit, getters }) {
    let success = false;
    const payload = {
      booking_token: getters.flight.booking_token,
      bnum: getters.bnum,
      adults: getters.adults,
      children: getters.children,
      infants: getters.infants
    };
    let counter = 0;
    while (!success && counter < 10) {
      try {
        const res = await checkFlightsApi(payload);
        commit('setCheckedData', res);
        commit('setTotalPrice', res?.total_price);
        commit('setTicketsPrice', res?.tickets_price);
        success = true;
      } catch {
        console.log('Flight not allow!');
      } finally {
        counter++;
      }
    }
    return success;
  },
  async heartbeat({ commit, getters }) {
    const payload = {
      booking_token: getters.flight.booking_token,
      bnum: getters.bnum,
      adults: getters.adults,
      children: getters.children,
      infants: getters.infants,
      session_id: getters.checkedData.session_id
    };
    const res = await heartbeatApi(payload);
    commit('setTotalPrice', res?.total_price);
    commit('setTicketsPrice', res?.tickets_price);
  },
  async getTicketFare({ commit, getters }) {
    const { checkedData, flight, passengers } = getters;
    if ([ checkedData, flight, passengers ].some(item => !item)) {
      return;
    }
    const { session_id: sessionId } = checkedData;
    const { booking_token } = flight;
    //this is test data
    const payload = {
      booking: {
        prices: {
          tickets: {
            amount: (flight.price * passengers.length).toString(),
            base: (flight.price * passengers.length).toString(),
            currency: APP_CURRENCY,
            merchant: '0',
            service: '0',
            service_flat: '0'
          }
        }
      },
      passengers: passengersCategories(passengers),
      visitor_id: sessionId,
      currency: APP_CURRENCY,
      ancillaries: [
        ancillaries.servicePackage,
        ancillaries.fareType,
        ancillaries.guarantee
      ],
      booking_token,
      affily: process.env.VUE_APP_SEARCH_FLIGHTS_API_PARTNER
    };
    const res = await ticketFareApi(payload);
    if (res) {
      res.fare_type?.offers && commit('setTicketFare', res.fare_type.offers);
      res.service_package?.offers &&
        commit('setTicketServices', res.service_package.offers);
    }
  },
  async saveTicket({ commit, getters }) {
    let success = false;
    const {
      checkedData,
      flight,
      passengers,
      contactEmail,
      contactPhoneStr,
      bnum
    } = getters;
    if ([ checkedData, flight, passengers ].some(item => !item)) {
      return;
    }
    const { booking_token } = flight;
    const payload = {
      booking_token,
      bags: bnum,
      passengers: passengers.map(passenger => ({
        birthday: moment(passenger.birthdayDate).format('YYYY-MM-DD'),
        category: ageCategory(passenger.birthdayDate).category,
        cardno: passenger.cardno,
        expiration: moment(passenger.expirationDate).format('YYYY-MM-DD'),
        email: contactEmail,
        name: passenger.firstName,
        surname: passenger.lastName,
        nationality: passenger.nationality.code,
        phone: contactPhoneStr,
        title: getShortPassengerTitle(passenger.gender)
      }))
    };
    try {
      const res = await saveBookingApi(payload);
      const { booking_id, transaction_id } = res;
      booking_id &&
        transaction_id &&
        commit('setTransaction', {
          bookingId: booking_id,
          transactionId: transaction_id
        });
      success = true;
    } catch {}
    return success;
  },
  async confirmPayment({ commit, getters }) {
    let success = false;
    const { bookingId, transactionId } = getters;
    const payload = {
      booking_id: bookingId,
      transaction_id: transactionId
    };
    try {
      const res = await confirmPaymentApi(payload);
      if (res?.status === 'success') {
        commit('setFlightComplete', getters.flight, { root: true });
        commit('clearFlight');
        success = true;
      }
    } catch {}
    return success;
  }
};

const mutations = {
  setStep(state, currentStep) {
    Object.assign(state, { currentStep });
  },
  setFlight(state, flight) {
    Object.assign(state, { flight });
  },
  setCheckedData(state, checkedData) {
    Object.assign(state, { checkedData });
  },
  setTicketsPrice(state, ticketsPrice) {
    state.ticketsPrice = ticketsPrice;
  },
  setTotalPrice(state, totalPrice) {
    state.totalPrice = totalPrice;
  },
  setHeartbeatInterval(state, heartbeatInterval) {
    state.heartbeatInterval = heartbeatInterval;
  },
  clearHeartbeatInterval(state) {
    clearInterval(state.heartbeatInterval);
    state.heartbeatInterval = null;
  },
  clearFlight(state) {
    clearInterval(state.heartbeatInterval);
    Object.assign(state, {
      currentStep: flightSteps[0],
      flight: null,
      heartbeatInterval: null,
      checkedData: null,
      ticketsPrice: 0,
      totalPrice: null,
      bookingId: null,
      transactionId: null
    });
  },
  setPassengerField(state, { index, field, value }) {
    const passengers = [ ...state.passengers ];
    const passenger = { ...passengers[index], [field]: value };
    passengers[index] = passenger;
    Object.assign(state, { passengers });
  },
  addPassenger(state) {
    state.passengers.push(passengerTemplate());
  },
  removePassenger(state, index) {
    state.passengers.splice(index, 1);
  },
  setEmail(state, email) {
    state.email = email;
  },
  setPhone(state, phone) {
    state.phone = phone;
  },
  setTransaction(state, { bookingId, transactionId }) {
    Object.assign(state, { bookingId, transactionId });
  },
  setTicketFare(state, ticketFares) {
    Object.assign(state, {
      ticketFares: fillFares(ticketFares),
      ticketFaresLoaded: true
    });
  },
  setTicketServices(state, ticketServices) {
    Object.assign(state, { ticketServices: fillServices(ticketServices) });
  },
  setSelectedFare(state, selectedFare) {
    const selectedService = state.ticketServices.find(
      service => service.level === selectedFare.preselectedService
    );
    Object.assign(state, { selectedFare, selectedService });
  },
  setSelectedService(state, selectedService) {
    Object.assign(state, { selectedService });
  },
  setGuarantee(state, guarantee) {
    Object.assign(state, { guarantee });
  },
  setSearchedFlights(state, searchedFlights) {
    Object.assign(state, { searchedFlights });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
