const getInitialState = () => {
  return {
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
  };
};

const state = getInitialState();

const getters = {
  getEmail: state => state.email,
  getPhone: state => state.phoneNumber,
  getfirstName: state => state.firstName,
  getlastName: state => state.lastName,
};

const actions = {
  // addEmail(context, email) {
  //   try {
  //     context.commit('setEmail', email );
  //   } catch (err) { console.log(err); }
  // },
  // addPhoneNumber(context, phone) {
  //   try {
  //     context.commit('setPhone', phone );
  //   } catch (err) { console.log(err); }
  // },
  // addFirstName(context, firstName) {
  //   try {
  //     context.commit('setfirstName', firstName );
  //   } catch (err) { console.log(err); }
  // },
  // addLastName(context, lastName) {
  //   try {
  //     context.commit('setLastName', lastName );
  //   } catch (err) { console.log(err); }
  // },
};

const mutations = {
  emailSet(state, email) {
    state.email = email;
  },
  phoneNumberSet(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  firstNameSet(state, firstName) {
    state.firstName = firstName;
  },
  lastNameSet(state, lastName) {
    state.lastName = lastName;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
