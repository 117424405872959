<template>
  <div class="tw-border tw-border-primary tw-rounded-lg tw-flex tw-justify-between tw-items-center tw-p-1 tw-mb-7">
    <div class="tw-flex"><CheckedIcon class="tw-mx-2"/><h4>{{title}}</h4></div>
    <div class="tw-flex tw-justify-center fare-card-button tw-p-2">
      <v-btn color="primary" @click="$emit('change')" small>Change</v-btn>
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/assets/bookingPage/flights/checked.svg';

export default {
  components: {
    CheckedIcon,
  },
  props: {
    title: String,
  },
};
</script>
