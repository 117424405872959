import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default function initSentry(Vue, router) {
  if (typeof window === 'undefined') {
    // do not initialize sentry in SSR
    return;
  }
  const isLive = window.location.hostname === 'balloon-to';
  const dsnUser = isLive
    ? 'e181ae19c6ac4899bac24842287c77de'
    : '424c677b3a9e45a2a1b749ccb8d60f73';
  const dsnPath = isLive ? '5993759' : '5998960';
  const dsn = `https://${dsnUser}@o1027121.ingest.sentry.io/${dsnPath}`;
  const urls = isLive ? [ 'balloon-to.com' ] : [ 'staging.go-travelo.com', 'staging.balloon-to.com' ];
  const routingInstrumentation = Sentry.vueRouterInstrumentation(router);
  const tracingOrigins = [ ...urls, /^\// ];
  const integrations = [
    new Integrations.BrowserTracing({ routingInstrumentation, tracingOrigins })
  ];

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
  // monitoring. We recommend adjusting this value in production
  const sentryOptions = { Vue, dsn, integrations, tracesSampleRate: 1.0 };
  if (isLive) {
    sentryOptions.trackComponents = [
      'Header', 'Navigation', 'Footer', 'LandingPage', 'DestinationFeed',
      'DestinationPage', 'InspirationPage', 'Booking', 'ConfirmationStep',
      'DetailsStep', 'PaymentStep'
    ];
    sentryOptions.hooks = [ 'create', 'mount' ];
  }

  Sentry.init(sentryOptions);
}
