<template>
  <div class="tw-flex tw-w-1/2">
    <div class="checkout-icon tw-flex-shrink-0 tw-bg-primary tw-bg-opacity-30 tw-p-3 tw-rounded-full tw-text-primary tw-flex tw-items-center"><slot name="icon"></slot></div>
    <div class="tw-pl-3">
      <h3 class="tw-text-xl tw-font-bold">{{title}}</h3>
      <template v-for="(item, index) in items">
        <div :key="`order_${index}`" class="tw-text-md tw-my-2">{{item}}</div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    items: Array,
  }
};
</script>

<style lang="scss" scoped>
.checkout-icon {
  width: 45px;
  height: 45px;
}
</style>
