<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-flex-row tw-m-0">
      <div class="app-card-container tw-mb-10 tw-flex-grow">
        <template v-if="success">
          <Confirmation />
        </template>

        <template v-if="!success">
          <PaymentDetails @succeeded="paymentSucceeded" />
        </template>
      </div>
      <div class="app-card-container summary-container">
        <TripSummary :canBeEdited="false" :activeTabName="''"/>
      </div>
    </div>
  </div>
</template>

<script>
import Confirmation from '@/views/booking/steps/payment/Confirmation';
import PaymentDetails from '@/views/booking/steps/payment/PaymentDetails';
import TripSummary from '../tripSummary/TripSummary.vue';

export default {
  name: 'PaymentStep',
  components: {
    PaymentDetails,
    Confirmation,
    TripSummary
  },
  data() {
    return {
      success: false
    };
  },
  methods: {
    paymentSucceeded() {
      this.success = true;
    }
  }
};
</script>

<style scoped>
.summary-container {
  margin-left: 40px;
  height: fit-content;
}
</style>
