<template>
  <div>
    <div
      class="
        tw-px-8
        tw-pt-3
        tw-pb-8
        tw-border
        tw-rounded-xl
        tw-mb-6
        tw-max-w-screen-sm
        tw-m-auto
      "
    >
      <div class="tw-flex tw-items-start">
        <div class="round">
          <input
            type="checkbox"
            id="checkbox"
            v-model="isStoredUse"
            class="tw-h-5 tw-w-5"
          />
          <label for="checkbox"></label>
        </div>
        <div class="tw-flex tw-flex-col tw-ml-4">
          <div class="stored tw-text-2xl tw-font-medium">
            Use Stored Details
          </div>
          <div
            class="tw-text-base tw-font-light nameDetails tw--ml-4"
          >
            {{
              !!holderData
                ? `${holderData.firstName} ${holderData.lastName}`
                : 'Anonimous'
            }}
          </div>
           <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mb-1 tw-mt-4">
            <overview-field>
            <div v-if="!!holderData.phone.value" class="tw-font-bold tw-mr-3"> {{holderData.email}}</div>
            <template slot="icon"><email-item /></template>
          </overview-field>
          <overview-field>
            <div v-if="!!holderData.email" class="tw-font-bold tw-mr-3">{{holderData.phone.code + holderData.phone.value}}</div>
            <template slot="icon"><phone-item /></template>
          </overview-field>
          </div>
          <!-- <div class="tw-pl-8">
            <div  class="tw-flex tw-items-center">
              <phone-icon></phone-icon>
              <div class="tw-ml-5">
                
              </div>
            </div>
            <div class="tw-flex tw-items-center">
              <email-icon></email-icon>
              <div class="tw-ml-5">
               
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//import PhoneIcon from '@/assets/bookingPage/acommodations/phone.svg';
//import EmailIcon from '@/assets/bookingPage/acommodations/email.svg';
import EmailItem from '@/assets/bookingPage/flights/overview/email.svg';
import PhoneItem from '@/assets/bookingPage/flights/overview/phone.svg';
import OverviewField from '../tabs/flights/steps/overview/components/OverviewField.vue';

export default {
  props: [ 'value' ],
  components: {
    //PhoneIcon,
    //EmailIcon
    OverviewField,
    EmailItem,
    PhoneItem,
  },
  computed: {
    ...mapGetters([
      'holderData',
    ]),
    isStoredUse: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      }
    },
    ...mapGetters('user', [ 'user' ]),
    ...mapGetters('flights', [ 'passengers', 'contactEmail', 'contactPhone' ])
  },
};
</script>

<style scoped>
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.stored {
  font-size: 1rem;
  font-family: 'PoppinsSemiBold';
}

.nameDetails {
  color: #28262E;
  font-family: 'MontserratSemiBold';
}

.date-title {
    font-size: 1rem;
    margin-top: 48px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'PoppinsSemiBold';
}

</style>
