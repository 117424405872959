<template>
  <div class="details-container tw-overflow-y-auto tw-px-2">
    <h3 class="booking-title">Booking Details</h3>
    <h4 class="date-title">Date and Time</h4>
    <template v-if="activitiesInCart">
      <transition name="fade" appear>
        <div
          class="modal-overlay"
          v-show="showModal >= 0"
          @click="closeModal"
        ></div>
      </transition>
      <template v-for="(item, index) in activitiesInCart">
        <activity-item
          :key="`activity_${item.id}`"
          :name="item.name"
          :date="item.date"
          :quantity="item.quantity"
          @open="editActivity(index)"
        />
        <div v-if="showModal >= 0" :key="`activiti_modal_${item.id}`">
          <transition name="slide" appear>
            <div class="modal" v-if="showModal === index">
              <activity-modal
                :item="item"
                class="modal"
                @close="closeModal"
                @update="updateModal"
              />
            </div>
          </transition>
        </div>
      </template>
    </template>
    <h4 v-if="isShow" class="date-title">Bookings shall be made under</h4>
    <use-stored-details
      v-if="isShow"
      :value="isActivitiesUserStored"
      @update="handleChangeUserData"
    ></use-stored-details>

    <div class="tw-flex">
      <v-btn
        class="add-details-btn tw-mb-6"
        v-if="addNewDetails === false && isShow"
        @click="
          () => {
            $store.commit('setNewDetailsActivitiesUsed', true);
          }
        "
        >Or, Add new details</v-btn
      >
    </div>
    <template v-if="addNewDetails || !isShow">
      <div class="booking-title">
        Add <template v-if="isShow">New</template> Details
      </div>
      <add-new-user-details
        userName="current"
        @change="handleChange"
        :data="activitiesCheckoutData['current']"
      ></add-new-user-details>
    </template>
    <div class="d-flex tw-mt-10 tw-float-right">
      <div class="tw-flex tw-flex-wrap">
        <v-btn
          class="rounded-pill tw-mb-2 back-button"
          v-if="!firstTab"
          min-width="150px"
          color="secondary"
          @click="back"
          >Go Back</v-btn
        >
        <v-btn
          class="rounded-pill tw-mb-2"
          min-width="150px"
          color="primary"
          :disabled="!activitiesComplete"
          @click="next"
          >{{
            lastTab ? 'Continue to Payment' : 'Go to ' + tabs[activeTabIndex + 1].title
          }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import AddNewUserDetails from './components/AddNewUserDetails.vue';
import ActivityItem from './components/ActivityItem.vue';
import ActivityModal from './components/ActivityModal.vue';
import UseStoredDetails from '@/views/booking/steps/details/components/UseStoredDetails.vue';

export default {
  components: {
    ActivityItem,
    ActivityModal,
    UseStoredDetails,
    AddNewUserDetails
  },
  props: {
    lastTab: Boolean,
    firstTab: Boolean,
    tabs: Array,
    activeTabIndex: Number
  },
  data: () => ({
    isUserStored: false,
    showModal: -1,
    title: 'Activities'
  }),
  computed: {
    ...mapGetters([
      'activitiesInCart',
      'activitiesComplete',
      'activitiesCheckoutData',
      'isActivitiesUserStored',
      'holderData'
    ]),
    ...mapActions([ 'addExperienceToCart', 'increment', 'decrement' ]),
    ...mapState({
      addNewDetails: state => state.experiences.newDetailsActivitiesUsed
    }),
    ...mapGetters('user', [ 'loggedIn' ]),
    ...mapGetters('flights', [ 'contactEmail', 'contactPhone' ]),
    experienceshoppingcart: () => {
      return this.$store.state.experienceshoppingcart;
    },
    isShow: function() {
      return (
        !(this.holderData.sourceTab === this.title) &&
        // (this.$store.getters['user/loggedIn'] ||
        this.holderData.firstName &&
        this.holderData.lastName
        // )
      );
    }
  },
  watch: {
    showModal: function() {
      if (this.showModal >= 0) {
        document.querySelector('html')?.classList.add('modal-open');
      } else {
        document.querySelector('html')?.classList.remove('modal-open');
      }
    }
  },
  methods: {
    ...mapMutations([ 'updateDates' ]),
    ...mapMutations([ 'setHolderData' ]),
    editActivity(index) {
      this.showModal = index;
    },
    closeModal() {
      this.showModal = -1;
    },

    back() {
      this.$emit('prev-tab');
    },
    next() {
      this.$emit('next-tab');
    },
    updateModal(experience, date, time, quantity) {
      this.closeModal();
      experience.date = date;
      experience.time = time;

      while (experience.quantity > quantity) {
        this.$store.dispatch('decrement', experience);
      }

      while (experience.quantity < quantity) {
        this.$store.dispatch('increment', experience);
      }
    },
    handleChange(data) {
      this.$store.commit('setActivitiesCheckoutData', data);

      if (
        !this.holderData.sourceTab ||
        !(this.holderData.firstName && this.holderData.lastName) ||
        this.holderData.sourceTab === this.title
      )
        this.setHolderData({ ...data.current, sourceTab: this.title });
    },
    handleChangeUserData(data) {
      this.$store.commit('setIsActivitiesUserStored', data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0;

  background: rgba(0, 0, 0, 0.8);
  z-index: 98;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;

  padding: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.booking-title {
  font-size: 2rem;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'PoppinsMedium';
}

.date-title {
  font-size: 1rem;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'PoppinsSemiBold';
}

.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}
.add-details-btn {
  margin: auto;
  max-width: 500px;
  width: 100%;
}
</style>
