<template>
  <div class="tw-flex tw-flex-col">
    <details-tabs
      v-if="showDetailsTabs"
      :tabs="tabs"
      :activeTabIndex="activeTabIndex"
      v-model="activeTabIndex"
    />
    <div class="tw-flex tw-flex-row tw-m-0">
      <div class="app-card-container tw-mb-10 tw-flex-grow">
        <component
          v-if="showDetailsTabs"
          :is="activeTab"
          :tabs="tabs"
          :activeTabIndex="activeTabIndex"
          @next-tab="nextTab"
          @prev-tab="prevTab"
          :firstTab="firstTab"
          :lastTab="lastTab"
        />
        <template v-else>
          <SignInComponent
            @setShowAuth="setShowAuth"
            v-show="showAuth == 'SignIn'"
          />
          <SignUpComponent
            @setShowAuth="setShowAuth"
            v-show="showAuth == 'SignUp'"
          />
          <ForgotPasswordComponent
            @setShowAuth="setShowAuth"
            v-show="showAuth == 'ForgotPassword'"
          />
        </template>
      </div>
      <div class="app-card-container summary-container">
        <TripSummary
          :canBeEdited="true"
          :activeTabName="
            loggedIn || showAuth == 'SkipBecauseGuest' ? activeTab : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import DetailsTabs from './components/DetailsTabs.vue';
import TripSummary from '../tripSummary/TripSummary.vue';
import { detailsTabsNames } from './detailsConstants';
import Flights from './tabs/flights/Flights.vue';
import Accommodation from './tabs/accommodation/Accommodation.vue';
import Restaurants from './tabs/restaurants/Restaurants.vue';
import { mapGetters } from 'vuex';
import Activities from './tabs/activities/Activities.vue';
import AccommodationIconWhite from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import SignInComponent from '../../auth/SignIn/SignInComponent.vue';
import SignUpComponent from '../../auth/SignUp/SignUpComponent.vue';
import ForgotPasswordComponent from '../../auth/ForgotPassword/ForgotPasswordComponent.vue';
import ActivitiesIconWhite from '@/assets/inspirationPage/tabicons/activities_w.svg';
import NightlifeIconWhite from '@/assets/inspirationPage/tabicons/nightlife_w.svg';
import RestaurantIconWhite from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import FlightIconWhite from '@/assets/inspirationPage/tabicons/plane_w.svg';
export default {
  components: {
    DetailsTabs,
    Flights,
    Accommodation,
    Restaurants,
    Activities,
    TripSummary,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    AccommodationIconWhite,
    ActivitiesIconWhite,
    NightlifeIconWhite,
    RestaurantIconWhite,
    FlightIconWhite
  },
  data: () => ({
    detailsTabsNames,
    activeTabIndex: 0,
    tabs: [],
    showAuth: 'SignIn'
  }),
  computed: {
    ...mapGetters('flights', [ 'complete', 'flightComplete' ]),
    ...mapGetters([
      'accommodationsInCart',
      'activitiesInCart',
      'restaurantsInCart',
      'flightsInCart',
      'hotelsComplete',
      'restaurantsComplete',
      'activitiesComplete'
    ]),
    ...mapGetters({
      user: 'user/data',
      loggedIn: 'user/loggedIn'
    }),
    showDetailsTabs() {
      return (
        this.showAuth == 'SkipBecauseGuest' ||
        (this.loggedIn &&
          !(this.showAuth === 'SignUp') &&
          (this.$store.state.user.data
            ? this.$store.state.user.data.emailVerified
            : false))
      );
    },
    activeTab() {
      return this.tabs[this.activeTabIndex].componentName;
    },
    firstTab() {
      return this.activeTabIndex === 0;
    },
    lastTab() {
      return this.activeTabIndex === this.tabs.length - 1;
    }
  },
  watch: {
    accommodationsInCart: {
      handler: function(v) {
        this.calcTabs(
          v,
          this.activitiesInCart,
          this.restaurantsInCart,
          this.flightsInCart
        );
      },
      immediate: true
    },
    activitiesInCart: {
      handler: function(v) {
        this.calcTabs(
          this.accommodationsInCart,
          v,
          this.restaurantsInCart,
          this.flightsInCart
        );
      },
      immediate: true
    },
    restaurantsInCart: {
      handler: function(v) {
        this.calcTabs(
          this.accommodationsInCart,
          this.activitiesInCart,
          v,
          this.flightsInCart
        );
      },
      immediate: true
    },
    flightsInCart: {
      handler: function(v) {
        this.calcTabs(
          this.accommodationsInCart,
          this.activitiesInCart,
          this.restaurantsInCart,
          v
        );
      },
      immediate: true
    },
    flightComplete: {
      handler: function(v) {
        this.changeComleted('flights', v);
      },
      immediate: true
    },
    hotelsComplete: {
      handler: function(v) {
        this.changeComleted('accommodation', v);
      },
      immediate: true
    },
    restaurantsComplete: {
      handler: function(v) {
        this.changeComleted('restaurants', v);
      },
      immediate: true
    },
    activitiesComplete: {
      handler: function(v) {
        this.changeComleted('experiences', v);
      },
      immediate: true
    }
  },
  methods: {
    setShowAuth(value) {
      this.showAuth = value;
    },
    calcTabs(accommodations, activities, restaurants, flights) {
      const allowTabs = [];
      accommodations.length && allowTabs.push('accommodation');
      activities.length && allowTabs.push('experiences');
      restaurants.length && allowTabs.push('restaurants');
      flights.length && allowTabs.push('flights');
      this.tabs = this.detailsTabsNames.filter(item =>
        allowTabs.includes(item.tab)
      );
    },
    nextTab() {
      !this.lastTab
        ? this.activeTabIndex++
        : this.$router.push({ name: 'BookingPayment' });
    },
    prevTab() {
      this.activeTabIndex--;
    },
    changeActiveTab(number) {
      this.activeTabIndex = number;
    },
    changeComleted(item, val) {
      const tabIndex = this.tabs.findIndex(t => t.tab === item);
      if (tabIndex !== -1) {
        const tabs = [ ...this.tabs ];
        tabs[tabIndex] = { ...tabs[tabIndex], checked: val };
        this.tabs = tabs;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 2rem;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'PoppinsMedium';
}

.summary-container {
  margin-left: 40px;
  height: fit-content;
}

@media (max-width: 1264px) {
}
</style>
