<template>
  <div>
    <h1>Great, your booking is complete now!</h1>
  </div>
</template>

<script>
import confetti from 'canvas-confetti';

export default {
  name: 'Confirmation',
  mounted() {
    confetti({
      particleCount: 300,
      spread: 70,
      origin: { y: 0.6 }
    });
  }
};
</script>

<style scoped>

</style>
