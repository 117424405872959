<template>
  <MediaQueryProvider :queries="$options.queries">
    <v-app>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </MediaQueryProvider>
</template>

<script>
import { MediaQueryProvider } from 'vue-component-media-queries';
const queries = {
  'sm': '(min-width: 640px)',
  'md': '(min-width: 768px)',
  'lg': '(min-width: 1024px)',
  'xl': '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
};

export default {
  name: 'App',
  metaInfo: {
    title: 'Balloon',
    meta: [
      { name: 'description', content:  'Get your travel inspiration' },
      { name: 'keywords', content: 'travels, rest' } 
    ]
  },
  components: {
    MediaQueryProvider,
  },
  queries,

  data: () => ({
    //
  }),
};
</script>
