<template>
  <div class="guarantee-block tw-border tw-border-primary tw-rounded-2xl tw-m-3 tw-h-full tw-flex tw-p-3 tw-cursor-pointer"
  @click="$emit('select-card')"
  >
    <div class="tw-mr-3">
      <component :is="cardIcon" />
    </div>
    <div>
      <h3>{{title}}</h3>
      <p>{{description}}</p>
      <div class="tw-text-danger tw-text-sm">{{dangerText}}</div>
      <div class="tw-text-success tw-text-sm">{{successText}}</div>
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/assets/bookingPage/flights/checked.svg';
import UncheckedIcon from '@/assets/bookingPage/flights/unchecked.svg';
import EmptyIcon from '@/assets/bookingPage/flights/emptyMarker.svg';

export default {
  components: {
    CheckedIcon,
    UncheckedIcon,
  },
  props: {
    title: String,
    description: String,
    icon: String,
    dangerText: String,
    successText: String,
    active: Boolean,
  },
  computed: {
    cardIcon() {
      return !this.active ? EmptyIcon : this.dangerText ? UncheckedIcon : CheckedIcon;
    }
  },
  methods: {
    select() {
      this.$emit('select');
    },
  }
};
</script>

<style lang="scss" scoped>
.guarantee-block{
  min-height: 140px;
}
</style>
