<template>
  <div>
    <v-form v-model="formVailid">
      <div class="tw-flex tw-justify-between tw-items-end mt-8 tw-relative">
        <h3 class="flights-step-title ">
          {{ index === 0 ? 'Primary Passenger' : `Passenger ${index + 1}` }}
        </h3>
        <v-btn
          class="tw-absolute tw-right-0 remove-passenger"
          v-if="!!index"
          x-small
          @click="$emit('remove-passenger')"
          >Remove Passenger</v-btn
        >
      </div>
      <div class="tw-grid tw-mt-3 tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-6">
        <div class="tw-py-0">
          <app-text-input
            v-model="firstName"
            label="First Name"
            placeholder="Name"
            :rules="rules.firstName"
            prependIcon="mdi-account-outline"
            small
          />
        </div>
        <div class="tw-py-0">
          <app-text-input
            v-model="lastName"
            label="Last Name"
            placeholder="Name"
            :rules="rules.lastName"
            prependIcon="mdi-account-outline"
            small
          />
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-6">
        <div class="tw-py-0">
          <app-autocomplete
            :items="countriesList"
            v-model="nationality"
            label="Nationality"
            placeholder="Nation Name"
            :rules="rules.nationality"
            small
          />
        </div>
        <div class="tw-py-0">
          <app-autocomplete
            :items="genders"
            v-model="gender"
            label="Gender"
            placeholder="male, female"
            :rules="rules.gender"
            small
          />
        </div>
      </div>

      <div class="tw-mt-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-6">
        <div class="tw-py-0">
          <app-text-input
            v-model="cardno"
            label="Passport or ID number"
            :rules="rules.cardno"
            placeholder="0000 0000 0000 0000"
            small
          />
        </div>
        <div class="tw-py-0">
          <details-datepicker
            label="Date of Birth"
            placeholder="DD/MM/YY"
            v-model="birthdayDate"
            :max="maxBirthday"
          />
        </div>
      </div>

      <div class="tw-mt-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-6">
        <div class="tw-py-0">
          <details-datepicker
            label="Passport or ID expiry date"
            placeholder="DD/MM/YY"
            v-model="expirationDate"
            :min="minExpiration"
            :rules="rules.expiration"
          />
        </div>
      </div>

      <h3 class="flights-step-title tw-mt-3">Baggage</h3>
      <cabin-baggage-block
        v-if="!!handBaggage.length"
        label="Cabin Baggage"
        :dimentions="handBaggageDimentions"
      />
      <baggage-selector
        label="Checked Baggage"
        :items="holdBaggage"
        clearable
        v-model="passengerBaggage"
      />
    </v-form>
  </div>
</template>

<script>
import AppTextInput from '@/components/controlsV2/AppTextInput.vue';
import { requiredRule, nameRule } from '@/utils/rules';
import AppAutocomplete from '@/components/controlsV2/AppAutocomplete.vue';
import countries from '@/assets/constants/countries.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaggageSelector from './BaggageSelector.vue';
import { ageCategory } from '@/store/flights/flightsUtils';
import CabinBaggageBlock from './CabinBaggageBlock.vue';
import moment from 'moment';
import DetailsDatepicker from '../detailsDate/DetailsDatepicker.vue';

export default {
  components: {
    AppTextInput,
    AppAutocomplete,
    BaggageSelector,
    CabinBaggageBlock,
    DetailsDatepicker
  },
  props: {
    passenger: Object,
    index: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    countries,
    title: 'flights',
    genders: [ 'Male', 'Female' ],
    rules: {
      firstName: [
        requiredRule('First name is required'),
        nameRule('First Name can contain letters only')
      ],
      lastName: [
        requiredRule('Last name is required'),
        nameRule('Last Name can contain letters only')
      ],
      cardno: [ requiredRule('Passport or ID number is required') ],
      nationality: [ requiredRule('Nationality is required') ],
      gender: [ requiredRule('Gender is required') ],
      expiration: [ requiredRule('Expiration is required') ]
    },
    maxAge: 18
  }),
  computed: {
    ...mapGetters([ 'holderData' ]),
    ...mapGetters('flights', [
      'handBaggageDimentions',
      'holdBaggageDimentions',
      'bagsPrice',
      'flight'
    ]),
    maxBirthday() {
      return moment().format('YYYY-MM-DD');
    },
    minExpiration() {
      return moment(this.flight.arrival_at).format('YYYY-MM-DD');
    },
    countriesList() {
      return this.countries.map(country => country.name);
    },
    handBaggage() {
      const baggage = [];
      const { length, height, width, weight } = this.handBaggageDimentions;
      [ length, height, width, weight ].every(dimantion => !!dimantion) &&
        baggage.push({
          dimentions: this.handBaggageDimentions
        });
      return baggage;
    },
    holdBaggage() {
      const items = [];
      this.bagsPrice &&
        Object.entries(this.bagsPrice).forEach(([ key, price ]) => {
          items.push({
            key: parseInt(key),
            price,
            dimentions: this.holdBaggageDimentions
          });
        });
      return items;
    },
    formVailid: {
      get() {
        return this.passenger.valid;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'valid',
          value
        });
      }
    },
    firstName: {
      get() {
        return this.passenger.firstName;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'firstName',
          value
        });
        this.index === 0 && this.setHolder('firstName', value);
      }
    },
    lastName: {
      get() {
        return this.passenger.lastName;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'lastName',
          value
        });
        this.index === 0 && this.setHolder('lastName', value);
      }
    },
    nationality: {
      get() {
        return this.passenger.nationality?.name;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'nationality',
          value: this.countries.find(country => country.name === value)
        });
      }
    },
    gender: {
      get() {
        return this.passenger.gender;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'gender',
          value
        });
      }
    },
    birthdayDate: {
      get() {
        return this.passenger.birthdayDate;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'birthdayDate',
          value
        });
      }
    },
    cardno: {
      get() {
        return this.passenger.cardno;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'cardno',
          value
        });
      }
    },
    expirationDate: {
      get() {
        return this.passenger.expirationDate;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'expirationDate',
          value
        });
      }
    },
    handBags: {
      get() {
        return this.passenger.handBags;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'handBags',
          value
        });
      }
    },
    holdBags: {
      get() {
        return this.passenger.holdBags;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'holdBags',
          value
        });
      }
    },
    passengerBaggage: {
      get() {
        return this.passenger.baggage;
      },
      set(value) {
        this.setPassengerField({
          index: this.index,
          field: 'baggage',
          value
        });
        this.heartbeat();
      }
    }
  },
  methods: {
    ...mapActions('flights', [ 'heartbeat' ]),
    ...mapMutations('flights', [ 'setPassengerField' ]),
    ...mapMutations([ 'setHolderData' ]),
    filterBaggageList(baggageList) {
      return (
        baggageList?.filter(def =>
          def.conditions?.passenger_groups.includes(
            ageCategory(this.birthdayDate)?.category
          )
        ) || []
      );
    },
    setHolder(name, value) {
      if (
        !this.holderData.sourceTab ||
        !(this.holderData.firstName && this.holderData.lastName) ||
        this.holderData.sourceTab === this.title
      )
        this.setHolderData({
          ...{
            [name]: value
          },
          sourceTab: this.title
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.remove-passenger {
  box-shadow: none !important;
  bottom: calc(50% - 19px);
}
</style>
