<template>
  <div class="tw-p-3">
    <div class="tw-font-poppins tw-font-semibold">{{ departureDate }}</div>
    <div class="tw-flex tw-my-3">
      <div class="tw-flex tw-flex-col tw-items-center">
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
        <div class="tw-flex-grow tw-relative tw-flex">
          <div class="flight-arrow tw-flex tw-items-center"><PlaneIcon /></div>
          <div class="path-line"></div>
        </div>
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
      </div>
      <div class="tw-py-1 tw-pl-4">
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-font-monserrat-medium tw-font-bold tw-mr-2 tw-mb-2">
            {{ departureTime }} {{ cityFrom }}
          </div>
          <div class="tw-text-grey_medium">{{ airportFrom }}</div>
        </div>
        <div class="tw-flex tw-items-center tw-my-4" style="flex-wrap: wrap">
          <div
            class="
              tw-rounded-full
              tw-w-max
              tw-px-5
              tw-py-1
              tw-text-sm
              tw-bg-secondary
              tw-my-3
              mr-4
              tw-text-primary tw-font-bold
            "
          >
            {{ flyDuration }}
          </div>
          <div class="tw-flex">
            <template v-for="(airline, index) in departureAirlines">
              <img
                :key="`airline_${index}`"
                :src="airline"
                class="mr-2 tw-rounded-full tw-border tw-border-grey_light"
              />
            </template>
          </div>
          <div
            class="
              tw-rounded-full tw-w-max tw-px-5 tw-py-1 tw-text-sm tw-my-3
              mr-4
              tw-font-bold
              chip-grey
            "
          >
            {{
              departureAirlines.length === 0 || departureAirlines.length === 1
                ? 'Direct'
                : departureAirlines.length + ' stops'
            }}
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-font-monserrat-medium tw-font-bold tw-mr-2 tw-mb-2">
            {{ arrivalTime }} {{ cityTo }}
          </div>
          <div class="tw-text-grey_medium">{{ airportTo }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="departureDate !== arrivalDate"
      class="tw-font-poppins tw-font-semibold"
    >
      {{ arrivalDate }}
    </div>
  </div>
</template>

<script>
import PlaneIcon from '@/assets/bookingPage/flights/plane.svg';

export default {
  components: {
    PlaneIcon,
  },
  props: {
    title: String,
    departureTime: String,
    departureDate: String,
    arrivalTime: String,
    arrivalDate: String,
    cityFrom: String,
    airportFrom: String,
    airportFromCode: String,
    cityTo: String,
    airportTo: String,
    airportToCode: String,
    cityCodeTo: String,
    flyDuration: String,
    departureAirlines: Array,
  },
};
</script>

<style lang="scss" scoped>
.path-line {
  content: '';
  position: absolute;
  left: calc(50% - 1px);
  width: 1px;
  border-right: 2px solid #ffe4ec;
  top: -7px;
  bottom: -7px;
}

.flight-arrow {
  width: 15px;
  z-index: 1;
  &::before {
    background: white;
  }
}

.chip-grey {
  color: #414145;
  background: #ececec;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
