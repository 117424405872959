import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Vuetify);
Vue.use(VueToast);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#D62A53',
        primaryMedium: '#e67f98',
        grayMain: '#595959',
        greyLight1: '#e0e0e0',
        accent: '#d62a53',
        secondary: '#FFE4EC',
      },
    },
  },
};

export default new Vuetify(opts);
