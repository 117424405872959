<template>
  <div
    class="activity-container tw-border tw-rounded-xl tw-flex tw-justify-between tw-items-center tw-py-1 tw-px-6 tw-m-auto"
  >
    <div class="tw-flex-grow tw-flex tw-flex-row  tw-pr-3 ">
      <div class="tw-font-bold tw-mr-3  tw-py-2 my-5 tw-h-9">{{ name }}</div>
      <div class="tw-text-right  tw-py-2 my-5 tw-h-9">{{ datesParse }}</div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'name', 'dateIn', 'dateOut' ],
  computed: {
    datesParse: {
      get() {
        return this.formatDate(this.dateIn) + ' - ' + this.formatDate(this.dateOut);
      }
    }
  },
  methods: {
    openCard() {
      console.log('opens');
    },
    formatDate(previusFormat) {
      let d = new Date(previusFormat);
      return (
        d.toLocaleString('default', { day: 'numeric' }) +
        ' ' +
        d.toLocaleString('default', { month: 'short' }) +
        ' ' +
        d.toLocaleString('default', { year: 'numeric' })
      );
    }
  }
};
</script>

<style>
.activity-container {
  max-width: 571px !important;
  margin-bottom: 8px;
}
</style>
