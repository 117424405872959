<template>
  <div class="tw-px-1 md:tw-px-11 tw-pb-5 tw-relative">
    <circle-loader :show="loading"
      ><h2 class="tw-text-lg tw-text-center">
        Loading details...
      </h2></circle-loader
    >
    <h3 class="flights-step-title tw-mb-3">Overview</h3>
    <h4 class="tw-font-semibold tw-text-center tw-mb-6">Itinerary</h4>
    <flights-item v-bind="flight" noFooter />
    <passengers
      :items="passengers"
      :email="contactEmail"
      :phone="contactPhoneStr"
    />
    <baggage :passengers="passengers" />
    <!-- <div
      class="tw-font-bold tw-text-right tw-pr-4 tw-border-t tw-pt-2 tw-mt-5 tw-border-grey_medium tw-border-opacity-70"
    >
      Tickets price:
      <span class="tw-text-lg tw-text-primary">{{ ticketsPrice }}</span>
      {{ APP_CURRENCY }}
    </div> -->
    <!-- <div class="tw-font-bold tw-text-right tw-pr-4">
      Total price:
      <span class="tw-text-lg tw-text-primary">{{ totalPrice }}</span>
      {{ APP_CURRENCY }}
    </div> -->
    <div class="bar">
      <v-btn class="back-button" @click="$emit('prev-step')">Go Back</v-btn>
      <v-btn color="primary" @click="proceed">{{
        lastTab ? 'Continue to Payment' : 'Go to ' + tabs[activeTabIndex + 1].title
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FlightsItem from './components/FlightsItem.vue';
import Passengers from './components/Passengers.vue';
import Baggage from './components/Baggage';
import { APP_CURRENCY } from '@/assets/constants/appConfig';
import CircleLoader from '@/components/controls/containers/circleLoader/CircleLoader';

export default {
  components: {
    FlightsItem,
    Passengers,
    Baggage,
    CircleLoader
  },
  props: {
    lastTab: Boolean,
    tabs: Array,
    activeTabIndex: Number
  },
  data: () => ({
    APP_CURRENCY,
    loading: false,
  }),
  computed: {
    ...mapGetters('flights', [
      'flight',
      'contactEmail',
      'contactPhoneStr',
      'passengers',
      'totalPrice',
      'checkedPrice',
      'ticketsPrice'
    ]),
    route() {
      return this.flight.route;
    }
  },
  methods: {
    ...mapActions('flights', [ 'saveTicket' ]),
    async proceed() {
      if (this.lastTab) {
        await this.confirmPurchase();
      } else {
        this.$emit('next-tab');
      }
    },
    async confirmPurchase() {
      this.loading = true;
      (await this.saveTicket()) &&
        this.$router.push({
          path: '/booking/payment'
        });
      this.loading = false;
    }
  }
};
</script>
<style scoped>
.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
  background: #ffe4ec !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}

.bar {
  text-align: right;
  padding: 24px 0;
  padding-top: 8px;
  margin-bottom: 68px;
}
</style>
