export const requiredRule = errMessage => {
  return v => !!v || errMessage;
};

export const emailRule = errMessage => {
  return v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || errMessage;
};

export const nameRule = errMessage => {
  return v => /^[a-zA-Z\-.'_]+$/.test(v) || errMessage;
};
