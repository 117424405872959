<template>
  <div v-if="passengersBaggage > 0" class="tw-w-full">
    <h4 class="flights-step-title">Baggage</h4>
    <h4 class="tw-text-center tw-text-gray-main tw-mb-4">
      Baggage information
    </h4>
    <template v-for="(baggage, index) in passengersBaggage">
      <div
        :key="`baggage_${index}`"
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-4 tw-mb-3"
      >
        <div
          class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-items-start tw-mb-1 md:tw-mb-0"
        >
          <overview-field>
            <div class="tw-flex tw-flex-wrap tw-items-center">
              <div class="tw-font-semibold tw-mr-2 tw-leading-5">
                {{ baggage.count }} x {{ baggage.title }}
                <span class="tw-text-grey_medium tw-font-medium tw-ml-1">{{
                  baggage.dimentions
                }}</span>
              </div>
            </div>
            <template slot="icon"
              ><component :is="baggage.icon" class="tw-w-5"
            /></template>
          </overview-field>
        </div>
        <div>
          <overview-field>
            <div class="tw-flex tw-flex-col">
              <div
                v-for="(passenger, passIndex) in baggage.passengers"
                :key="`passenger_${index}_${passIndex}`"
                class="tw-flex tw-items-center tw-text-grey_medium tw-mb-1"
              >
                <user-item class="tw-mr-2" />
                <div class="tw-font-semibold">{{ getFullName(passenger) }}</div>
              </div>
            </div>
          </overview-field>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HandBag from '@/assets/bookingPage/flights/handBag.svg';
import HoldBag from '@/assets/bookingPage/flights/holdBag.svg';
import OverviewField from './OverviewField.vue';
import UserItem from '@/assets/bookingPage/flights/overview/user.svg';

export default {
  components: {
    HandBag,
    HoldBag,
    OverviewField,
    UserItem
  },
  computed: {
    ...mapGetters('flights', [
      'passengers',
      'handBaggageDimentions',
      'holdBaggageDimentions',
      'bagsPrice',
      'bnum'
    ]),
    passengersBaggage() {
      const baggage = [];
      const handBag = this.handBaggageFormation();
      handBag && baggage.push(handBag);
      const holdBag = this.holdBaggageFormation();
      holdBag && baggage.push(holdBag);
      return baggage;
    }
  },
  methods: {
    handBaggageFormation() {
      const { length, height, width, weight } = this.handBaggageDimentions;
      return [ length, height, width, weight ].every(dimantion => !!dimantion)
        ? {
          title: 'Hand Baggage',
          dimentions: `${length} x ${height} x ${width} cm, - ${weight} kg`,
          icon: HandBag,
          count: this.passengers.length,
          passengers: this.passengers
        }
        : null;
    },
    holdBaggageFormation() {
      return this.bnum
        ? {
          title: 'Hold Baggage',
          dimentions: `${this.holdBaggageDimentions.length} x ${this.holdBaggageDimentions.height} x ${this.holdBaggageDimentions.width} cm, ${this.holdBaggageDimentions.weight} kg`,
          icon: HoldBag,
          count: this.bnum,
          passengers: this.passengers.filter(passenger => !!passenger.baggage)
        }
        : null;
    },
    getFullName(passenger) {
      return `${passenger.firstName.charAt(0)}. ${passenger.lastName}`;
    }
  }
};
</script>
