<template>
  <div class="tw-border tw-border-primary tw-rounded-2xl tw-m-3 tw-h-full tw-flex tw-flex-col tw-justify-between">
    <div class="tw-p-3">
    <h3 class="tw-text-center">{{title}}</h3>
    <h3 class="tw-mb-5 tw-text-center">$ {{price}}</h3>
    <div class="tw-flex tw-flex-col">
      <div v-for="(option, index) in options" 
      :key="`${title}_option_${index}`"
      class="tw-flex tw-mb-4">
      <div class="tw-mr-2">
        <CheckedIcon v-if="option.success" />
        <UncheckedIcon v-else />
      </div>
      <div class="tw-text-sm"><b>{{option.title}}</b>&nbsp;<span>{{option.desc}}</span></div>
      </div>
    </div>
    </div>
    <div class="tw-flex tw-justify-center fare-card-button tw-p-2">
      <v-btn :color="active ? 'primary' : 'mainGray'" @click="select">{{actionText}}</v-btn>
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/assets/bookingPage/flights/checked.svg';
import UncheckedIcon from '@/assets/bookingPage/flights/unchecked.svg';

export default {
  components: {
    CheckedIcon,
    UncheckedIcon,
  },
  props: {
    title: String,
    price: Number,
    options: Array,
    actionText: String,
    active: Boolean,
  },
  methods: {
    select() {
      this.$emit('select');
    }
  }
};
</script>

<style lang="scss">
.fare-card-button button{
  padding-right: 5px !important;
  padding-left: 5px !important;
  font-size: 12px !important;
  width: 100%;
}

</style>
