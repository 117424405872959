import { getAuth, signOut } from 'firebase/auth';

// Common pages
import DetailsStep from '@/views/booking/steps/details/DetailsStep';
import PaymentStep from '@/views/booking/steps/payment/PaymentStep';
import ConfirmationStep from '@/views/booking/steps/confirmation/ConfirmationStep';

const CommonLayout = () => import('@/views/layouts/common/CommonLayout.vue');
const AuthLayout = () => import('@/views/layouts/AuthLayout.vue');
const LandingPage = () => import('@/views/landingPage/LandingPage.vue');
const MapPage = () => import('@/views/map/MapPage.vue');
const DestinationFeed = () =>
  import('@/views/destinationFeed/DestinationFeed.vue');
const DestinationPage = () =>
  import('@/views/destinationFeed/DestinationPage.vue');
const InspirationPage = () => import('@/views/inspiration/InspirationPage.vue');
const Booking = () => import('@/views/booking/Booking.vue');
const SignInPage = () => import('@/views/auth/SignIn/SignInPage.vue');
const SignUpPage = () => import('@/views/auth/SignUp/SignUpPage.vue');
const ForgotPasswordPage = () =>
  import('@/views/auth/ForgotPassword/ForgotPasswordPage.vue');
const MobileGalleryExperiences = () =>
  import('@/views/destinationFeed/mobile/MobileGalleryExperiences.vue');
const ExpressStoryView = () =>
  import('@/views/inspiration/insta-story/ExpressStoryView.vue');

const ExpressCheckout = () =>
  import('@/views/expressCheckout/ExpressCheckout.vue');

const OurTrips = () =>
  import(
    '@/views/landingPage/landingPageComponents/ExploreExperiences/ExploreExperiences.vue'
  );
const CityDestinations = () =>
  import(
    '@/views/landingPage/landingPageComponents/Destinations/exoticDestinations.vue'
  );
  
const MobileFeed = () => import('@/views/mobilePages/Feed/MobileFeed.vue');
const MobileStory = () => import('@/views/mobilePages/Stories/MobileStory.vue');
const MobilePackage = () => import('@/views/mobilePages/Packages/MobilePackageView.vue');
const MobilePackageSteps = () => import('@/views/mobilePages/Packages/MobilePackageSteps.vue');
//const HotelSelection = () => import('@/views/mobilePages/Packages/Steps/Hotels/MobileHotelSelection.vue');
//const HotelDescription = () => import('@/views/mobilePages/Packages/Steps/Hotels/MobileHotelDescription.vue');
const MobileFlights = () => import('@/views/mobilePages/Packages/Steps/Flights/MobileFlights.vue');
const MobileActivities = () => import('@/views/mobilePages/Packages/Steps/Activities/MobileActivities.vue');
const MobileCategory = () => import('@/views/mobilePages/CategoryPage/MobileCategory.vue');
//const MobileTripBasket = () => import('@/views/mobilePages/TripBasket/MobileTripBasket.vue');
//import { hotelSelected } from './guards/mobile/packages';

const FullpageStory = () => import('@/views/inspiration/FullpageStories.vue');
import { detailsTabComleted, paided } from './guards/booking';

let commonPages = [
  {
    path: 'fullpageStory',
    name: 'FullpageStory',
    component: FullpageStory,
    meta: {
      darkLogo: true,
      darkMenu: true,
      footerHidden: true,
    },
  },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    // beforeEnter() {
    //   location.href = 'https://info.balloon-to.com';
    // },
  },
  {
    path: '/ourtrips',
    name: 'OurTrips',
    component: OurTrips,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  {
    path: '/citydestinations',
    name: 'CityDestinations',
    component: CityDestinations,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  {
    path: '/map',
    name: 'MapPage',
    component: MapPage,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  {
    path: '/destinations',
    name: 'DestinationFeed',
    component: DestinationFeed,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  {
    path: '/destination/:cityName',
    name: 'DestinationPage',
    component: DestinationPage,
    meta: {
      footerHidden: true,
    },
  },
  {
    path: '/inspiration/:inspirationId',
    name: 'InspirationPage',
    component: InspirationPage,
    meta: {
      darkLogo: true,
      darkMenu: true,
      title: 'Inspiration',
    },
  },
  {
    path: '/about-us',
    name: 'AboutPage',
    beforeEnter() {
      location.href = 'https://info.balloon-to.com/about-1';
    },
  },
  {
    path: '/become-an-influencer',
    name: 'BecomeAnInfluencerPage',
    beforeEnter() {
      location.href = 'https://info.balloon-to.com/contact';
    },
  },
  {
    path: '/imprint',
    name: 'Imprint',
    beforeEnter() {
      location.href = 'https://www.info.balloon-to.com/impressum';
    },
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    beforeEnter() {
      location.href = 'https://www.info.balloon-to.com/datenschutz';
    },
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    beforeEnter() {
      location.href = 'https://www.info.balloon-to.com/contact-us';
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    beforeEnter() {
      window.open(
        'https://4zbsmiafid4.typeform.com/to/rcLpS7Ns#experience=xxxxx',
        '_blank'
      );
    },
  },
  {
    name: 'BookingConfirmation',
    path: '/booking/confirmation',
    component: ConfirmationStep,
    beforeEnter: paided,
    meta: {
      darkLogo: true,
      darkMenu: true,
      footerHidden: true,
    },
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
    redirect: { name: 'BookingDetails' },
    children: [
      {
        name: 'BookingDetails',
        path: 'details',
        component: DetailsStep,
        meta: {
          darkLogo: true,
          darkMenu: true,
        },
      },
      {
        name: 'BookingPayment',
        path: 'payment',
        beforeEnter: detailsTabComleted,
        component: PaymentStep,
        meta: {
          darkLogo: true,
          darkMenu: true,
        },
      },
    ],
  },
  {
    path: '/express-checkout/:experienceId',
    name: 'ExpressCheckout',
    component: ExpressCheckout,
    meta: {
      darkLogo: true,
      darkMenu: true,
      footerHidden: true,
    },
    // redirect: { name: 'ExpressCheckoutFlights', },
    // children: [
    //   {
    //     name: 'ExpressCheckoutFlights',
    //     path: 'flights',
    //     component: ExpressCheckoutFlightsStep,
    //     meta: {
    //       darkLogo: true,
    //       darkMenu: true,
    //       footerHidden: true,
    //     },
    //   },
    //   {
    //     name: 'ExpressCheckoutAccommodation',
    //     path: 'accommodation',
    //     component: ExpressCheckoutAccommodationStep,
    //     meta: {
    //       darkLogo: true,
    //       darkMenu: true,
    //       footerHidden: true,
    //     },
    //   },
    //   {
    //     name: 'ExpressCheckoutActivities',
    //     path: 'activities',
    //     component: ExpressCheckoutActivitiesStep,
    //     meta: {
    //       darkLogo: true,
    //       darkMenu: true,
    //       footerHidden: true,
    //     },
    //   },
    //   {
    //     name: 'ExpressCheckoutRestaurants',
    //     path: 'restaurants',
    //     component: ExpressCheckoutRestaurantsStep,
    //     meta: {
    //       darkLogo: true,
    //       darkMenu: true,
    //       footerHidden: true,
    //     },
    //   },
    // ],
  },
  {
    path: '/mobilefeed/:search/:index',
    name: 'MobileFeed',
    component: MobileFeed,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  {
    path: '/mobilepackage/trip/:experienceId',
    name: 'MobilePackage',
    component: MobilePackage,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  
  {
    path: '/mobilepackagesteps/:experienceId',
    name: 'MobilePackageSteps',
    component: MobilePackageSteps,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
    redirect: { name: 'MobileHotelSelection' },
    children: [
      // {
      //   name: 'MobileHotelSelection',
      //   path: 'hotelselection',
      //   component: HotelSelection,
      //   meta: {
      //     darkLogo: true,
      //     darkMenu: true,
      //   },
      //   children: [
      //     {
      //       name: 'MobileHotelDescription',
      //       path: 'hoteldescription',
      //       component: HotelDescription,
      //       meta: {
      //         darkLogo: true,
      //         darkMenu: true,
      //       },
      //     },
      //   ],
      // },
      {
        name: 'MobileFlights',
        path: '/flights/:experienceId',
        //beforeEnter: hotelSelected,
        component: MobileFlights,
        meta: {
          darkLogo: true,
          darkMenu: true,
        },
      },
      {
        name: 'MobileActivities',
        path: '/activities/:experienceId',
        //beforeEnter: hotelSelected,
        component: MobileActivities,
        meta: {
          darkLogo: true,
          darkMenu: true,
        },
      },
    ],
  },
    

  {
    path: '/mobilecategory',
    name: 'MobileCategory',
    component: MobileCategory,
    meta: {
      darkLogo: true,
      darkMenu: true,
    },
  },
  // {
  //   path: '/mobiletripbasket',
  //   name: 'MobileTripBasket',
  //   component: MobileTripBasket,
  //   meta: {
  //     darkLogo: true,
  //     darkMenu: true,
  //   },
  // },
];

let authPages = [
  {
    path: '/auth/sign-in',
    name: 'SignIn',
    component: SignInPage,
  },
  {
    path: '/auth/sign-up',
    name: 'SignUp',
    component: SignUpPage,
  },
  {
    path: '/auth/sign-out',
    name: 'SignOut',
    beforeEnter() {
      signOut(getAuth());
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
  },
];

const routes = [
  {
    path: '/destination/:cityName/gallery',
    name: 'MobileGalleryExperiences',
    component: MobileGalleryExperiences,
  },
  {
    path: '/story/:inspirationId',
    name: 'ExpressStoryView',
    component: ExpressStoryView,
  },
  {
    path: '/mobilepackage/story/:inspirationId',
    name: 'MobileStory',
    component: MobileStory,
  },
  {
    path: '/',
    component: CommonLayout,
    children: commonPages,
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: authPages,
  },
];

export default routes;
