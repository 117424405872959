<template>
  <div class="summary-block">
    <!-- Top part -->
    <div class="top-part">
      <h3 v-if="activeTabName === 'Flights'">Flight Summary</h3>
      <h3 v-else-if="activeTabName === 'Accommodation'">Accommodation Summary</h3>
      <h3 v-else-if="activeTabName === 'Activities'">Activites Summary</h3>
      <h3 v-else-if="activeTabName === 'Restaurants'">Restaurants Summary</h3>
      <h3 v-else>Trip Summary</h3>
      <div class="scroll-container">
        <display-tabs
          :canBeEdited="canBeEdited"
          :activeTabName="activeTabName"
        ></display-tabs>
      </div>
    </div>
    <!-- Lower part -->
    <div class="lower-part">
      <div class="discount-input-part">
        <input placeholder="Gift card or discount code" /><v-btn
          @click="validateCode"
          >Apply</v-btn
        >
      </div>

      <div class="validate-message" v-if="validateMessage">
        {{ validateMessage }}
      </div>

      <ul>
        <li v-for="(value, propertyName) in orderSummary" :key="propertyName">
          <div class="order-items">
            <div
              class="left"
              :class="{ 'total-price': propertyName == 'Total' }"
            >
              {{ propertyName }}
            </div>

            <div
              class="right"
              :class="{ 'total-price': propertyName == 'Total' }"
            >
              <template v-if="value === 0">
                <span class="tw-text-primary">Free</span>
              </template>
              <template v-else> €{{ roundToTwo(value) }} </template>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DisplayTabs from './tabs/displayTabs.vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';

export default {
  name: 'shoppingCart',
  components: { DisplayTabs },
  data() {
    return {
      validateMessage: ''
    };
  },
  props: {
    canBeEdited: Boolean,
    activeTabName: String
  },

  computed: {
    ...mapGetters([ 'totalPrice', 'totalCartCount', 'drawerState' ]),
    ...mapActions([ 'addExperienceToCart', 'increment', 'decrement' ]),
    orderSummary: () => {
      return {
        'Sub total': store.getters.totalPrice,
        // Tax: 0,
        // Shipping: 0,
        Total: store.getters.totalPrice
      };
    }
  },
  methods: {
    validateCode() {
      this.validateMessage = 'The entered code is invalid';
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 2rem;
  margin: 32px 0 24px 32px;
  font-family: 'PoppinsMedium';
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #28262e;
}

.summary-block {
  width: 519px;
  // max-height: 742px;
  display: flex;
  // height: calc(100vh - 324px - 46px);

  flex-direction: column;

  @media (max-width: 1264px) {
    width: calc(100vw - 519px - 181px - 42px);
    min-width: 370px;
  }
}

.lower-part {
  height: 219px;
  border-top: 1px solid #eeeeee;
}

.top-part {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
input {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 54px;
  margin: 24px;
  flex-grow: 1;
  margin-right: 0;

  font-family: MontserratMedium;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #747378;
  padding: 0 20px;
}

.v-btn {
  background: #ffe4ec !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 24px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  color: #d62a53 !important;
}

.discount-input-part {
  display: flex;
  width: 100%;

  @media (max-width: 1264px) {
    input {
      width: 0;
      // min-width: 344px;
    }
  }
}

.validate-message {
  margin-top: -12px;
  margin-right: 36px;
  margin-bottom: 24px;
  margin-left: 45px;

  font-family: MontserratMedium;

  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.04em;

  color: #747378;
}

.left {
  @apply tw-w-1/2;

  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.04em;

  /* Paragraph */

  color: #747378;
}

.right {
  @apply tw-w-1/2 tw-text-right;
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.order-items {
  @apply tw-flex tw-flex-row;
  margin: 12px 0 0 0;
}

ul {
  padding: 0 24px 24px 24px;
  margin-top: -6px;
}
.total-price {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: -0.04em;

  color: #28262e;
}
.scroll-container {
  overflow: hidden;
}
</style>
