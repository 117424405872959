<template>
  <div class="app-autocomplete-v2 d-flex flex-column" :class="{'small': small}">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{label}}</div>
    <v-autocomplete
    :items="items"
    outlined
    dense
    :placeholder="placeholder"
    :value="value"
    @input="(val) => $emit('input', val)"
    :rules="rules"
    />
  </div>
</template>
<script>
export default {
  props: {
    items: Array,
    value: String,
    label: String,
    placeholder: String,
    rules: Array,
    small: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
};
</script>

<style>
.app-autocomplete-v2 .v-input__slot fieldset {
  border-color: #EEEEEE !important;
}
</style>
