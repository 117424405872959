<template>
  <v-form v-model="formValid">
    <div
      class="tw-grid tw-mt-3 tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-6 tw-w-9/12 tw-m-auto tw-pb-4"
    >
      <div class="tw-py-0">
        <app-text-input
          v-model="firstName"
          label="First Name"
          placeholder="Name"
          :rules="rules.firstName"
          prependIcon="mdi-account-outline"
          small
        />
      </div>
      <div class="tw-py-0">
        <app-text-input
          v-model="lastName"
          label="Last Name"
          placeholder="Name"
          :rules="rules.lastName"
          prependIcon="mdi-account-outline"
          small
        />
      </div>
    </div>
    <div class="tw-w-9/12 tw-m-auto">
      <div class="mt-3">
        <app-text-input
          v-model="email"
          label="Email address"
          placeholder="Enter email"
          prependIcon="mdi-email-outline"
          :rules="rules.email"
        />
        <app-phone-input
          v-model="phone"
          label="Phone Number"
          :rules="rules.phone"
          prependIcon="mdi-phone-outline"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import AppTextInput from '@/components/controlsV2/AppTextInput.vue';
import AppPhoneInput from '@/components/controlsV2/AppPhoneInput.vue';
import { requiredRule, emailRule } from '@/utils/rules';
export default {
  props: [ 'userName', 'data' ],
  data: () => ({
    rules: {
      email: [
        requiredRule('E-mail is required'),
        emailRule('E-mail must be valid')
      ],
      phone: [ requiredRule('Phone is required') ],
      firstName: [ requiredRule('First name is required') ],
      lastName: [ requiredRule('Last name is required') ]
    },
    formValid: false
  }),
  computed: {
    firstName: {
      get() {
        return this.data.firstName;
      },
      set(value) {
        this.handleChange('firstName', value);
      }
    },
    lastName: {
      get() {
        return this.data.lastName;
      },
      set(value) {
        this.handleChange('lastName', value);
      }
    },
    phone: {
      get() {
        return this.data.phone;
      },
      set(value) {
        this.handleChange('phone', value);
      }
    },
    email: {
      get() {
        return this.data.email;
      },
      set(value) {
        this.handleChange('email', value);
      }
    }
  },
  watch: {
    formValid: {
      handler: function(v) {
        this.handleChange('valid', v);
      },
      immediate: true
    }
  },
  components: {
    AppTextInput,
    AppPhoneInput
  },
  methods: {
    handleChange(name, val) {
      this.$emit('change', {
        [this.userName]: {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          valid: this.formValid,
          email: this.email,
          [name]: val
        }
      });
    }
  }
};
</script>

<style></style>
