<template>
  <div>
    Seating
    <div class="d-flex tw-justify-between tw-mt-10">
      <v-btn color="primary" @click="$emit('prev-step')">Go Back</v-btn>
      <v-btn color="primary" @click="$emit('next-step')">Continue</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  props: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}
</style>
