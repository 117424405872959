import moment from 'moment';

const titlesDataConsts = {
  HOTEL_INFO: 'Hotel information',
  PASS_INFO: 'Passenger information',
  BOOKING_INFO: 'Booking information',
  PAYMENT_INFO: 'Payment information',
};

const EMPTY_STAR = '/images/empty_star.png';
const FULL_STAR = '/images/full_star.png';

const hotelStars = {
  4: [ FULL_STAR, FULL_STAR, FULL_STAR, FULL_STAR, EMPTY_STAR ],
  3: [ FULL_STAR, FULL_STAR, FULL_STAR, EMPTY_STAR, EMPTY_STAR ],
  2: [ FULL_STAR, FULL_STAR, EMPTY_STAR, EMPTY_STAR, EMPTY_STAR ],
  1: [ FULL_STAR, EMPTY_STAR, EMPTY_STAR, EMPTY_STAR, EMPTY_STAR ],
  5: [ FULL_STAR, FULL_STAR, FULL_STAR, FULL_STAR, FULL_STAR ],
};

let height = 20;
let delta = 10;

const setCenterView = (doc, item) => {
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(0);
  doc.setFontSize(18);
  doc.text(item, 105, height, null, null, 'center');
  height += delta;
};

const generateStars = (doc, data) => {
  const key = data || 3;
  const param = hotelStars[key];
  let width = 20;
  param.forEach(it => {
    var img = new Image();
    img.src = it;
    doc.addImage(img, 'JPEG', width, height, 7, 7);
    width += 10;
  });
};

export const generatePDF = async(doc, data) => {
  const { name, details, holder, paxes: paxesData, checkInDate, checkOutDate, categoryName, children } = data.data.cart.accommodation[0];
  const { reference, invoiceCompany } = data.data.accommodation[0].value.booking;
  height = 20;
  var img = new Image();
  img.src = '/images/main-picture-for-pdf.png';
  doc.addImage(img, 'JPEG', 0, 0, 220, 100);

  height += 100;
  generateStars(doc, categoryName);
  height += 15;
  doc.setFontSize(26);
  doc.setFont('helvetica', 'bold');
  doc.text(name, 20, height);
  height += 7;
  doc.setFontSize(14);
  doc.setTextColor(150);
  doc.setFont('helvetica', 'normal');
  doc.text(
    `${details.address.content}, ${details.destinationName}`,
    20,
    height
  );
  height += 20;
  doc.setTextColor(0);

  setCenterView(doc, titlesDataConsts.PASS_INFO);
  doc.setFontSize(20);
  doc.setFont('helvetica', 'bold');
  doc.text(
    holder.firstName +
      ' ' +
      holder.lastName,
    105,
    height,
    null,
    null,
    'center'
  );
  
  doc.setFontSize(14);
  doc.setTextColor(150);
  doc.setFont('helvetica', 'normal');
  let paxesTitle = '1 Adult';
  height += 7;
  const adults = paxesData.filter(it => it.type === 'AD');
  paxesTitle = `${adults.length + 1 } Adult, ${children.length} ${children.length > 1 ? 'Children' : 'Child'} (${children.reduce((acc, it) => acc + it.age + ' y/o ', '')})`;
  doc.text(
    paxesTitle,
    105,
    height,
    null,
    null,
    'center'
  );
    
  height += 20;

  setCenterView(doc, titlesDataConsts.BOOKING_INFO);
  doc.setFontSize(20);
  doc.setFont('helvetica', 'bold');
  doc.text(
    `Booking reference: ${reference}`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 7;
  doc.setFontSize(14);
  doc.setTextColor(150);
  doc.setFont('helvetica', 'normal');
  doc.text(
    `${details.roomName}, ${details.boardName}`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 7;
  doc.text(
    `${moment(checkInDate).format(
      'dddd, DD MMM YYYY'
    )} - ${moment(checkOutDate).format(
      'dddd, DD MMM YYYY'
    )}`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 20;

  setCenterView(doc, titlesDataConsts.PAYMENT_INFO);
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.text(
    `Payable through ${invoiceCompany.company},`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 5;
  doc.text(
    'acting as agent for the service operating',
    105,
    height,
    null,
    null,
    'center'
  );
  height += 5;
  doc.text(
    'company, details of which can be provided',
    105,
    height,
    null,
    null,
    'center'
  );
  height += 5;
  doc.text(
    `upon request. VAT: ${invoiceCompany.registrationNumber}`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 5;
  doc.setFont('helvetica', 'bold');
  doc.text(
    `Reference: ${reference}`,
    105,
    height,
    null,
    null,
    'center'
  );
  height += 5;
  doc.save('hotel-voucher.pdf');
};
