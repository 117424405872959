import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  state: {
    data: null,
  },
  getters: {
    loggedIn(state) {
      return state.data !== null;
    },
    user(state) {
      return state.data;
    }
  },
  mutations: {
    SET_USER(state, data) {
      state.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_USER', user);
    }
  },
  namespaced: true,
};
