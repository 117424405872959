<template>
  <div class="tw-flex tw-flex-col baggage-selector tw-mt-4 tw-mb-8">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{ label }}</div>
    <div
      class="selector tw-flex tw-justify-between tw-pr-0 md:tw-pr-24 tw-bg-grey_light tw-h-11 tw-rounded"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-mx-2"><CheckedIcon /></div>
        <div class="tw-font-bold">Personal Item</div>
      </div>
      <div class="tw-flex tw-items-center">
        <div>
          <b>{{ dimentions.length }}</b> x <b>{{ dimentions.height }}</b> x
          <b>{{ dimentions.width }}</b> cm, <b>{{ dimentions.weight }}</b> kg
        </div>
        <b class="tw-ml-3">{{ price }} EUR</b>
      </div>
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/assets/bookingPage/flights/checked.svg';

export default {
  components: {
    CheckedIcon
  },
  props: {
    label: String,
    dimentions: Object,
    price: {
      type: Number,
      default: 0
    }
  }
};
</script>
<style scoped>
.baggage-selector .selector {
  height: 60px;
  background: #f1f1f1 !important;
}
</style>
