<template>
  <div class="flights-container">
    <flights-steps :steps="flightSteps" v-model="step" />
    <hr class="h-splitter" />
    <div class="steps-content-container" :class="stepClasses(step)">
      <component
        :is="currentStep.component"
        :tabs="tabs"
        :activeTabIndex="activeTabIndex"
        @prev-step="prevStep"
        @next-step="nextStep"
        @next-tab="$emit('next-tab')"
      />
    </div>
  </div>
</template>

<script>
import { flightSteps } from '@/store/flights/flightsConstants';
import FlightsSteps from './steps/FlightsSteps.vue';
import FlightSelection from './steps/flightSelection/FlightSelection';
import PassengerDetails from './steps/passengerDetails/PassengerDetails.vue';
import TicketFare from './steps/ticketFare/TicketFare.vue';
import Guarantee from './steps/guarantee/Guarantee.vue';
import Seating from './steps/seating/Seating.vue';
import Overview from './steps/overview/Overview.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    FlightsSteps,
    FlightSelection,
    PassengerDetails,
    TicketFare,
    Guarantee,
    Seating,
    Overview
  },
  props: {
    tabs: Array,
    activeTabIndex: Number
  },
  data: () => ({
    flightSteps
  }),
  computed: {
    ...mapGetters('flights', [ 'currentStep' ]),
    step: {
      get() {
        return this.currentStep;
      },
      set(value) {
        this.setStep(value);
      }
    }
  },
  methods: {
    ...mapMutations('flights', [ 'setStep' ]),
    prevStep() {
      this.setStep(this.flightSteps[this.stepIndex(this.currentStep) - 1]);
    },
    nextStep() {
      this.setStep(this.flightSteps[this.stepIndex(this.currentStep) + 1]);
    },
    stepIndex(step) {
      return this.flightSteps.findIndex(s => s.title === step.title);
    },
    stepClasses(step) {
      return step.options?.scrollable ? 'scrollable' : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.steps-content-container {
  height: calc(100% - 40px);
  &.scrollable {
    padding-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

<style lang="scss">
.flights-step-title {
  font-size: 28px;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;

  font-family: PoppinsMedium;
  font-size: 32px;
  line-height: 32px;

  text-align: center;
  letter-spacing: -0.04em;

  /* Title */

  color: #28262e;
}
</style>
