<template>
  <div
    class="cart-flight-item tw-flex tw-flex-col tw-py-5 tw-px-8"
    :class="{ 'tw-bg-success tw-bg-opacity-10': completed }"
  >
    <div class="tw-flex tw-flex-grow inner-container">
      <!-- left -->
      <div class="left tw-flex-grow">
        <div class="flight-row">
          <div class="tw-text-left flight-column">
            <div v-if="completed" class="tw-text-success">Purchased</div>
            <flight-direction
              title=""
              :departureTime="departureTime"
              :departureDate="departureDate"
              :arrivalTime="arrivalTime"
              :arrivalDate="arrivalDate"
              :cityFrom="city_from"
              :airportFrom="airport_from"
              :airportFromCode="airport_from_code"
              :cityTo="city_to"
              :airportTo="airport_to"
              :airportToCode="airport_to_code"
              :flyDuration="durationFromSec(duration.departure)"
              :departureAirlines="departureAirlines"
            />
          </div>
          <div
            class="tw-text-left flight-column"
            v-if="nights_in_dest !== null"
          >
            <flight-direction
              title=""
              :departureTime="returnDepartureTime"
              :departureDate="returnDepartureDate"
              :arrivalTime="returnArrivalTime"
              :arrivalDate="returnArrivalDate"
              :cityFrom="returnCityFrom"
              :airportFrom="returnAirportFrom"
              :airportFromCode="returnAirportFromCode"
              :cityTo="returnCityTo"
              :airportTo="returnAirportTo"
              :airportToCode="returnAirportToCode"
              :flyDuration="durationFromSec(duration.return)"
              :departureAirlines="returnAirlines"
            />
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="right tw-flex">
        <div class="tw-flex tw-flex-row tw-justify-between tw-w-full">
          <div class="tw-flex tw-items-center">
            <h4>
              <span class="price tw-text-lg tw-font-bold tw-mr-4">{{
                racePrice
              }}</span>
            </h4>
          </div>
          <div class="tw-flex tw-items-center tw-ml-auto" v-if="canBeEdited">
            <button
              v-if="!completed"
              x-small
              color="primaryMedium"
              class="remove-btn"
              @click="$emit('remove-flight')"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 3.5H2.91667H12.25"
                  stroke="#D62A53"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.66602 3.49935V2.33268C4.66602 2.02326 4.78893 1.72652 5.00772 1.50772C5.22652 1.28893 5.52326 1.16602 5.83268 1.16602H8.16602C8.47544 1.16602 8.77218 1.28893 8.99097 1.50772C9.20977 1.72652 9.33268 2.02326 9.33268 2.33268V3.49935M11.0827 3.49935V11.666C11.0827 11.9754 10.9598 12.2722 10.741 12.491C10.5222 12.7098 10.2254 12.8327 9.91602 12.8327H4.08268C3.77326 12.8327 3.47652 12.7098 3.25772 12.491C3.03893 12.2722 2.91602 11.9754 2.91602 11.666V3.49935H11.0827Z"
                  stroke="#D62A53"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.83398 6.41602V9.91601"
                  stroke="#D62A53"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.16602 6.41602V9.91601"
                  stroke="#D62A53"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { airlineSrc } from '@/store/flights/flightsUtils';

import moment from 'moment';
import FlightDirection from './FlightDirection.vue';
import { APP_CURRENCY } from '@/assets/constants/appConfig';

export default {
  components: { FlightDirection },
  props: {
    departure_at: String,
    arrival_at: String,
    city_from: String,
    airport_from: String,
    airport_from_code: String,
    city_to: String,
    city_code_to: String,
    airport_to: String,
    airport_to_code: String,
    itinerary: Array,
    duration: Object,
    availability: Object,
    nights_in_dest: Number,
    return_duration: String,
    price: Array,
    selected: Boolean,
    selectionBehavior: Boolean,
    cartBehavior: Boolean,
    addedToCart: Boolean,
    completed: Boolean,
    canBeEdited: Boolean,
  },
  data: () => ({
    formValid: false,
    APP_CURRENCY,
  }),
  computed: {
    departureDate() {
      return this.getDate(this.departure_at);
    },
    departureTime() {
      return this.getTime(this.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.arrival_at);
    },
    arrivalDate() {
      return this.getDate(this.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureDate() {
      return this.getDate(this.returnRoute?.departure_at);
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute?.departure_at);
    },
    returnArrivalDate() {
      return this.getDate(this.returnRouteArrival?.arrival_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnAirportFrom() {
      return this.returnRoute?.airport_from;
    },
    returnAirportFromCode() {
      return this.returnRoute?.airport_from_code;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirportTo() {
      return this.returnRouteArrival?.airport_to;
    },
    returnAirportToCode() {
      return this.returnRouteArrival?.airport_to_code;
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    seats() {
      return this.availability.seats;
    },
    racePrice() {
      return this.price.length && `€${this.price[0].price}`;
    },
    currency() {
      return this.price.length && this.price[0].currency;
    },
  },
  methods: {
    airlineImg(code) {
      return airlineSrc(code);
    },
    getDate(strDate) {
      return moment(new Date(strDate)).format('ddd MMM D');
    },
    getTime(strDate) {
      return moment(new Date(strDate)).format('h:mm A');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      // var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? 'd ' : 'd ') : '';
      var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
      var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
      // var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
      return dDisplay + hDisplay + mDisplay;
    }
  },
};
</script>
<style scoped lang="scss">
.cart-flight-item {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0.5rem;
  border-radius: 1rem;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  background: #ffffff;
  border: 1px solid #f1f1f1;

  // @media only screen and (min-width: 768px) {
  // box-shadow: 0px 12px 16px rgb(0 0 0 / 6%);
  // }
  border-radius: 16px;
  padding-left: 11px;
  padding-right: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.chip-grey {
  background: #7473781a;
  color: #747378;
  margin-left: 43px !important;
}

.chip-primary {
  background: #ffe4ec;
  color: #d62a53;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  height: 24px;
  padding: 0 12px;
  margin-left: 0;
  margin-top: 2px;
}

.remove-btn {
  background: #ffe4ec;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  svg {
    margin: auto;
  }
}

.flight-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flight-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
}

.price {
  font-family: MontserratMedium;
  margin-right: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /* Paragraph */

  color: #747378;
}
.experience-type-icon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: #7473781a;
  margin-right: 16px;
  display: flex;
  margin-top: 11px;

  svg {
    margin: auto;
  }
}

.price {
  font-family: MontserratSemiBold;
  font-size: 18px;
  line-height: 18px;
  /* Title */

  color: #28262e;
}

@media (max-width: 1206px) {
  .inner-container {
    flex-direction: column;
  }

  .right > div {
    padding: 2px 12px 12px 6px;
  }
}
</style>
