import api from './axios';

export const getExperienceApi = id => {
  return api.get(`exp/${id}.json`);
};

export const getExperiencesApi = interests => {
  return api.get(`exp_ranks/${interests}.json`);
};

export const getCitiesApi = () => {
  return api.get('city_ranks.json');
};

export const getCountriesApi = payload => {
  return api.get(`origin_countries/${payload}.json`);
};

export const getExperiencesByCategoryApi = payload => {
  return api.get(`category/${payload}.json`);
};

export const getPackageExperiencesApi = () => {
  return api.get('top_experiences.json');
};

export const getDestinationCitiesApi = () => {
  return api.get('destination_cities.json');
};

export default api;
