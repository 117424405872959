<template>
  <div
    class="outer tw-border tw-rounded-xl tw-flex tw-justify-between tw-items-center tw-py-1 tw-px-6 tw-m-auto"
  >
    <div class="tw-flex-grow tw-grid tw-grid-cols-3 tw-pr-3 tw-items-center">
      <div class="tw-col-span-2 tw-font-bold">{{ name }}</div>
      <div class="tw-text-right " v-if="date">{{ formatDate(date) }}</div>
    </div>
    <button
      class="tw-bg-secondary hover:tw-opacity-90 tw-text-primary tw-font-sans tw-rounded-full tw-py-2 tw-px-6 my-5 tw-h-9"
      @click="$emit('open')"
    >
      Open
    </button>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    quantity: Number,
    date: String
  },
  methods: {
    // parseDate(date) {
    //   return moment(date).format('DD-MMM-YYYY hh:mm A');
    // },
    formatDate(previusFormat) {
      let d = new Date(previusFormat);
      return (
        d.toLocaleString('default', { day: 'numeric' }) +
        ' ' +
        d.toLocaleString('default', { month: 'short' }) +
        ' ' +
        d.toLocaleString('default', { year: 'numeric' })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.outer {
  max-width: 571px !important;
  margin-bottom: 8px;
}
</style>
