<template>
  <div class="details-container tw-overflow-y-auto tw-px-2">
    <h3 class="booking-title">Booking Details</h3>
    <h4 class="date-title">Date and Time</h4>
    <template v-for="item in accommodationsInCart">
      <acommodation-item
        :key="item.id"
        :name="item.name"
        :dateIn="item.details.checkInDate"
        :dateOut="item.details.checkOutDate"
      ></acommodation-item>
    </template>
    <template v-if="isShow">
      <h4 class="date-title">Bookings shall be made under</h4>
      <use-stored-details
        :value="isUserStored"
        @update="handleChangeUserData"
      ></use-stored-details>
    </template>
    <div class="tw-flex">
      <v-btn
        class="add-details-btn tw-mb-6"
        v-if="addNewDetails === false && isShow"
        @click="
          () => {
            $store.commit('setNewDetailsAccommodationUsed', true);
          }
        "
        >Or, Add new details</v-btn
      >
    </div>

    <template v-if="addNewDetails || !isShow">
      <div class="booking-title">
        Add <template v-if="isShow">New</template> Details
      </div>
      <add-new-user-details
        userName="current"
        @change="handleChange"
        :data="hotelsCheckoutData['current']"
      ></add-new-user-details>
      <template v-for="(user, index) in newUsers">
        <div :key="user">
          <div class="tw-flex tw-justify-between tw-items-center tw-m-auto">
            <div
              class="tw-font-sans tw-text-2xl tw-m-auto tw-mb-10 tw-font-medium tw-mt-3"
            >
              Guest {{ ' ' + `${index + 1}` }}
            </div>
          </div>
          <add-new-user-details
            :userName="user"
            @change="handleChange"
            :data="hotelsCheckoutData[user]"
          ></add-new-user-details>
        </div>
      </template>
      <div class="tw-flex tw-justify-center tw-mt-6">
        <v-btn class="add-guest-button" @click="addUser"
          ><PassengerIcon class="mr-2" />Add details of all guests</v-btn
        >
      </div>
    </template>
    <div class="d-flex tw-mt-10 tw-float-right">
      <div class="tw-flex tw-flex-wrap">
        <div class="tw-w-16"></div>
        <v-btn
          class="rounded-pill tw-mb-2 back-button"
          v-if="!firstTab"
          min-width="150px"
          color="secondary"
          @click="back"
        >
          Go Back</v-btn
        >
        <v-btn
          class="rounded-pill tw-mb-2"
          min-width="150px"
          color="primary"
          :disabled="!hotelsComplete"
          @click="next"
          >{{
            lastTab ? 'Continue to Payment' : 'Go to ' + tabs[activeTabIndex + 1].title
          }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import AcommodationItem from './components/AcommodationItem.vue';
import AddNewUserDetails from './components/AddNewUserDetails.vue';
import UseStoredDetails from '@/views/booking/steps/details/components/UseStoredDetails.vue';
import PassengerIcon from '@/assets/bookingPage/flights/passenger.svg';
export default {
  data: function() {
    return { title: 'Accommodation' };
  },
  props: [
    'tabs',
    'activeTabIndex',
    'changeActiveTab',
    'canBack',
    'firstTab',
    'lastTab'
  ],
  components: {
    AcommodationItem,
    UseStoredDetails,
    AddNewUserDetails,
    PassengerIcon
  },
  computed: {
    ...mapState({
      hotelsCheckoutData: state => state.experiences.hotelsCheckoutData,
      addNewDetails: state => state.experiences.newDetailsAccommodationUsed,
      isUserStored: state => state.experiences.isUserStored
    }),
    ...mapGetters([ 'accommodationsInCart', 'hotelsComplete', 'holderData' ]),
    ...mapGetters('user', [ 'loggedIn' ]),
    ...mapGetters('flights', [ 'passengers', 'contactEmail', 'contactPhone' ]),
    isShow: function() {
      return (
        !(this.holderData.sourceTab === this.title) &&
        // (this.$store.getters['user/loggedIn'] ||
        this.holderData.firstName &&
        this.holderData.lastName
        // )
      );
    },
    newUsers: function() {
      return this.hotelsCheckoutData
        ? Object.keys(this.hotelsCheckoutData).filter(it => it !== 'current')
        : [];
    }
  },
  methods: {
    ...mapMutations([ 'setHolderData' ]),
    addUser() {
      this.$store.commit('setHotelsCheckoutData', {
        ['user' + (this.newUsers.length + 1)]: {
          firstName: '',
          lastName: '',
          phone: ''
        }
      });
    },
    next() {
      this.$emit('next-tab');
    },
    back() {
      this.canBack && this.$emit('prev-tab');
    },
    handleChange(data) {
      this.$store.commit('setHotelsCheckoutData', data);
      if (
        !this.holderData.sourceTab ||
        !(this.holderData.firstName && this.holderData.lastName) ||
        this.holderData.sourceTab === this.title
      )
        this.setHolderData({ ...data.current, sourceTab: this.title });
    },
    handleChangeUserData(data) {
      this.$store.commit('setIsUserStored', data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.container-data {
  max-height: 430px;
}

.booking-title {
  font-size: 2rem;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'PoppinsMedium';
}

.date-title {
  font-size: 1rem;
  margin-top: 48px;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'PoppinsSemiBold';
}

.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}

.add-details-btn {
  margin: auto;
  max-width: 500px;
  width: 100%;
}
</style>
