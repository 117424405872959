<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    chips
    small-chips
    flat
    solo
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="text-field"
        :value="formatDate(value)"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        chips
        small-chips
        flat
        solo
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      @input="menu = false"
      chips
      small-chips
      flat
      solo
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  inject: [ 'mediaQueries' ],
  props: {
    label: String,
    value: String,
    min: String,
    rules: Array
  },
  data: () => ({
    menu: false
  }),
  methods: {
    formatDate(previusFormat) {
      if (previusFormat) {
        let d = new Date(previusFormat);
        return (
          d.toLocaleString('default', { day: 'numeric' }) +
          ' ' +
          d.toLocaleString('default', { month: 'short' }) +
          ' ' +
          d.toLocaleString('default', { year: 'numeric' })
        );
      } else {
        return null;
      }
    }
  },
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
      }
    },
    inputFieldSize() {
      return this.mediaQueries.md ? '112' : '90';
    }
  }
};
</script>

<style scoped>
.v-picker {
  width: 300px;
  height: 250px;
}

.text-field::v-deep .v-text-field__details {
  display: none !important;
}

.datePicker {
  border-radius: 15px;
}
</style>
