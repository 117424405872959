<template>
  <div class="tw-grid" :class="gridClasses">
    <div v-for="(service, index) in allowServices" :key="`service_${index}`">
      <fare-card
      :title="`${service.title} Services`"
      :price="service.price"
      :options="service.options"
      :active="active(service)"
      :actionText="actionText(service)"
      @select="() => select(service)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FareCard from './FareCard.vue';

export default {
  components: { FareCard },
  props: {
  },
  computed: {
    ...mapGetters('flights', [
      'ticketServices',
      'selectedService',
    ]),
    gridClasses() {
      return `tw-grid-cols-${this.allowServices?.length}`;
    },
    allowServices() {
      return this.selectedService && this.ticketServices.filter(service => this.selectedService.allow.includes(service.level));
    }
  },
  methods: {
    active(service) {
      return this.selectedService.level !== service.level;
    },
    actionText(service) {
      return this.selectedService.level === service.level ? `Continue with ${service.title}` : 'Upgrade and continue';
    },
    select(service) {
      this.$emit('service-selected', service);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

</style>
