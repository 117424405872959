<template>
  <div
    class="steps-container d-flex align-center justify-center tw-my-12 tw-w-7/12 tw-m-auto"
  >
    <template v-for="(step, index) in steps">
      <div
        v-if="index !== 0"
        class="step-splitter tw-border-secondary"
        :class="{ active: current(index)||completed(index), first: index === 0 }"
        :key="'splitter' + index"
      ></div>
      <div
        class="flights-step d-flex flex-column"
        :class="{ active: current(index)||completed(index) }"
        :key="step.title"
      >
        <div
          class="step-number d-flex align-center justify-center tw-bg-secondary tw-text-primary tw-font-semibold"
        >
          <template v-if="completed(index)">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6654 3.5L5.2487 9.91667L2.33203 7"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
          <template v-else> 0{{ index + 1 }} </template>
        </div>
        <div class="step-title tw-mt-2">{{ step.title }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { flightSteps } from '@/store/flights/flightsConstants';

export default {
  props: {
    steps: Array,
    value: Object
  },
  methods: {
    current(index) {
      return index == this.findStepIndex(this.value);
    },
    completed(index) {
      return index < this.findStepIndex(this.value);
    },
    lastStep(index) {
      return index === this.steps.length - 1;
    },
    findStepIndex(step) {
      return flightSteps.findIndex(s => s.title === step.title);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.steps-container {
  .step-splitter {
    width: 100%;
    border-top-width: 1px;
    &.active {
      border-color: $primary;
    }
  }

  .flights-step {
    position: relative;

    &.active {
      .step-number {
        background: $primary;
        color: white;
      }

      .step-title {
        color: black;
      }
    }

    .step-number {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 12px;
    }

    .step-title {
      font-size: 10px;
      position: absolute;
      left: -40px;
      width: 100px;
      text-align: center;
      top: 30px;
    }
  }
}
</style>
