<template>
  <div class="passenger-details tw-w-9/12 tw-m-auto tw-pb-4">
    <h3 class="flights-step-title">Contact details</h3>
    <v-form v-model="formValid">
      <div class="mt-3">
        <app-text-input
          v-model="email"
          label="Email address"
          placeholder="Enter email"
          prependIcon="mdi-email-outline"
          :rules="rules.email"
        />
        <app-phone-input
          v-model="phone"
          label="Phone Number"
          :rules="rules.phone"
          prependIcon="mdi-phone-outline"
        />
      </div>
    </v-form>
    <div v-for="(passenger, index) in passengers" :key="`passenger_${index}`">
      <passenger-item
        :passenger="passenger"
        :index="index"
        @remove-passenger="removePassengerHandler(index)"
      />
    </div>
    <div class="bar">
      <v-btn class="rounded-pill tw-mb-2" @click="addPassengerHandler"
        ><PassengerIcon class="mr-2" /> Add another passenger</v-btn
      >
      <v-btn
        class="rounded-pill tw-mb-2"
        color="primary"
        :disabled="!stepValid"
        @click="nextStep"
        >Continue</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PassengerIcon from '@/assets/bookingPage/flights/passenger.svg';
import PassengerItem from './components/PassengerItem.vue';
import AppPhoneInput from '@/components/controlsV2/AppPhoneInput.vue';
import AppTextInput from '@/components/controlsV2/AppTextInput.vue';
import { requiredRule, emailRule } from '@/utils/rules';

export default {
  components: {
    PassengerIcon,
    PassengerItem,
    AppPhoneInput,
    AppTextInput
  },
  props: {},
  data: () => ({
    rules: {
      email: [
        requiredRule('E-mail is required'),
        emailRule('E-mail must be valid')
      ],
      title: 'flights',
      phone: [ v => (!!v && !!v.value) || 'Phone is required' ],
      firstName: [ requiredRule('First name is required') ],
      lastName: [ requiredRule('Last name is required') ],
      cardno: [ requiredRule('Passport or ID number is required') ]
    },
    formValid: false,
    title: 'PassengerDetails'
  }),
  computed: {
    ...mapGetters([ 'holderData' ]),

    ...mapGetters('flights', [ 'passengers', 'contactEmail', 'contactPhone' ]),
    email: {
      get() {
        return this.contactEmail;
      },
      set(value) {
        this.setEmail(value);
        this.setHolder('email', value);
      }
    },
    phone: {
      get() {
        return this.contactPhone;
      },
      set(value) {
        this.setPhone(value);
        this.setHolder('phone', value);
      }
    },
    stepValid() {
      return (
        this.formValid && this.passengers.every(passenger => passenger.valid)
      );
    }
  },
  methods: {
    ...mapActions('flights', [ 'saveTicket', 'heartbeat' ]),
    ...mapMutations('flights', [
      'addPassenger',
      'removePassenger',
      'setEmail',
      'setPhone'
    ]),
    ...mapMutations([ 'setHolderData' ]),
    nextStep() {
      this.stepValid && this.$emit('next-step');
    },
    removePassengerHandler(index) {
      this.removePassenger(index);
      this.heartbeat();
    },
    addPassengerHandler() {
      this.addPassenger();
      this.heartbeat();
    },
    setHolder(name, value) {
      if (
        !this.holderData.sourceTab ||
        !(this.holderData.firstName && this.holderData.lastName) ||
        this.holderData.sourceTab === this.title
      )
        this.setHolderData({
          ...{
            [name]: value
          },
          sourceTab: this.title
        });
    }
  }
};
</script>
<style scoped>
.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}
.bar {
  text-align: right;
  padding: 24px 0;
  padding-top: 8px;
  margin-bottom: 68px;
}
</style>
