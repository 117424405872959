import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default {
  state: {
    location: null,
    airports: [],
  },
  getters: {
    location(state) {
      return state.location;
    },
    airports(state) {
      return state.airports;
    },
  },
  mutations: {
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_AIRPORTS(state, airports) {
      state.airports = airports;
    }
  },
  actions: {
    obtainLocation: async function({ commit, state }, force) {
      if (state.location && !force) {
        return state.location;
      }

      const response = await axios.get('https://api.bigdatacloud.net/data/ip-geolocation?key=6c22384196ed44fab1e573827c497897');
      commit('SET_LOCATION', response.data.location);

      const airportResponse = await axios.get('https://api.skypicker.com/locations', {
        params: {
          'type': 'radius',
          'lat': response.data.location.latitude,
          'lon': response.data.location.longitude,
          'location_types': 'airport',
          'limit': 5,
        }
      });
      console.log(airportResponse.data);
      commit('SET_AIRPORTS', airportResponse.data.locations);
      return response.data;
    }
  },
  namespaced: true,
};
