<template>
  <ul class="tab-list" :class="'has-' + tabs.length + '-tabs'">
    <li
      class="tw-relative link tw-font-sans tw-text-sm tw-text-center"
      v-for="(tab, index) in tabs"
      :class="{
        active: index <= activeTabIndex
      }"
      :key="tab.tab"
    >
      <div class="divider" v-if="index > 0" />
      <div class="active-tab-divider-left" v-if="index > 0" />

      <button
        :class="{
          'tw-cursor-auto': !(index <= activeTabIndex)
        }"
        @click="
          () => {
            if (index <= activeTabIndex) {
              $emit('input', index);
            }
          }
        "
      >
        <div
          class=" 
                icon-container
                tw-rounded-full 
                tw-bg-secondary 
                tw-h-12 
                tw-w-12
                tw-mx-auto"
        >
          <component v-if="index <= activeTabIndex" :is="tab.iconWhite" />
          <component v-else :is="tab.icon" />
        </div>
        <span class="label">{{ tab.title }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import DetailTab from './DetailTab.vue';
import FlightIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import AccommodationIconWhite from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import ActivitiesIconWhite from '@/assets/inspirationPage/tabicons/activities_w.svg';
import NightlifeIconWhite from '@/assets/inspirationPage/tabicons/nightlife_w.svg';
import RestaurantIconWhite from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import FlightIconWhite from '@/assets/inspirationPage/tabicons/plane_w.svg';

export default {
  components: {
    DetailTab,
    FlightIcon,
    AccommodationIcon,
    ActivitiesIcon,
    RestaurantIcon,
    AccommodationIconWhite,
    ActivitiesIconWhite,
    NightlifeIconWhite,
    RestaurantIconWhite,
    FlightIconWhite
  },
  data() {
    return {
      displayTabs: []
    };
  },
  props: {
    tabs: Array,
    value: Number,
    activeTabIndex: Number
  },
  methods: {
    showSplitter(index) {
      return index < this.tabs.length - 1;
    },
    active(index) {
      return index === this.value;
    },
    dividerClass(index) {
      return index === this.value
        ? 'left-divider'
        : index === this.value + 1
          ? 'right-divider'
          : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';

.steps-container {
  .step-splitter {
    width: 15px;
    height: 35px;
    margin: 0 16px;
    border-top: 1px solid $gray-main;
    &.active {
      border-color: $primary;
    }
  }
}

// .divider {
//   height: 2px;
//   width: 40px;
//   &.left-divider {
//     background: linear-gradient(270deg, #d62a53 0%, rgba(214, 42, 83, 0) 100%);
//     border-radius: 30px;
//   }
//   &.right-divider {
//     background: linear-gradient(270deg, rgba(214, 42, 83, 0) 0%, #d62a53 100%);
//     border-radius: 30px;
//   }
// }

.tab-list {
  margin: auto;
  @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-0 tw-mt-12;
  width: 100%;
  margin-bottom: 56px;
}

.has-1-tabs {
  max-width: 48px;
}
.has-2-tabs {
  max-width: 186px;
  .divider,
  .active-tab-divider-left {
    right: calc(33px + 50%);

    @media (max-width: 600px) {
      right: calc(12.5vw);
    }
  }
}
.has-3-tabs {
  max-width: 332px;
  .divider,
  .active-tab-divider-left {
    right: calc(35px + 50%);

    @media (max-width: 600px) {
      right: calc(12.5vw);
    }
  }
}
.has-4-tabs {
  max-width: 444px;
  .divider,
  .active-tab-divider-left {
    right: calc(30px + 50%);
    @media (max-width: 600px) {
      right: calc(12.5vw);
    }
  }
}
.has-5-tabs {
  max-width: 560px;
  .divider,
  .active-tab-divider-left {
    right: calc(28px + 50%);

    @media (max-width: 600px) {
      right: calc(12.5vw);
    }
  }
}

.divider,
.active-tab-divider-left {
  width: 72px !important;
  height: 4px;

  border-radius: 30px;
  position: absolute;
  top: 24px;

  @media (max-width: 600px) {
    width: 22px !important;
  }
}
.divider {
  border-top: 4px solid #ffe4ec;
  z-index: 0;
}

.label {
  margin-left: -100%;
  margin-right: -100%;
  position: relative;
  user-select: none;
}

li {
  padding: 0;
}

.link {
  display: block;

  @include respond-to(xs-sm) {
    display: flex;
    align-items: center;
  }

  .icon-container {
    color: #d62a53;
    margin-bottom: 12px;
  }

  &.active {
    .active-tab-divider-left {
      height: 4px;
      background: #d62a53;
    }

    .active-tab-divider-left,
    .active-tab-divider-right {
      z-index: 1;
    }

    .icon-container {
      background: #d62a53;
      color: white;
    }
    .label {
      color: #d62a53;
    }
  }

  button {
    color: currentColor;
    width: 48px;

    svg {
      display: block;
      width: 20px;
      height: 48px;
      margin: auto auto;
    }
  }
}
</style>
