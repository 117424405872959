<template>
  <v-menu
  v-model="menu"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  min-width="auto"
  >
  <template v-slot:activator="{ on }">
  <div class="details-date-picker-input d-flex flex-column tw-mb-7">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{label}}</div>
    <div class="control tw-flex tw-items-start">
      <template v-if="!!prependIcon"><v-icon class="icon">{{prependIcon}}</v-icon></template>
      <div class="input-container">
        <v-text-field
        outlined
        dense
        readonly
        :placeholder="placeholder"
        :rules="rules"
        :value="formattedValue"
        @change="(val) => $emit('change', val)"
        @click="e => $emit('click', e)"
         v-on="on"
        />
      </div>
    </div>
  </div>
  </template>
  <v-date-picker
    v-model="date"
    :min="min"
    :max="max"
    @input="menu = false"
    no-title
  ></v-date-picker>
</v-menu>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: String,
    min: String,
    max: String,
    label: String,
    placeholder: String,
    rules: Array,
    prependIcon: String,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit('input', value);
      },
    },
    formattedValue: function() {
      return this.value ? moment(this.value).format('DD/MM/YY') : '';
    },
  },
};
</script>

<style lang="scss">
.details-date-picker-input {
  
  .v-input__slot fieldset{
    border: none !important
  }

  .control {
    height: 50px;
    border: 1px solid #EEEEEE !important;
    border-radius: 10px;
    
    .icon{
      margin-top: 13px;
      margin-left: 13px;
    }

    .input-container {
      margin-top: 4px;
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }

  .v-text-field__details {
    margin-top: 5px;
  }
}
</style>
