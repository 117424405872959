import { render, staticRenderFns } from "./TicketFare.vue?vue&type=template&id=5b09ac05&scoped=true&"
import script from "./TicketFare.vue?vue&type=script&lang=js&"
export * from "./TicketFare.vue?vue&type=script&lang=js&"
import style0 from "./TicketFare.vue?vue&type=style&index=0&id=5b09ac05&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b09ac05",
  null
  
)

export default component.exports