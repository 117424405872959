var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tab-list",class:'has-' + _vm.tabs.length + '-tabs'},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.tab,staticClass:"tw-relative link tw-font-sans tw-text-sm tw-text-center",class:{
      active: index <= _vm.activeTabIndex
    }},[(index > 0)?_c('div',{staticClass:"divider"}):_vm._e(),(index > 0)?_c('div',{staticClass:"active-tab-divider-left"}):_vm._e(),_c('button',{class:{
        'tw-cursor-auto': !(index <= _vm.activeTabIndex)
      },on:{"click":() => {
          if (index <= _vm.activeTabIndex) {
            _vm.$emit('input', index);
          }
        }}},[_c('div',{staticClass:"icon-container tw-rounded-full tw-bg-secondary tw-h-12 tw-w-12 tw-mx-auto"},[(index <= _vm.activeTabIndex)?_c(tab.iconWhite,{tag:"component"}):_c(tab.icon,{tag:"component"})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(tab.title))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }