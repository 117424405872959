export const passengerCategory = {
  adult: {
    title: 'Adult',
    category: 'adult',
    min: 12,
  },
  child: {
    title: 'Child',
    category: 'child',
    max: 11,
    min: 2,
  },
  infant: {
    title: 'Infant',
    category: 'infant',
    max: 1,
    min: 0,
  },
};

export const baggageCatedegories = [
  {
    category: 'personal_item',
    title: 'Personal Item',
  },
  {
    category: 'cabin_bag',
    title: 'Cabin Bag',
  },
  {
    category: 'hold_bag',
    title: 'Hold Bag',
  },
];

export const ancillaries = {
  servicePackage: 'service_package',
  fareType: 'fare_type',
  guarantee: 'guarantee',
};

export const faresTypes = {
  SAVER: 'saver',
  STANDARD: 'standard',
  FLEXI: 'flexi',
};

export const servicesTypes = {
  BASIC: 'basic',
  PLUS: 'plus',
  PREMIUM: 'premium',
};

export const faresList = [
  {
    title: 'Saver Ticket',
    loyaltyOption: faresTypes.SAVER,
    options: [
      {
        title: 'Basic Services',
        success: false,
      },
      {
        title: 'No flexibility',
        desc: 'to change trip',
        success: false,
      },
      {
        title: 'No refund',
        desc: 'on cancellation',
        success: false,
      },
    ],
    preselectedService: servicesTypes.BASIC,
    actionText: 'Continue with Saver',
  },
  {
    title: 'Standard Ticket',
    loyaltyOption: faresTypes.STANDARD,
    options: [
      {
        title: 'Plus Services',
        success: true,
      },
      {
        title: 'Free trip changes',
        desc: 'or pay the difference',
        success: true,
      },
      {
        title: 'No refund',
        desc: 'on cancellation',
        success: false,
      },
    ],
    preselectedService: servicesTypes.PLUS,
    actionText: 'Continue with Standard',
  },
  {
    title: 'Flexi Ticket',
    loyaltyOption: faresTypes.FLEXI,
    options: [
      {
        title: 'Premium Services',
        success: true,
      },
      {
        title: 'Free trip changes',
        desc: 'or pay the difference',
        success: true,
      },
      {
        title: '90% refund',
        desc: 'on cancellation',
        success: true,
      },
    ],
    preselectedService: servicesTypes.PREMIUM,
    actionText: 'Continue with Flexi',
  },
];

export const servicesList = [
  {
    title: 'Basic',
    level: servicesTypes.BASIC,
    options: [
      {
        title: 'Limited support availability',
        success: false,
      },
      {
        title: 'Low caller priority',
        success: false,
      },
      {
        title: 'No email support',
        success: false,
      },
      {
        title: '$30 processing fee for additional services',
        success: false,
      },
    ],
    allow: [ servicesTypes.BASIC, servicesTypes.PLUS, servicesTypes.PREMIUM ],
  },
  {
    title: 'Plus',
    level: servicesTypes.PLUS,
    options: [
      {
        title: 'Extended support availability',
        success: true,
      },
      {
        title: 'Medium caller priority',
        success: true,
      },
      {
        title: 'Email Support',
        success: true,
      },
      {
        title: '$10 processing fee for additional services',
        success: false,
      },
    ],
    allow: [ servicesTypes.PLUS, servicesTypes.PREMIUM ],
  },
  {
    title: 'Premium',
    level: servicesTypes.PREMIUM,
    options: [
      {
        title: 'Extended support availability',
        success: true,
      },
      {
        title: 'High caller priority',
        success: true,
      },
      {
        title: 'Email Support',
        success: true,
      },
      {
        title: 'No processing fee for additional services',
        success: false,
      },
    ],
    allow: [ ],
  },
];

export const guaranteesItems = [
  {
    id: 1,
    title: 'No Transfer Protection',
    description: 'If your trip is affected by a carrier delay or cancellation, we’ll provide you with a:',
    dangerText: 'Paid alternatives or a carrier refund of an undetermined amount. This refund is not guaranteed. Its processing time depends solely on the carrier.',
  },
  {
    id: 2,
    title: 'Kiwi.com Guarantee transfer protection',
    description: 'If your trip is affected by a carrier delay or cancellation, we’ll provide you with a:',
    successText: 'Free alternative or instant refund',
  },
];

export const flightSteps = [
  // {
  //   title: 'Flight Selection',
  //   component: 'flight-selection',
  // },
  {
    title: 'Passenger details',
    component: 'passenger-details',
    options: {
      scrollable: true,
    },
  },
  // {
  //   title: 'Ticket Fare',
  //   component: 'ticket-fare',
  //   options: {
  //     scrollable: true,
  //   },
  // },
  // {
  //   title: 'Guarantee',
  //   component: 'guarantee',
  //   options: {
  //     scrollable: true,
  //   },
  // },
  {
    title: 'Overview',
    component: 'overview',
    options: {
      scrollable: true,
    },
  },
];
