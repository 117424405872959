<template>
  <div
    class="tw-bg-paragraph tw-bg-opacity-10 tw-rounded-lg tw-flex tw-p-4 tw-items-center tw-w-full tw-h-full"
  >
    <div v-if="hasIcon" class="tw-w-9"><slot name="icon"></slot></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    phone: String
  },
  computed: {
    hasIcon() {
      return this.$slots.icon;
    }
  }
};
</script>
