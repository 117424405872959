<template>
  <div class="container tw-relative tw-w-full tw-py-10 tw-m-auto">
    <circle-loader :show="loadingDetails"
      ><h2 class="tw-text-lg tw-text-center">
        Loading details...
      </h2></circle-loader
    >
    <div class="tw-flex tw-flex-col tw-h-full">
      <h3 class="flights-step-title">Payment Details</h3>
      <div
        v-if="error"
        id="card-error"
        class="
          tw-border tw-p-2 tw-border-red-500 tw-bg-red-300 tw-rounded tw-mt-4
        "
      >
        {{ error }}
      </div>

      <div id="payment-request-button">
        <!-- A Stripe Element will be inserted here. -->
      </div>

      <label class="tw-full tw-mt-4">
        <span class="tw-font-poppins-medium">Name on card</span>
        <div class="tw-rounded tw-bg-white tw-p-3 control">
          <input
            type="text"
            class="tw-block tw-bg-white tw-w-full"
            v-model="nameOnCard"
          />
        </div>
      </label>

      <label class="tw-mt-4">
        <span class="tw-font-poppins-medium">Card number</span>
        <div
          id="card-number"
          class="tw-rounded tw-bg-white tw-p-3 control"
        ></div>
      </label>

      <label class="tw-mt-4">
        <span class="tw-font-poppins-medium">Expiration</span>
        <div
          id="card-expiry"
          class="tw-rounded tw-bg-white tw-p-3 control"
        ></div>
      </label>

      <label class="tw-mt-4">
        <span class="tw-font-poppins-medium">CVC</span>
        <div id="card-cvc" class="tw-rounded tw-bg-white tw-p-3 control"></div>
      </label>
    </div>
    <div class="d-flex tw-mt-10 tw-float-right">
      <div class="bar">
        <router-link :to="{ name: 'BookingDetails' }">
          <v-btn
            class="rounded-pill cancel-button tw-mb-2 button"
            min-width="150px"
            color="secondary"
            >Cancel</v-btn
          >
        </router-link>

        <v-btn
          class="rounded-pill tw-mb-2"
          min-width="150px"
          color="primary"
          @click="createToken"
          :loading="loading"
          >Complete Order</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { delay } from '@/utils/appHotels';
import CircleLoader from '@/components/controls/containers/circleLoader/CircleLoader.vue';

export default {
  name: 'PaymentDetails',
  components: {
    CircleLoader
  },
  data() {
    return {
      order: null,
      token: null,
      nameOnCard: '',
      error: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      loading: false,
      loadingDetails: true,
      accommodations: []
    };
  },
  computed: {
    ...mapGetters('flights', [
      'flight',
      'passengersUTC',
      'bookingId',
      'transactionId',
      'totalPrice',
      'bnum',
      'flightComplete',
      'contactEmail',
      'contactPhoneStr'
    ]),
    ...mapGetters([
      'activitiesInCart',
      'accommodationsInCart',
      'flightsInCart',
      'isUserStored',
      'hotelsCheckoutData',
      'isRestaurantsUserStored',
      'restaurantsCheckoutData',
      'isRestaurantsUserStored',
      'restaurantsInCart',
      'activitiesCheckoutData',
      'holderData'
    ]),
    ...mapGetters('user', [ 'user' ]),
    stripeElements() {
      return this.$stripe.elements();
    },
    accommodationsHolder() {
      return this.isUserStored
        ? this.holderData
        : {
          firstName: this.hotelsCheckoutData?.current?.firstName,
          lastName: this.hotelsCheckoutData?.current?.lastName,
          phone:
              this.hotelsCheckoutData?.current?.phone.code +
              this.hotelsCheckoutData?.current?.phone.value
        };
    }
  },
  mounted() {
    this.createOrder()
      .then(order => {
        this.order = order;
        if (order.amount > 0) {
          const paymentRequest = this.$stripe.paymentRequest({
            country: 'DE',
            currency: order.currency,
            total: {
              label: 'Total',
              amount: order.amount
            },
            requestPayerName: true,
            requestPayerEmail: true
          });

          paymentRequest.on('paymentmethod', ev => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            this.$stripe
              .confirmCardPayment(
                order.clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              )
              .then(confirmResult => {
                if (confirmResult.error) {
                  // Report to the browser that the payment failed, prompting it to
                  // re-show the payment interface, or show an error message and close
                  // the payment interface.
                  ev.complete('fail');
                } else {
                  ev.complete('success');
                  if (
                    confirmResult.paymentIntent.status === 'requires_action'
                  ) {
                    // Let Stripe.js handle the rest of the payment flow.
                    this.$stripe
                      .confirmCardPayment(order.clientSecret)
                      .then(result => {
                        if (result.error) {
                          // The payment failed -- ask your customer for a new payment method.
                        } else {
                          this.$emit('succeeded');
                        }
                      });
                  } else {
                    this.$emit('succeeded');
                  }
                }
              });
          });

          const paymentButton = this.stripeElements.create(
            'paymentRequestButton',
            {
              paymentRequest: paymentRequest
            }
          );

          paymentRequest.canMakePayment().then(function(result) {
            if (result) {
              paymentButton.mount('#payment-request-button');
            } else {
              document.getElementById('payment-request-button').style.display =
                'none';
            }
          });
        } else if (order.amount === 0) {
          this.getOrder();
        } else {
          console.log('Somethithsg wrong with order amount', order);
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.loadingDetails = false));

    const style = {
      base: {
        backgroundColor: '#FFFFF',
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        width: '100%'
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async createOrder() {
      const flights = this.flight
        ? [
          {
            ...this.flight,
            contactPhone: this.contactPhoneStr,
            contactEmail: this.contactEmail,
            passengers: this.passengersUTC,
            bnum: this.bnum,
            totalPrice: parseFloat(this.totalPrice),
            bookingId: this.bookingId,
            transactionId: this.transactionId
          }
        ]
        : null;

      this.accommodations = this.accommodationsInCart.map(accommodation => {
        const {
          roomName,
          boardName,
          reviews,
          address,
          destinationName,
          checkInDate,
          checkOutDate,
          image,
          categoryName
        } = accommodation.details;
        return {
          id: accommodation.id,
          name: accommodation.name,
          holder: this.accommodationsHolder,
          price: accommodation.price,
          checkInDate,
          checkOutDate,
          details: {
            roomName,
            boardName,
            reviews,
            address,
            destinationName,
            image,
            categoryName
          },
          children: accommodation.details.paxes || [],
          paxes: Object.keys(this.hotelsCheckoutData)
            .filter(it => it !== 'current')
            .map(val => ({
              roomId: 1,
              type: 'AD',
              name: this.hotelsCheckoutData[val].firstName,
              surname: this.hotelsCheckoutData[val].lastName
            }))
          // .concat(accommodation.details.paxes.map(it => ({ ...it, roomId: 1 })))
        };
      });

      const restaurants = this.restaurantsInCart.map(restaurant => {
        return {
          id: restaurant.id,
          date: restaurant.date,
          quantity: restaurant.quantity,
          name: restaurant.name,
          holder: this.isRestaurantsUserStored
            ? this.holderData
            : this.restaurantsCheckoutData
        };
      });

      const activities = this.activitiesInCart.map(activity => {
        return {
          id: activity.id,
          date: activity.date,
          quantity: activity.quantity,
          name: activity.name,
          holder: this.isActivitiesUserStored
            ? this.holderData
            : this.activitiesCheckoutData
        };
      });

      const createOrderResponse = await axios.post(
        process.env.VUE_APP_PAYMENTS_API,
        {
          action: 'create-order',
          activities,
          restaurants,
          accommodation: this.accommodations,
          flights
        }
      );
      return createOrderResponse.data;
    },
    async createToken() {
      window.app = this;
      this.loading = true;
      const { paymentMethod, error } = await this.$stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
        billing_details: {
          name: this.nameOnCard
        }
      });

      if (error) {
        this.error = error.message;
        this.loading = false;
        return;
      }

      try {
        await axios.post(process.env.VUE_APP_PAYMENTS_API, {
          action: 'add-payment-method',
          id: this.order.id,
          paymentMethod: paymentMethod.id
        });
      } catch {
        this.loading = false;
      }

      let confirmCardPaymentResponse = null;
      try {
        confirmCardPaymentResponse = await this.$stripe.confirmCardPayment(
          this.order.clientSecret
        );
      } catch {
        this.loading = false;
      }
      if (confirmCardPaymentResponse?.paymentIntent?.status === 'succeeded') {
        await delay(5000);
        this.getOrder();
      }
    },
    async getOrder() {
      try {
        let paymentData = await axios.post(process.env.VUE_APP_PAYMENTS_API, {
          action: 'get-order',
          id: this.order.id
        });
        if (
          !paymentData.data.accommodation.length &&
          this.accommodations.length
        ) {
          await delay(4000);
          paymentData = await axios.post(process.env.VUE_APP_PAYMENTS_API, {
            action: 'get-order',
            id: this.order.id
          });
        }
        this.$store.commit('setPaymentData', paymentData);
        this.$store.dispatch('clearCart');
        this.$router.push({ name: 'BookingConfirmation' });
      } finally {
        this.loading = false;
      }
    },
    firstName(sourceVal) {
      let name = '';
      if (this.isUserStored) {
        name = this.user ? this.user.displayName : this.passengers[0].firstName;
      } else {
        name = sourceVal;
      }
      return name;
    },
    lastName(sourceVal) {
      let name = '';
      if (this.isUserStored) {
        name = this.user ? this.user.displayName : this.passengers[0].lastName;
      } else {
        name = sourceVal;
      }
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.control {
  height: 50px;
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  margin-top: 1rem;
}

.button {
  color: $primary !important;
  margin-right: 2rem;
}

.container {
  max-width: 400px;
}

.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.cancel-button {
  color: #d62a53 !important;
  margin: 0 16px;
}

.bar {
  text-align: right;
  padding: 24px 0;
  padding-top: 8px;
  margin-bottom: 68px;
}
</style>
