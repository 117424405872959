<template>
  <div class="tw-flex tw-flex-col tw-px-0 md:tw-px-5 tw-pb-5">
    <h3 class="flights-step-title">Flight Selection</h3>
    <div class="tw-flex tw-flex-col tw-mb-2">
      <div class="tw-flex-grow">
        <div>
          <template v-for="(flight, index) in flightsInCart">
            <flights-item
              v-bind="flight"
              :key="`flight_${index}`"
              :selected="selectedFlight(flight)"
              :buttonBehavior="true"
              @select-flight="flightSelectionHandler(flight)"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="bar">
      <v-btn @click="nextStep" :disabled="!canContinue" :loading="loading"
        >Continue</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FlightsItem from '@/views/inspiration/tab-items/flights/components/FlightsItem.vue';

export default {
  components: {
    FlightsItem
  },
  props: {},
  data: () => ({
    formValid: false,
    loading: false
  }),
  computed: {
    ...mapGetters('flights', [ 'flight', 'searchedFlights' ]),
    ...mapGetters([ 'flightsInCart' ]),
    email: {
      get() {
        return this.contactEmail;
      },
      set(value) {
        this.setEmail(value);
      }
    },
    phone: {
      get() {
        return this.contactPhone;
      },
      set(value) {
        this.setPhone(value);
      }
    },
    canContinue() {
      return !!this.flight;
    }
  },
  methods: {
    ...mapActions('flights', [ 'setFlightRequest' ]),
    ...mapMutations('flights', [ 'setFlight' ]),
    nextStep() {
      this.$emit('next-step');
    },
    async flightSelectionHandler(flight) {
      this.loading = true;
      await this.setFlightRequest(flight);
      this.loading = false;
    },
    selectedFlight(flight) {
      return this.flight && flight.booking_token === this.flight.booking_token;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.v-btn {
  box-shadow: none !important;
  height: 54px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
  margin: 0 16px 57px 16px;

  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  height: 54px !important;

  text-align: center;
  letter-spacing: -0.02em;
  min-width: unset !important;
}

.back-button {
  color: #d62a53 !important;
}

.add-guest-button {
  background: #f1f1f1 !important;
  color: #747378 !important;
}
</style>
