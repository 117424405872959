<template>
  <div class="experience-content">
    <div
      class="experience-image"
      :style="{
        backgroundImage: `url(${item.image})`
      }"
    ></div>
    <div class="display-product-info">
      <div class="product-name-container  tw-flex-grow tw-flex">
        <span class="product-name"> {{ item.name }} </span>
      </div>

      <span v-if="!hidePrice" class="price tw-font-bold tw-text-primary">
        {{ priceWithCurrency(roundToTwo(item.price)) }}
      </span>
    </div>
    <div v-if="removable && canBeEdited">
      <button
        x-small
        color="primaryMedium"
        class="remove-btn"
        @click="$emit('remove-experience')"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.00195 5.33398H4.55751H17.002"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.88997 5.33377V3.77821C6.88997 3.36565 7.05386 2.96999 7.34559 2.67827C7.63731 2.38654 8.03297 2.22266 8.44553 2.22266H11.5566C11.9692 2.22266 12.3649 2.38654 12.6566 2.67827C12.9483 2.96999 13.1122 3.36565 13.1122 3.77821V5.33377M15.4455 5.33377V16.2227C15.4455 16.6352 15.2816 17.0309 14.9899 17.3226C14.6982 17.6143 14.3025 17.7782 13.89 17.7782H6.1122C5.69964 17.7782 5.30398 17.6143 5.01225 17.3226C4.72053 17.0309 4.55664 16.6352 4.55664 16.2227V5.33377H15.4455Z"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.44531 9.22266V13.8893"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.5566 9.22266V13.8893"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div v-else-if="item.type === 'restaurants' && canBeEdited">
      <button
        x-small
        color="primaryMedium"
        class="remove-btn"
        @click="$emit('remove-restaurant')"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.00195 5.33398H4.55751H17.002"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.88997 5.33377V3.77821C6.88997 3.36565 7.05386 2.96999 7.34559 2.67827C7.63731 2.38654 8.03297 2.22266 8.44553 2.22266H11.5566C11.9692 2.22266 12.3649 2.38654 12.6566 2.67827C12.9483 2.96999 13.1122 3.36565 13.1122 3.77821V5.33377M15.4455 5.33377V16.2227C15.4455 16.6352 15.2816 17.0309 14.9899 17.3226C14.6982 17.6143 14.3025 17.7782 13.89 17.7782H6.1122C5.69964 17.7782 5.30398 17.6143 5.01225 17.3226C4.72053 17.0309 4.55664 16.6352 4.55664 16.2227V5.33377H15.4455Z"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.44531 9.22266V13.8893"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.5566 9.22266V13.8893"
            stroke="#D62A53"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div v-else-if="!hideIncrement && canBeEdited" class="counter">
      <button type="button" v-on:click="decrement(item)" class="counter-button">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.55469 9.99902H15.4436"
            stroke="#D62A53"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="quantity">{{ item.quantity }}</div>
      <button type="button" v-on:click="increment(item)" class="counter-button">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.55469V15.4436"
            stroke="#D62A53"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.55469 9.99902H15.4436"
            stroke="#D62A53"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExperienceCartContainer',
  props: {
    item: Object,
    priceWithCurrency: Function,
    increment: Function,
    decrement: Function,
    hidePrice: Boolean,
    hideIncrement: Boolean,
    removable: Boolean,
    canBeEdited: Boolean
  },
  methods: {
    log() {
      console.log(this.item);
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    },
    formatDate(previusFormat) {
      let d = new Date(previusFormat);
      return (
        d.toLocaleString('default', { day: 'numeric' }) +
        ' ' +
        d.toLocaleString('default', { month: 'short' }) +
        ' ' +
        d.toLocaleString('default', { year: 'numeric' })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.experience-content {
  @apply tw-border tw-w-full tw-h-auto tw-bg-white tw-rounded-lg tw-mb-4 tw-flex tw-flex-col tw-items-start tw-rounded-2xl;

  @apply tw-mx-24  tw-py-1 tw-flex-row tw-items-center tw-justify-between tw-border-none;

  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 16px;
  padding-left: 1rem;
  padding-right: calc(8px + 1rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.experience-image {
  @apply tw-bg-cover tw-bg-center tw-w-full tw-h-52;
  margin-right: 8px;
  border-radius: 12px;
  @apply tw-my-2 tw-w-24;
  width: 82px;
  height: 82px;
  min-width: 82px;
  min-height: 82px;
}

.counter {
  @apply tw-self-center tw-font-bold tw-flex tw-flex-row tw-items-center tw-gap-12 tw-mb-2;

  @apply tw-gap-2 tw-mb-0;

  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  .quantity {
    min-width: 18px;
    user-select: none;
  }

  text-align: center;

  /* Primary */

  color: #d62a53;
}

.counter-button,
.remove-btn {
  background: #ffe4ec;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  /* Primary */
  color: #d62a53;
}

.display-product-info {
  @apply tw-font-sans tw-leading-6 tw-flex tw-flex-col tw-mx-2 tw-overflow-hidden;
  flex-grow: 1;

  @apply tw-leading-4;
}

svg {
  margin: auto;
}

.experience-type-icon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: #7473781a;
  margin-right: 16px;
  display: flex;
}

.room-name {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;

  color: #28262e;
}

.details-chips {
  display: flex;
}

.product-name-container {
  white-space: nowrap;
  overflow: hidden;
  height: 28px;
  text-overflow: ellipsis;
  font-family: PoppinsMedium;
  font-size: 18px;
  line-height: 28px;

  letter-spacing: -0.02em;

  color: #28262e;

  .product-name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.chip-grey {
  background: #7473781a;
  color: #747378;
}

.chip-primary {
  background: #ffe4ec;
  color: #d62a53;
}

.chip-grey,
.chip-primary {
  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 90px;
  height: 24px;
  padding: 0 12px;

  margin-top: 8px;
  margin-right: 8px;
}

.chip-grey {
  background: #7473781a;
  color: #747378;
}

.price {
  font-family: PoppinsSemiBold;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
}
</style>
