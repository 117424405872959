<template>
  <div class="tw-w-4/5 tw-m-auto">
      <h4 class="flights-step-title">Passengers</h4>
      <template v-for="(passenger, index) in items">
        <div
        :key="`passenger_${index}`">
          <h4 class="tw-text-center tw-text-gray-main">{{index === 0 ?  'Information of passenger' : `Passenger ${index + 1}`}}</h4>
          
          <overview-field class="tw-my-4">
            <div class="tw-flex tw-flex-wrap tw-items-center">
            <div class="tw-font-bold tw-mr-3">{{passenger.firstName}} {{passenger.lastName}}</div>
            <div class="tw-text-gray-main tw-font-semibold tw-text-sm">{{getDate(passenger.birthdayDate)}}</div>
            </div>
            <template slot="icon"><img :src="countryImgSrc(passenger.nationality.code)" class="tw-mr-3 tw-w-5 tw-h-5" /></template>
          </overview-field>

          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mb-4">
            <overview-field>
            <div class="tw-flex tw-flex-wrap tw-items-center">
            <div class="tw-font-bold tw-mr-3">{{passenger.cardno}}</div>
            <div class="tw-text-gray-main tw-font-semibold tw-text-sm">{{getDate(passenger.expirationDate)}}</div>
            </div>
            <template slot="icon"><cardno-item /></template>
          </overview-field>
          <overview-field>
            <div class="tw-font-bold tw-mr-3">No insurance</div>
            <template slot="icon"><shield-item /></template>
          </overview-field>
          </div>

          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mb-4">
            <overview-field>
            <div class="tw-font-bold tw-mr-3">{{email}}</div>
            <template slot="icon"><email-item /></template>
          </overview-field>
          <overview-field>
            <div class="tw-font-bold tw-mr-3">{{phone}}</div>
            <template slot="icon"><phone-item /></template>
          </overview-field>
          </div>
        </div>
      </template>
  </div>
</template>

<script>
import moment from 'moment';
import { countryImgSrc } from '@/store/flights/flightsUtils';
import OverviewField from './OverviewField.vue';
import CardnoItem from '@/assets/bookingPage/flights/overview/cardno.svg';
import ShieldItem from '@/assets/bookingPage/flights/overview/shield.svg';
import EmailItem from '@/assets/bookingPage/flights/overview/email.svg';
import PhoneItem from '@/assets/bookingPage/flights/overview/phone.svg';

export default {
  components: { 
    OverviewField,
    CardnoItem,
    ShieldItem,
    EmailItem,
    PhoneItem,
  },
  props: {
    items: Array,
    email: String,
    phone: String,
  },
  data: () => ({
    countryImgSrc,
  }),
  methods: {
    getDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
  },
};
</script>
