<template>
  <div class="tw-flex tw-flex-row">
    <div
      class="tw-flex tw-flex-col tw-border tw-rounded-xl tw-mb-4 tw-py-3 tw-shadow-sm tw-m-auto"
      :class="containerClassess"
      @click="btnBehaviorHandler"
    >
      <div v-if="completed" class="tw-text-success">Purchased</div>
      <div class="tw-grid tw-gap-28 tw-grid-cols-1  2xl:tw-grid-cols-3 tw-px-3">
        <div
          class="tw-text-left tw-col-span-1"
          :class="{ 'tw-col-span-full': noReturn }"
        >
          <flight-direction
            :departureTime="departureTime"
            :departureDate="departureDate"
            :arrivalTime="arrivalTime"
            :arrivalDate="arrivalDate"
            :cityFrom="city_from"
            :airportFrom="airport_from"
            :airportFromCode="airport_from_code"
            :cityTo="city_to"
            :airportTo="airport_to"
            :airportToCode="airport_to_code"
            :flyDuration="durationFromSec(duration.departure)"
            :departureAirlines="departureAirlines"
          />
        </div>
        <template v-if="!noReturn">
          <div class="tw-items-center tw-hidden lg:tw-flex">
            <div
              class="tw-border-b-2 tw-border-grey_lighter tw-pb-4 tw-w-1/2 tw-text-center tw-leading-5"
            >
              {{ nights_in_dest }}
              {{
                nights_in_dest > 1
                  ? `Nights in ${city_to}`
                  : `Night in ${city_to} only`
              }}
            </div>
          </div>
          <div class="tw-text-left ">
            <flight-direction
              :departureTime="returnDepartureTime"
              :departureDate="returnDepartureDate"
              :arrivalTime="returnArrivalTime"
              :arrivalDate="returnArrivalDate"
              :cityFrom="returnCityFrom"
              :airportFrom="returnAirportFrom"
              :airportFromCode="returnAirportFromCode"
              :cityTo="returnCityTo"
              :airportTo="returnAirportTo"
              :airportToCode="returnAirportToCode"
              :flyDuration="durationFromSec(duration.return)"
              :departureAirlines="returnAirlines"
            />
          </div>
        </template>
      </div>
      <template v-if="!noFooter">
        <div
          class="tw-px-4 tw-pt-4 tw-pb-1 tw-flex tw-flex-col md:tw-flex-row tw-justify-between"
        >
          <div class="tw-flex tw-items-center">
            <div
              class="tw-rounded-full tw-w-max tw-px-3 tw-py-1 tw-text-sm tw-bg-grey_light tw-text-gray-600"
            >
              {{ seats }} {{ seats > 1 ? 'seats' : 'seat' }} available
            </div>
          </div>
        </div>
      </template>
    </div>

    <div></div>
  </div>
</template>

<script>
import { airlineSrc } from '@/store/flights/flightsUtils';
import moment from 'moment';
import FlightDirection from './FlightDirection.vue';
import { APP_CURRENCY } from '@/assets/constants/appConfig';

export default {
  components: {
    FlightDirection
  },
  props: {
    departure_at: String,
    arrival_at: String,
    city_from: String,
    airport_from: String,
    airport_from_code: String,
    city_to: String,
    city_code_to: String,
    airport_to: String,
    airport_to_code: String,
    itinerary: Array,
    duration: Object,
    availability: Object,
    nights_in_dest: Number,
    return_duration: String,
    price: Array,
    selected: Boolean,
    selectionBehavior: Boolean,
    inCart: Boolean,
    completed: Boolean,
    buttonBehavior: Boolean,
    noFooter: Boolean,
    loading: Boolean
  },
  data: () => ({
    formValid: false,
    APP_CURRENCY
  }),
  computed: {
    departureDate() {
      return this.getDate(this.departure_at);
    },
    departureTime() {
      return this.getTime(this.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.arrival_at);
    },
    arrivalDate() {
      return this.getDate(this.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureDate() {
      return this.getDate(this.returnRoute?.departure_at);
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute?.departure_at);
    },
    returnArrivalDate() {
      return this.getDate(this.returnRouteArrival?.arrival_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnAirportFrom() {
      return this.returnRoute?.airport_from;
    },
    returnAirportFromCode() {
      return this.returnRoute?.airport_from_code;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirportTo() {
      return this.returnRouteArrival?.airport_to;
    },
    returnAirportToCode() {
      return this.returnRouteArrival?.airport_to_code;
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    seats() {
      return this.availability.seats;
    },
    racePrice() {
      return this.price.length && this.price[0].price;
    },
    currency() {
      return this.price.length && this.price[0].currency;
    },
    containerClassess() {
      let classes = this.selected
        ? 'tw-border-primary tw-border-opacity-40 tw-bg-secondary tw-bg-opacity-20'
        : 'tw-border-grey_lighter';
      if (this.buttonBehavior) {
        classes += ' tw-cursor-pointer';
      }
      if (this.completed) {
        classes +=
          ' tw-border-opacity-100 tw-border-success tw-bg-success tw-bg-opacity-5';
      }
      return classes;
    },
    noReturn() {
      return this.duration?.return === 0;
    }
  },
  methods: {
    airlineImg(code) {
      return airlineSrc(code);
    },
    getDate(strDate) {
      return moment(new Date(strDate)).format('ddd D MMM');
    },
    getTime(strDate) {
      return moment(new Date(strDate)).format('h:mm A');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      // var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? 'd ' : 'd ') : '';
      var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
      var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
      // var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
      return dDisplay + hDisplay + mDisplay;
    },
    btnBehaviorHandler() {
      this.buttonBehavior && !this.completed && this.$emit('select-flight');
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.bookNow {
  border-radius: 90px;
}

.priceLabel {
  font-size: 1.5vw;
  @include respond-to(md) {
    font-size: 1vw;
  }
  font-family: 'MontserratSemiBold';
}

.button {
  font-size: 0.75rem;
  font-family: 'MontserratSemiBold';
  letter-spacing: -0.01rem;
  height: 2.8rem !important;
  // @include respond-to(md) {
  //   height: 3vh !important;
  // }
  width: 10rem !important;
}
</style>
