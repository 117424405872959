export default {
  state: () => ({
    city: 'Berlin',
    country: null,
    fromDate: null,
    toDate: null,
    interests: [],
    maxPrice: 100,
    from: null,
    to: null,
  }),
  getters: {
    city: state => state.city,
    country: state => state.country,
    from: state => state.from,
    to: state => state.to,
  },
  mutations: {
    setParams(state, payload) {
      Object.assign(state, payload);
    },
    setInterests(state, interests) {
      state.interests = interests;
    },
    setMaxPrice(state, maxPrice) {
      state.maxPrice = maxPrice;
    }
  },
  namespaced: true,
};
