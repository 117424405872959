<template>
  <div v-if="show" 
  class="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-items-center tw-justify-center tw-bg-white tw-z-50">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div v-if="hasDefaultSlot" class="tw-mb-10">
        <slot></slot>
      </div>
      <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
      size="100"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    }
  },
};
</script>
