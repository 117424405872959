<template>
  <div>
    <h3 class="flights-step-title">Don’t get stuck during your trip</h3>
    <h4 class="tw-mb-10">This unique route is not protected by the carriers. Get protection from Kiwi.com. Learn more</h4>
    <template v-for="(guarantee, index) in guaranteesItems">
    <guarantee-card
    :key="`guarantee_${index}`"
    :title="guarantee.title"
    :description="guarantee.description"
    :successText="guarantee.successText"
    :dangerText="guarantee.dangerText"
    :active="selectedCard(guarantee)"
    @select-card="() => cardSelectionHandler(guarantee)"
    />
    </template>
    <div class="bar">
      <v-btn color="primary" @click="$emit('prev-step')">Back</v-btn>
      <v-btn color="primary" @click="$emit('next-step')">Continue</v-btn>
    </div>
  </div>
</template>

<script>
import { guaranteesItems } from '@/store/flights/flightsConstants';
import GuaranteeCard from './components/GuaranteeCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    GuaranteeCard,
  },
  props: {
  },
  data: () => ({
    guaranteesItems,
  }),
  computed: {
    ...mapGetters('flights', [
      'guarantee',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapMutations('flights', [
      'setGuarantee',
    ]),
    selectedCard(guarantee) {
      return this.guarantee.id === guarantee.id;
    },
    cardSelectionHandler(guarantee) {
      this.setGuarantee(guarantee);
    },
    continueHandler() {

    },
  },
};
</script>
