import { experiencesTypes } from './constants';

export default {
  state: () => ({
    products: [],
    name: null,
    price: null,
    image: null,
    type: null,
    quantity: 0,
    experienceshoppingcart: [],
    fromAirport: null,
    toAirport: null,
    fromDate: null,
    toDate: null,
    flightshoppingcart: [],
    totalPrice: null,
    totalCartCount: 0,
    drawerState: false,
    paymentData: {},
    hotelPackage: {}
  }),
  mutations: {
    setEmptyCart(state) {
      state.quantity = 0;
      state.experienceshoppingcart = [];
      state.flightshoppingcart = [];
      state.totalPrice = [];
      state.totalCartCount = 0;
    },
    setAddToCart(state, { id, name, price, image, quantity, type, details }) {
      const experienceInCart = state.experienceshoppingcart.find(item => {
        return item.id === id;
      });
      if (!experienceInCart) {
        state.experienceshoppingcart = [
          ...state.experienceshoppingcart,
          { id, name, price, image, quantity, type, details }
        ];
        state.totalCartCount += quantity;
      } else {
        state.experienceshoppingcart.find(item => {
          if (item.type !== 'accommodation') {
            if (item.id === experienceInCart.id) {
              experienceInCart.quantity += quantity;
              state.totalCartCount += quantity;
            }
          }
        });
      }
    },
    removeExperienceFromCart(state, experience) {
      const index = state.experienceshoppingcart.findIndex(
        exp => exp.type === experience.type && exp.id === experience.id
      );
      if (index != -1) {
        state.experienceshoppingcart.splice(index, 1);
        state.totalCartCount--;
      }
    },
    setFlightToCart(state, { flight }) {
      state.flightshoppingcart = [ flight ];
    },
    removeFlightFromCart(state, index) {
      state.flightshoppingcart.splice(index, 1);
    },
    incrementQuantity(state, { name }) {
      const experienceInCart = state.experienceshoppingcart.find(item => {
        return item.name === name;
      });

      if (experienceInCart) {
        state.experienceshoppingcart.find(item => {
          if (item.name === experienceInCart.name) {
            experienceInCart.quantity++;
            state.totalCartCount++;
          }
        });
      }
    },
    removeActivityOrRestaurant(state, { name }) {
      const experienceInCart = state.experienceshoppingcart.find(item => {
        return item.name === name;
      });

      state.totalCartCount -= experienceInCart.quantity;
      state.experienceshoppingcart = state.experienceshoppingcart.filter(
        item => item.name !== experienceInCart.name
      );
    },
    decrementQuantity(state, { name }) {
      const experienceInCart = state.experienceshoppingcart.find(item => {
        return item.name === name;
      });

      if (experienceInCart) {
        state.experienceshoppingcart.find(item => {
          if (item.name === experienceInCart.name) {
            experienceInCart.quantity--;
            state.totalCartCount--;
          }
          if (experienceInCart.quantity === 0) {
            state.experienceshoppingcart = state.experienceshoppingcart.filter(
              item => item.name !== experienceInCart.name
            );
          }
        });
      }
    },
    toggleDrawer(state, value) {
      state.drawerState = value;
    },
    setPaymentData(state, value) {
      state.paymentData = value;
    },
    setFlightComplete(state, flight) {
      const flights = [ ...state.flightshoppingcart ];
      const index = flights.findIndex(
        fl => fl.booking_token === flight.booking_token
      );
      flights[index] = { ...flights[index], completed: true };
      state.flightshoppingcart = flights;
    },
    updateDates(state, { name, date, time }) {
      const index = state.experienceshoppingcart.findIndex(
        item => item.name === name
      );

      if (index !== -1) {
        const experienceshoppingcart = [ ...state.experienceshoppingcart ];

        if (time) {
          experienceshoppingcart[index] = {
            ...experienceshoppingcart[index],
            date,
            time
          };
        } else {
          experienceshoppingcart[index] = {
            ...experienceshoppingcart[index],
            date
          };
        }
        state.experienceshoppingcart = experienceshoppingcart;
      }
    },
    updateHotelPackage(state, data) {
      state.hotelPackage = data;
    }
  },
  actions: {
    addExperienceToCart(
      context,
      { id, name, price, image, quantity, type, details, date }
    ) {
      try {
        context.commit('setAddToCart', {
          id,
          name,
          price,
          image,
          quantity,
          type,
          details,
          date
        });
      } catch (err) {
        console.log(err);
      }
    },
    addFlightToCart(context, { flight, toggleDrawer = true }) {
      try {
        context.commit('setFlightToCart', { flight });
        context.commit('flights/setFlight', flight);
        context.commit('toggleDrawer', toggleDrawer);
      } catch (err) {
        console.log(err);
      }
    },
    increment(context, { name }) {
      try {
        context.commit('incrementQuantity', { name });
      } catch (err) {
        console.log(err);
      }
    },
    decrement(context, { name }) {
      try {
        context.commit('decrementQuantity', { name });
      } catch (err) {
        console.log(err);
      }
    },
    removeActivityOrRestaurant(context, { name }) {
      try {
        context.commit('removeActivityOrRestaurant', { name });
      } catch (err) {
        console.log(err);
      }
    },
    toggleDrawer(context, drawerState) {
      try {
        context.commit('toggleDrawer', drawerState);
      } catch (err) {
        console.log(err);
      }
    },
    updateDates(context, { name, date, time }) {
      try {
        context.commit('updateDates', { name, date, time });
      } catch (err) {
        console.log(err);
      }
    },
    clearCart({ commit }) {
      commit('flights/clearFlight');
      commit('setEmptyCart');
    },
    storeHotelPackage(context, { hotelPackage }) {
      try {
        context.commit('updateHotelPackage', hotelPackage);
      } catch (err) {
        console.log(err);
      }
    }
  },
  getters: {
    experienceshoppingcart(state) {
      return state.experienceshoppingcart;
    },
    accommodationsInCart(state) {
      return state.experienceshoppingcart.filter(
        item => item.type === 'accommodation'
      );
    },
    activitiesInCart(state) {
      return state.experienceshoppingcart.filter(
        item => item.type === 'activities'
      );
    },
    restaurantsInCart(state) {
      return state.experienceshoppingcart.filter(
        item => item.type === 'restaurants'
      );
    },
    flightsInCart(state) {
      return state.flightshoppingcart;
    },
    totalPrice(state) {
      let total = 0;
      state.experienceshoppingcart.forEach(item => {
        if (item.type !== experiencesTypes.RESTAURANTS) {
          total += item.price * item.quantity;
        }
      });
      state.flightshoppingcart.forEach(item => {
        total += item.price[0]?.price;
      });
      return total;
    },
    totalCartCount(state) {
      return state.totalCartCount + state.flightshoppingcart.length;
    },
    drawerState(state) {
      return state.drawerState;
    },
    paymentData(state) {
      return state.paymentData;
    },
    tabsComplete: (state, getters) => {
      const flightsComplete = getters.flightsInCart.length
        ? getters['flights/flightComplete']
        : true;
      const hotelsComplete = getters.accommodationsInCart.length
        ? getters.hotelsComplete
        : true;
      const restaurantsComplete = getters.restaurantsInCart.length
        ? getters.restaurantsComplete
        : true;
      const activitiesComplete = getters.activitiesInCart.length
        ? getters.activitiesComplete
        : true;
      return [
        hotelsComplete,
        flightsComplete,
        restaurantsComplete,
        activitiesComplete
      ].every(completed => completed);
    },
    getHotelPackage(state) {
      return state.hotelPackage;
    }
  }
};
