<template>
  <div class="tw-w-full">
    <label>
      <span class="tw-block tw-font-sans">
        {{ label }}
      </span>
      <input
        :type="type"
        :value="value"
        @input="e => this.$emit('input', e.target.value)"
        :placeholder="placeholder"
        class="tw-font-sans tw-w-full tw-rounded tw-p-4 tw-mt-2"
      />
    </label>
  </div>
</template>

<style scoped>
input {
  border: 1px solid #eeeeee;
  border-radius: 10px;
}
</style>

<script>
export default {
  name: 'InputRow',
  props: {
    label: {
      type: String
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    }
  }
};
</script>
