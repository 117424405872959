import api from './axios';

export const searchFlightsApi = payload => {
  return api.post('/search', payload);
};

export const checkFlightsApi = payload => {
  return api.post('/check_flights', payload);
};

export const heartbeatApi = payload => {
  return api.post('/heartbeat', payload);
};

export const saveBookingApi = payload => {
  return api.post('/save_booking', payload);
};

export const confirmPaymentApi = payload => {
  return api.post('/confirm_payment', payload);
};
