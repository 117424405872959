import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import ExperienceModule from './experience/experience';
import SearchModule from './search';
import UserModule from './user';
import CartModule from './cart/cart';
import FlightsModule from './flights';
import ActivitiesModule from './activities'; // Temporary Fix - should be one store for booking flow
import LocationModule from './location';
//import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoading: false,    
  },
  getters: {
    isLoading: state => state.isLoading,
  },
  modules: {
    experiences: ExperienceModule,
    search: SearchModule,
    user: UserModule,
    cart: CartModule,
    flights: FlightsModule,
    activities: ActivitiesModule,
    location: LocationModule,
  },
  mutations: {
    loadingStateSet: (state, isLoading) => {
      Object.assign(state, isLoading);
    },
  },
  plugins: [
    createPersistedState({
      paths: [ 'search', 'user', 'cart', 'flights', 'activities', 'experiences' ],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      paths: [ 'location' ],
      storage: window.localStorage,
    }),
  ],
});

export default store;
