export const countriesByStatus = (state, borderStatus) => {
  return state.countries
    ?.filter(
      country =>
        country.borderStatus === borderStatus &&
        state.enrichedCitiesWithCountry
          .map(city => city.countryCode)
          .includes(country.targetCountryCode),
    )
    .map(country => country.targetCountryCode);
};


export const countriesFilteredByPrice = (state, getters, borderStatus) => {
  return state.countries
    ?.filter(
      country =>
        country.borderStatus === borderStatus &&
        state.enrichedCitiesWithCountry
          .map(city => city.countryCode)
          .includes(country.targetCountryCode) &&
        getters.filteredPriceData
          .map(priceResponse => priceResponse.city_code.slice(- 2).toUpperCase())
          .includes(country.targetCountryCode)
    )
    .map(country => country.targetCountryCode);
};
