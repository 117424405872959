var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content-container"},[_c('div',{staticClass:"tw-flex tw-flex-row"},[_c('div',[_c('div',{staticClass:"confirm-container"},[_c('h1',{staticClass:"confirm-header tw-font-bold tw-text-lef tw-tracking-wide"},[_vm._v(" "+_vm._s(_vm.greeting)+" ")])]),_vm._m(0),_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('ConfirmationMarker')],1),_c('h1',{staticClass:"tw-text-4xl tw-font-bold tw-text-center tw-mt-5 tw-mb-10"},[_vm._v(" You’re going to "+_vm._s(_vm.cityaccommodation)+"! ")]),_c('div',{staticClass:"tw-flex tw-full tw-justify-center"},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-w-3/4"},[(_vm.flights && _vm.flights.length)?_vm._l((_vm.flights),function(flight,index){return _c('order-detail',{key:`flight_${index}`,attrs:{"title":`${flight.city_from} - ${flight.city_to} and Back`,"items":[
                `Departure: ${_vm.formatDate(flight.departure_at)}`,
                `Return: ${_vm.calcReturn(flight.itinerary, flight.city_to)}`,
                `Passengers: ${flight.passengers.length}`
              ]}},[_c('template',{slot:"icon"},[_c('PlaneIcon')],1)],2)}):_vm._e(),(!!_vm.accommodations.length)?_vm._l((_vm.accommodations),function(acc,index){return _c('order-detail',{key:`accommodation_${index}`,attrs:{"title":acc.name,"items":[
                `${_vm.formatDate(acc.checkInDate, 'DD MMM')} - ${_vm.formatDate(
                  acc.checkOutDate
                )}`,
                `Guests: ${acc.paxes.length + 1} ${
                  acc.children && acc.children.length
                    ? 'Children: ' + acc.children.length
                    : ''
                }`,
                `Booking Nr. ${_vm.accommodationsBookingId(index)}`
              ]}},[_c('template',{slot:"icon"},[_c('AccommodationIcon')],1)],2)}):_vm._e(),(!!_vm.restaurants.length)?_vm._l((_vm.restaurants),function(res,index){return _c('order-detail',{key:`restaurant_${index}`,attrs:{"title":res.name,"items":[
                `${_vm.formatDate(res.date, 'DD MMM')}`,
                `Guests: ${res.quantity}`
              ]}},[_c('template',{slot:"icon"},[_c('RestaurantIcon')],1)],2)}):_vm._e(),(!!_vm.activities.length)?_vm._l((_vm.activities),function(res,index){return _c('order-detail',{key:`activities_${index}`,attrs:{"title":res.name,"items":[
                `${_vm.formatDate(res.date, 'DD MMM')}`,
                `Guests: ${res.quantity}`
              ]}},[_c('template',{slot:"icon"},[_c('ActivitiesIcon')],1)],2)}):_vm._e()],2)]),_c('div',{staticClass:"tw-flex"},[(_vm.paymentData.data.cart.accommodation.length > 0)?_c('button',{staticClass:"tw-border tw-bg-primary tw-content-center tw-mx-auto tw-px-12 tw-py-4 md:tw-py-2 tw-flex tw-flex-row tw-right-0 tw-rounded-full tw-mt-16",on:{"click":_vm.generatePDF}},[_c('div',{staticClass:"tw-text-white"},[_vm._v("Download details")])]):_vm._e(),_c('button',{staticClass:"tw-border tw-bg-primary tw-content-center tw-mx-auto tw-px-12 tw-py-4 md:tw-py-2 tw-flex tw-flex-row tw-right-0 tw-rounded-full tw-mt-16",on:{"click":_vm.homepage}},[_c('div',{staticClass:"tw-text-white"},[_vm._v("Go to homepage")])])])]),_c('div',[_c('div',{staticClass:"tw-rounded-3xl confirm tw-ml-20"},[_c('video',{ref:"videoRef",staticClass:"confirmation-video tw-rounded-3xl",attrs:{"playsinline":"","src":"confirmationVideo","id":"video-container","muted":"","loop":""},domProps:{"muted":true}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"para-container tw-mb-20"},[_c('h2',{staticClass:"header-text confirm-para tw-tracking-wide tw-ml-16"},[_vm._v(" You will receive a confirmation email soon with your itinerary and booking details ")])])
}]

export { render, staticRenderFns }