import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { sync } from 'vuex-router-sync';
import store from './store';
import router from './router';
import mapBoxGl from 'mapbox-gl';
import VueMeta from 'vue-meta';
import VueSplide from '@splidejs/vue-splide';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import * as VueGoogleMaps from 'gmap-vue';
import GmapCluster from 'gmap-vue/dist/components/cluster';
import Geocoder from '@pderas/vue2-geocoder';
import Vue2TouchEvents from 'vue2-touch-events';
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import '@/assets/tailwind.css';
import '@/assets/styles/fonts.scss';
import '@/assets/styles/app.scss';
import 'fullpage.js/dist/fullpage.css';
import VueMask from 'v-mask';
import Hotjar from 'vue-hotjar';
import initSentry from './plugins/sentry';
import initFirebase from './plugins/firebase';

Vue.prototype.$mapboxgl = mapBoxGl;
Vue.config.productionTip = false;
sync(store, router);

Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
  apiVersion: '2020-08-27'
});

Vue.use(Hotjar, {
  id: '2527604',
  isProduction: process.env.NODE_ENV === 'production',
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBOGIGPzh5-eypFgwFYamtjq-iPadsVUSA'
  }
});

initSentry(Vue, router);
initFirebase(Vue, store);

Vue.use(VueMeta);
Vue.use(VueSplide);
Vue.use(VueFullPage);
Vue.use(VueMask);
Vue.use(VueAwesomeSwiper);
Vue.use(Vue2TouchEvents);

const GOOGLE_MAPS_API_KEY = 'AIzaSyC7dXnsvYPMYyzt7kYkVcEfsMfq0SC4v2E';
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});
Vue.component('cluster', GmapCluster);
Vue.use(Geocoder, {
  defaultMode: 'address',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
