<template>
  <div class="d-flex flex-column">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{label}}</div>
    <div class="app-input">
      <v-select 
      :items="items"
      item-text="key"
      v-model="currentBaggage"
      solo
      :clearable="clearable"
      >
        <template slot="selection" slot-scope="{item}">
          <div class="selection tw-flex tw-w-full">
            <div class="mx-2"><CheckedIcon /></div>
            <div class="d-flex tw-justify-between tw-w-full">
              <div>{{ item.dimentions.weight }} kg</div>
              <div :class="{'tw-pr-7': !clearable}">{{ item.dimentions.length }} x {{ item.dimentions.height }} x {{item.dimentions.width}} cm <b class="tw-ml-3">{{item.price}} EUR</b></div>
            </div>
          </div>
        </template>
        <template slot="item" slot-scope="{item}">
          <div class="d-flex tw-w-full">
            <div class="d-flex tw-justify-between tw-w-full">
              <div>{{ item.dimentions.weight }} kg</div>
              <div>{{ item.dimentions.length }} x {{ item.dimentions.height }} x {{item.dimentions.width}} cm <b class="tw-ml-3">{{item.price}} EUR</b></div>
            </div>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckedIcon from '@/assets/bookingPage/flights/checked.svg';
import { getBagCategoryTitle } from '@/store/flights/flightsUtils';

export default {
  components: {
    CheckedIcon,
  },
  props: {
    label: String,
    items: Array,
    clearable: Boolean,
    value: Number,
    dimentions: Object,
    bagsPrice: Object,
  },
  data: () => ({
    getBagCategoryTitle,
  }),
  computed: {
    ...mapGetters([
      'baggageCombinations'
    ]),
    currentBaggage: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
.v-select__slot {
  height: 60px;
}
</style>
