import moment from 'moment';
import { baggageCatedegories, faresList, passengerCategory, servicesList } from './flightsConstants';

export const passengerTemplate = () => ({
  firstName: '',
  lastName: '',
  nationality: null,
  gender: null,
  birthdayDate: '2000-01-01',
  cardno: '',
  expirationDate: '',
  handBags: 0,
  holdBags: null,
  baggage: null,
  valid: false,
});

export function dateFromObj(v) {
  const date = moment(v, 'YYYY-MM-DD');
  return date.isValid() ? date.toDate() : null;
}

export function momentFromObj(v) {
  const date = moment(`${v.month}-${v.date}-${v.year}`, 'MMMM-DD-YYYY');
  return date.isValid() ? date : null;
}

export function passengersCategories(passengers) {
  const passCatgs = passengers.map(passenger => {
    const age = calculateAge(passenger.birthdayDate);
    const subLIst = Object.values(passengerCategory)
      .filter(cat => cat.min <= age);
    const resCategory = subLIst.reduce((acc, curr) => {
      return curr.min >= acc.min ? curr : acc;
    }, subLIst[0]);

    return {
      category: resCategory.category,
    };
  });
  return passCatgs;
}

export function ageCategory(birthdayDate) {
  const age = calculateAge(birthdayDate);
  const catsList = Object.values(passengerCategory)
    .filter(cat => cat.min <= age);
  return catsList.reduce((acc, curr) => {
    return curr.min >= acc.min ? curr : acc;
  }, catsList[0]);
}

export function countAgeCategories(passengers, category) {
  return passengers.filter(passenger => ageCategory(passenger.birthdayDate)?.category === category)?.length;
}

function calculateAge(birthdayDate) {
  const birthday = dateFromObj(birthdayDate);
  let age = null;
  if (birthday) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    age = ageDate.getUTCFullYear() - 1970;
  }
  return age > 0 ? age : 0;
}

export function fillFares(fares) {
  faresList.map(fareItem => {
    fareItem.fare = fares.find(f => f.loyalty_option === fareItem.loyaltyOption);
    return fareItem;
  });
  return faresList;
}

export function fillServices(services) {
  servicesList.map(serviceItem => {
    serviceItem.service = services.find(s => s.level === serviceItem.level);
    return serviceItem;
  });
  return servicesList;
}

export const airlineSrc = (airlineCode, size = 32) => {
  return `${process.env.VUE_APP_KIWI_IMAGES_URL}/airlines/${size}x${size}/${airlineCode}.png?default=airline.png`;
};

export const countryImgSrc = (counryCode, type = 'flat', size = 32) => {
  return `${process.env.VUE_APP_FLAGS_URL}/${counryCode}/${type}/${size}.png`;
};

export function getBagCategoryTitle(category) {
  return baggageCatedegories.find(c => c.category === category)?.title;
}

export const getShortPassengerTitle = gender => gender === 'Male' ? 'mr' : 'ms';
