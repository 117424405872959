import {
  getExperiencesApi,
  getCitiesApi,
  getDestinationCitiesApi,
  getCountriesApi,
  getExperienceApi,
  getExperiencesByCategoryApi,
  getPackageExperiencesApi,
} from '@/api/firebase';
import { availabilityApi, checkratesApi } from '@/api/traveloHotels';

import cities from '@/assets/json/cities.json';
// import hotelCountryCode from '@/assets/json/hotelCountryCode.json';
import { pricePerCityApi } from '@/api/traveloKorchetsIo';
import { countriesFilteredByPrice, countriesByStatus } from './experienceUtils';
import {
  BORDER_STATUS_OPEN,
  BORDER_STATUS_RESTRICTIONS,
} from './experienceConsts';
import { reduceHotelseResponse } from '@/utils/appHotels';

const getInitialState = () => {
  return {
    experience: null,
    destinationBackground: null,
    experiences: [],
    countries: [],
    destinationCities: [],
    enrichedCitiesWithCountry: cities.map(city => {
      return {
        ...city,
        countryCode: city.kiwi_id.slice(-2).toUpperCase(),
      };
    }),
    priceData: [],
    toCities: '',
    topExperiences: [],
    packageCityExperienceId: '',
    PackageExperiences: [],
    topExperiencesLoaded: false,
    PackageExperiencesLoaded: false,
    availabilityHotels: [],
    searchDetails: null,
    isLoading: false,
    isNoContent: false,
    newDetailsAccommodationUsed: false,
    newDetailsActivitiesUsed: false,
    newDetailsRestaurantsUsed: false,
    hotelsCheckoutData: {
      current: {
        firstName: '',
        lastName: '',
        phone: { code: '', value: '' },
      },
    },
    isUserStored: false,
    holderData: {
      email: '',
      phone: { code: '', value: '' },
      firstName: '',
      lastName: '',
      valid: false,
    },
    isRestaurantsUserStored: false,
    restaurantsCheckoutData: {
      current: {
        firstName: '',
        lastName: '',
        phone: { code: '', value: '' },
      },
    },
    isActivitiesUserStored: false,
    activitiesCheckoutData: {
      current: {
        firstName: '',
        lastName: '',
        phone: { code: '', value: '' },
      },
    },
    experiencesByCategory: [],
  };
};

const state = getInitialState();

const getters = {
  experiences: state => state.experiences,
  experience: state => state.experience,
  destinationCities: state => state.destinationCities,
  destinationBackground: state => state.destinationBackground,
  availabilityHotels: state => state.availabilityHotels,
  isaccommodationSearching: state => state.availabilityHotels.length === 0,
  PackageCityExperienceId(state) { return state.packageCityExperienceId; },
  PackageExperiences: state => state.PackageExperiences,
  openCountries: state => countriesByStatus(state, BORDER_STATUS_OPEN),
  restrictedCountries: state =>
    countriesByStatus(state, BORDER_STATUS_RESTRICTIONS),
  openCountriesFilteredByPrice: (state, getters) =>
    countriesFilteredByPrice(state, getters, BORDER_STATUS_OPEN),
  restrictedCountriesFilteredByPrice: (state, getters) =>
    countriesFilteredByPrice(state, getters, BORDER_STATUS_RESTRICTIONS),
  displayCountries(state, getters) {
    return [
      ...getters.openCountriesFilteredByPrice,
      ...getters.restrictedCountriesFilteredByPrice,
    ];
  },
  filteredPriceData(state, getters, rootState) {
    return state.priceData.filter(
      priceResponse =>
        parseInt(priceResponse.price) <= rootState.search.maxPrice
    );
  },
  maxPossiblePriceValue(state) {
    return (
      Math.max(
        ...state.priceData.map(priceItem => parseFloat(priceItem.price))
      ) ?? 0
    );
  },
  minPossiblePriceValue(state) {
    return (
      Math.min(
        ...state.priceData.map(priceItem => parseFloat(priceItem.price))
      ) ?? 0
    );
  },
  topExperiences(state) {
    return state.topExperiences.sort((prev, next) => next.rank - prev.rank);
  },
  topExperiencesLoaded(state) {
    return state.topExperiencesLoaded;
  },
  topInfluencers(state) {
    let influencers = [];
    state.topExperiences.forEach(city => {
      influencers = influencers.concat(city.experiences);
    });
    influencers.forEach(
      exp => (exp.likes = Math.floor(Math.random() * (10000 - 1)) + 1)
    );
    return influencers.sort((prev, next) => next.likes - prev.likes);
  },
  cityaccommodation(state, getters) {
    const city = getters?.experiences?.find(
      item => item.city_id === getters?.experience?.city_id
    );
    return city ? city.name : '';
  },
  country(state, getters) {
    const city = getters.experiences.find(
      item => item.city_id === getters.experience.city_id
    );
    return city ? city.country : '';
  },

  batchHotels: state => butchCount => {
    return state.availabilityHotels.slice(0, butchCount * 10);
  },
  hotelsCheckoutData: state => state.hotelsCheckoutData,
  isUserStored: (state, getters) => {
    return state.isUserStored ? getters['flights/contactsComplete'] : false;
  },
  hotelsComplete: (state, getters) => {
    return getters.isUserStored || state.hotelsCheckoutData.current.valid;
  },
  restaurantsCheckoutData: state => state.restaurantsCheckoutData,
  isRestaurantsUserStored: state => {
    return state.isRestaurantsUserStored;
  },
  restaurantsComplete: (state, getters) => {
    return (
      getters.isRestaurantsUserStored ||
      state.restaurantsCheckoutData.current.valid
    );
  },
  activitiesCheckoutData: state => state.activitiesCheckoutData,
  isActivitiesUserStored: state => {
    return state.isActivitiesUserStored;
  },
  activitiesComplete: (state, getters) => {
    return (
      getters.isActivitiesUserStored ||
      state.activitiesCheckoutData.current.valid
    );
  },
  holderData: state => state.holderData,
};

const actions = {
  async loadCountries(context) {
    try {
      const data = await getCountriesApi(context.rootState.search.country);
      context.commit('setCountries', Object.values(data));
      context.commit(
        'setCities',
        context.state.enrichedCitiesWithCountry
          .filter(city => {
            return [
              ...context.getters.openCountries,
              ...context.getters.restrictedCountries,
            ].includes(city.countryCode);
          })
          .filter(city => city.kiwi_id !== 'None')
          .map(city => city.kiwi_id)
          .join(',')
      );
    } catch (err) {
      console.log(err);
    }
  },
  async loadExperience(context, id) {
    try {
      const experience = await getExperienceApi(id);
      context.commit('setExperience', experience);
    } catch (err) {
      console.log(err);
    }
  },
  async loadExperiences(context, interests) {
    try {
      const experiences = await getExperiencesApi(interests);

      context.commit('setExperiences', experiences);
      return experiences;
    } catch (err) {
      console.log(err);
    }
  },

  async loadCities(context) {
    try {
      const experiences = await getCitiesApi();
      context.commit(
        'setExperiences',
        Object.values(experiences).filter(
          experience =>
            experience instanceof Object && !(experience instanceof Array)
        )
      );
      return experiences;
    } catch (err) {
      console.log(err);
    }
  },

  async loadDestinationsCities(context) {
    try {
      const destinationCities = await getDestinationCitiesApi();

      context.commit('setDestinationCities', destinationCities);
      return destinationCities;
    } catch (err) {
      console.log(err);
    }
  },

  async loadPriceData(context) {
    await context.dispatch('getCountries');
    const { city, country, fromDate, toDate } = context.rootState.search;
    const from = `${city.toLowerCase()}_${country.toLowerCase()}`;

    const requestBody = {
      from,
      to: context.state.toCities,
      date_from: fromDate,
      date_to: toDate,
    };
    try {
      const priceData = await pricePerCityApi(requestBody);
      context.commit('setPriceData', priceData);
    } catch (e) {
      console.log(e);
    }
  },
  async loadTopExperiences(context) {
    try {
      const experiences = await getExperiencesApi(1);
      context.commit('setTopExperiences', experiences);
    } catch (err) {
      console.log(err);
    }
  },
  async loadPackageExperiences(context) {
    try {
      const PackageExperienceIds = await getPackageExperiencesApi();

      const PackageExperiences = await Promise.all(
        PackageExperienceIds.map(expId => getExperienceApi(expId))
      );

      context.commit('setPackageExperiences', PackageExperiences);
      return PackageExperiences;
    } catch (err) {
      console.log(err);
    }
  },
  async loadAvailabilityHotels(context, data) {
    try {
      // const country = hotelCountryCode.find(
      //   it => it.name === context.getters.cityaccommodation
      // );

      const requestBody = {
        // destination: { code: country ? country.code : 'SSS' },
        ...(data.geolocation && { geolocation: data.geolocation }),
        ...(data.code && { code: data.code }),
        ...(data.hotels && { hotels: data.hotels }),
        stay: {
          checkIn: data.checkInDate,
          checkOut: data.checkOutDate,
        },
        occupancies: [
          {
            rooms: data.roomsCount,
            adults: data.adultsCount,
            children: data.childrenCount,
            paxes: data.paxes,
          },
        ],
        filter: {
          maxHotels: data.maxHotels || 50,
          minCategory: 3,
        },
        reviews: [ { type: 'TRIPADVISOR' } ],
      };

      context.commit('setSearchDetails', data);
      context.commit('setIsLoading', true);
      context.commit('setAvailabilityHotels', []);
      const res = await availabilityApi(requestBody);
      if (res.hotels && res.hotels.total > 0) {
        res.hotels.hotels.sort((a, b) => b.reviews[0].rate - a.reviews[0].rate);
        // res.hotels.hotels.forEach(hotel => {
        //   if (hotel.images) {
        //     let smallestVisualOrderImage = hotel.images[0];
        //     hotel.images.slice(1).forEach(image => {
        //       if (image.visualOrder < smallestVisualOrderImage.visualOrder) {
        //         smallestVisualOrderImage = image;
        //       }
        //     });

        //     hotel.image = smallestVisualOrderImage;
        //     hotel.image.path = hotel.image.path.replace('medium', 'bigger');
        //   }
        // });

        const hotels = reduceHotelseResponse(res.hotels.hotels);
        context.commit('setAvailabilityHotels', hotels);
        if (!hotels.length) {
          context.commit('setIsNoContent', true);
        }
      } else {
        context.commit('setAvailabilityHotels', []);
        context.commit('setIsNoContent', true);
      }
      return res.hotels.total;
    } catch (err) {
      context.commit('setAvailabilityHotels', []);
      context.commit('setIsNoContent', true);
      console.log(err);
    } finally {
      context.commit('setIsLoading', false);
    }
  },
  async loadHotelByCode(context, data) {
    try {
      const requestBody = {
        ...(data.hotels && { hotels: data.hotels }),
        stay: {
          checkIn: data.checkInDate,
          checkOut: data.checkOutDate,
        },
        occupancies: [
          {
            rooms: data.roomsCount,
            adults: data.adultsCount,
            children: data.childrenCount,
            paxes: data.paxes,
          },
        ],
        filter: {
          maxHotels: data.maxHotels || 50,
          minCategory: 3,
        },
        reviews: [ { type: 'TRIPADVISOR' } ],
      };
      const res = await availabilityApi(requestBody);
      if (res.hotels && res.hotels.total > 0) {
        const hotels = reduceHotelseResponse(res.hotels.hotels);
        return hotels[0];
      }
    } catch (err) {
      console.log(err);
    }
  },
  async roomsChecking(context, data) {
    try {
      let res;
      const details = await checkratesApi(data);
      if (details) {
        res = details.hotel.rooms[0].rates[0];
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  },
  async getExperiencesByCategory(context, data) {
    try {
      let experiences = await getExperiencesByCategoryApi(data);
      experiences = experiences.slice(0, 15);
      context.commit('setExperiencesByCategory', experiences);
    } catch (err) {
      console.log(err);
    }
  },
  addPackageCityExperienceId(context, id ) {
    try { context.commit('setPackageCityExperienceId', id );
    } catch (err) {
      console.log(err);
    }
  },
};

const mutations = {
  setCountries(state, countries) {
    state.countries = countries;
  },
  setPriceData(state, priceData) {
    state.priceData = priceData;
  },
  setCities(state, cities) {
    state.toCities = cities;
  },
  setDestinationCities(state, destinationCities) {
    state.destinationCities = destinationCities;
  },
  setExperience(state, experience) {
    state.experience = { ...experience };
  },
  setDestinationBackground(state, destinationBackground) {
    state.destinationBackground = destinationBackground;
  },
  setExperiences(state, experiences) {
    state.experiences = experiences;
  },
  setTopExperiences(state, experiences) {
    state.topExperiences = experiences;
    state.topExperiencesLoaded = true;
  },
  setPackageCityExperienceId(state, packageCityExperienceId) {
    state.packageCityExperienceId = packageCityExperienceId;
  },
  setPackageExperiences(state, experiences) {
    state.PackageExperiences = experiences;
    state.PackageExperiencesLoaded = true;
  },
  setSearchDetails(state, data) {
    state.searchDetails = data;
  },
  setAvailabilityHotels(state, data) {
    state.availabilityHotels = data;
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setIsNoContent(state, data) {
    state.isNoContent = data;
  },
  setHotelsCheckoutData(state, data) {
    state.hotelsCheckoutData = { ...state.hotelsCheckoutData, ...data };
  },
  setIsUserStored(state, data) {
    state.isUserStored = data;
  },
  setRestaurantsCheckoutData(state, data) {
    state.restaurantsCheckoutData = {
      ...state.restaurantsCheckoutData,
      ...data,
    };
  },
  setIsRestaurantsUserStored(state, data) {
    state.isRestaurantsUserStored = data;
  },
  setNewDetailsAccommodationUsed(state, data) {
    state.newDetailsAccommodationUsed = data;
  },
  setNewDetailsActivitiesUsed(state, data) {
    state.newDetailsActivitiesUsed = data;
  },
  setNewDetailsRestaurantsUsed(state, data) {
    state.newDetailsRestaurantsUsed = data;
  },
  setActivitiesCheckoutData(state, data) {
    state.activitiesCheckoutData = { ...state.activitiesCheckoutData, ...data };
  },
  setIsActivitiesUserStored(state, data) {
    state.isActivitiesUserStored = data;
  },
  setHolderData(state, data) {
    state.holderData = { ...state.holderData, ...data };
  },
  setExperiencesByCategory(state, data) {
    state.experiencesByCategory = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
