import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { getAuth } from 'firebase/auth';

export default function initFirebase(Vue, store) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBOGIGPzh5-eypFgwFYamtjq-iPadsVUSA',
    authDomain: 'travelo-91156.firebaseapp.com',
    databaseURL: 'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'travelo-91156',
    storageBucket: 'travelo-91156.appspot.com',
    messagingSenderId: '637246888295',
    appId: '1:637246888295:web:18763a05ea2dffe89577c2',
    measurementId: 'G-HVP9QC0LXP'
  });
  Vue.prototype.$analytics = firebase.analytics();

  getAuth().onAuthStateChanged(user => {
    store.dispatch('user/fetchUser', user);
  });
}
