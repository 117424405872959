import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_HOTELS_TRAVELO_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => response.data,
  error => {
    const message = error.response.data.error.message || 'Server error';

    console.log(message);
    return Promise.reject(error);
  },
);



export default api;
