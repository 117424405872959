<template>
  <div class="app-phone-input-v2 d-flex flex-column tw-mb-7">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{label}}</div>
    <div class="control tw-flex tw-items-start">
      <template v-if="!!prependIcon"
        ><v-icon class="icon">{{ prependIcon }}</v-icon></template
      >
      <div class="input-container">
        <v-input :rules="rules" :value="value" dense>
          <div>
            <vue-tel-input
              v-model="phoneValue"
              v-on:country-changed="countryChanged"
              :dropdownOptions="dropdownOptions"
              :showDialCodeInSelection="dropdownOptions.showDialCodeInSelection"
              validCharactersOnly
            ></vue-tel-input>
          </div>
        </v-input>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    VueTelInput
  },
  props: {
    value: Object,
    label: String,
    rules: Array,
    prependIcon: String
  },
  data: () => ({
    dropdownOptions: {
      disabled: false,
      showDialCodeInList: true,
      showDialCodeInSelection: true,
      showFlags: false,
      tabindex: 0
    },
    hideDetails: true
  }),
  computed: {
    phoneValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        if (value.length && this.hideDetails) {
          this.hideDetails = false;
        }
        if (value.length < 15) {
          this.inputPhone(this.value.code, value);
        } else {
          this.inputPhone(this.value.code, this.value.value);
        }
      }
    }
  },
  methods: {
    countryChanged(code) {
      this.inputPhone(code.dialCode, this.phoneValue);
    },
    inputPhone(code, value) {
      this.$emit('input', {
        code,
        value
      });
    }
  }
};
</script>

<style lang="scss">
.app-phone-input-v2 {
  .control {
    height: 50px;
    border: 1px solid #eeeeee !important;
    border-radius: 10px;

    .icon {
      margin-top: 13px;
      margin-left: 13px;
    }

    .input-container {
      margin-top: 10px;

      .v-messages.error--text {
        margin-top: 12px;
        margin-left: 5px;
      }
    }
  }

  .v-input__slot fieldset {
    border: none !important;
  }

  .vue-tel-input {
    border: none !important;
    background: white;
    padding: 1px 10px;
    width: 100%;

    .vti__selection .vti__country-code {
      font-size: 16px;
    }

    .vti__dropdown {
      padding-left: 0;
    }

    .vti__dropdown.disabled,
    .vti__dropdown.open,
    .vti__dropdown:hover {
      background-color: unset;
    }

    ul {
      padding-left: 0;
    }

    &:focus-within {
      box-shadow: unset !important;
      border-color: unset !important;
    }
  }
}
</style>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
