<template>
  <div class="app-text-input-v2 d-flex flex-column tw-mb-7">
    <div class="tw-mb-3 tw-font-poppins tw-font-medium">{{label}}</div>
    <div class="control tw-flex tw-items-start">
      <template v-if="!!prependIcon"><v-icon class="icon">{{prependIcon}}</v-icon></template>
      <div class="input-container">
        <v-text-field
        outlined
        dense
        :placeholder="placeholder"
        :rules="rules"
        v-mask="mask"
        v-model="inputVal"
        :type="type"
        @change="(val) => $emit('change', val)"
        @click="e => $emit('click', e)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    value: String | Number,
    label: String,
    placeholder: String,
    rules: Array,
    small: {
      type: Boolean,
      default: false,
    },
    mask: String,
    type: {
      type: String,
      default: 'text',
    },
    prependIcon: String,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
};
</script>

<style lang="scss">
.app-text-input-v2 {
  
  .v-input__slot fieldset{
    border: none !important
  }

  .control {
    height: 50px;
    border: 1px solid #EEEEEE !important;
    border-radius: 10px;
    
    .icon{
      margin-top: 13px;
      margin-left: 13px;
    }

    .input-container {
      margin-top: 4px;
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }

  .v-text-field__details {
    margin-top: 5px;
  }
}
</style>
