<template>
  <div class="tw-grid tw-grid-cols-3">
    <div v-for="(fare, index) in ticketFares" :key="`fare_${index}`">
      <fare-card
      :title="fare.title"
      :price="fare.price"
      :options="fare.options"
      :actionText="fare.actionText"
      :active="selected(fare)"
      @select="() => select(fare)"
      />
    </div>
  </div>
</template>

<script>
import FareCard from './FareCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { FareCard },
  props: {
  },
  computed: {
    ...mapGetters('flights', [
      'ticketFares',
      'selectedFare',
    ]),
  },
  watch: {
    checkedData: {
      handler: async function(val) {
        val && await this.getTicketFare();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('flights', [
      'setSelectedFare',
    ]),
    select(fare) {
      this.$emit('fare-selected', fare);
    },
    selected(fare) {
      return fare.loyaltyOption === this.selectedFare.loyaltyOption;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

</style>
